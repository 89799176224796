import { Forms as NgtForms } from '@ngt/forms'
import * as Dtos from '../api/dtos';
import { RequestState } from '@ngt/request-utilities';
import mcgLogo from '../assets/img/logo.png';
// import mcgLogo from './assets/img/logo.png';
import { responsiveFontSizes, Theme, ThemeOptions } from '@material-ui/core';
import createSpacing from '@material-ui/core/styles/createSpacing';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const user = global.AUTH as any
// Dtos.AuthenticateResponse;
const formMetadata = global.FORM_METADATA as Dtos.GetFormMetadataResponse;

if (!user) {
    if (global.location.search) {
        global.location.href = '/auth/openiddict' + global.location.search;
    } else {
        global.location.href = '/auth/openiddict';
    }
}

const spacingStep: number = 8;
const spacingFn = createSpacing(spacingStep);
const largeHeadingMargins = spacingFn(3, 0, 2, 0);
const smallHeadingMargins = spacingFn(2, 0, 2, 0);

const ngtFormsContextConfigured = new NgtForms({
    organisationOptions: {
        organisationLogo: mcgLogo,
        organisationName: "McCloy Group"
    },
    dtos: Dtos,
    storeOptions: {
        preloadState: {
            authenticatedUser: {
                user,
                loadState: {
                    state: RequestState.Success
                }
            }
        }
    },
    themeOptions: {
        beforeThemeCreated: (themeOptions: ThemeOptions) => {
            const primaryColour = "#004842";
            const secondaryColour = "#cd1342";

            return {
                palette: {
                    primary: {
                        main: primaryColour
                    },
                    secondary: {
                        main: secondaryColour
                    }
                },
                typography: {
                    h1: {
                        fontSize: '2rem',
                        color: secondaryColour,
                        margin: largeHeadingMargins
                    },
                    h2: {
                        fontSize: '1.5rem',
                        color: primaryColour,
                        margin: largeHeadingMargins
                    },
                    h3: {
                        fontSize: '1.25rem',
                        color: secondaryColour,
                        margin: largeHeadingMargins
                    },
                    h4: {
                        fontSize: '1.20rem',
                        color: primaryColour,
                        margin: smallHeadingMargins
                    },
                    h5: {
                        fontSize: '1.15rem',
                        color: secondaryColour,
                        margin: smallHeadingMargins
                    },
                    h6: {
                        fontSize: '1.10rem',
                        color: primaryColour,
                        margin: smallHeadingMargins
                    }
                }
            };
        },
        afterThemeCreated: (theme: Theme) => {
            return responsiveFontSizes(theme);
        }
    },
    formMetadata: formMetadata?.formMetadata
})


export default ngtFormsContextConfigured;