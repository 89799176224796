import { FunctionComponent } from 'react';
import Header from './Header';


interface IDefaultLayoutProps {
}

const DefaultLayout: FunctionComponent<IDefaultLayoutProps> = ({
    children
}) => {
    return (
        <>
            <Header />
            {children}
        </>
    )
};

export default DefaultLayout;