import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import McgFormsProvider from './components/providers/FormsProvider';
import { FormsProvider as NgtFormsProvider } from '@ngt/forms'
import formsContextConfigured from './contexts/formsContextConfigured';
import ngtFormsContextConfigured from './contexts/ngtFormsContextConfigured';

if (ngtFormsContextConfigured.store.getState().authenticatedUser.user) {
    ReactDOM.render(
        <React.StrictMode>
            <NgtFormsProvider value={ngtFormsContextConfigured}>
                <McgFormsProvider forms={formsContextConfigured}>
                    <App />
                </McgFormsProvider>
            </NgtFormsProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);