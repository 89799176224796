import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, fade, Grid, IconButton, ListItem, ListItemText, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useCallback, useMemo, useRef, useState } from "react";
import { useDrag, useDrop, XYCoord, DropTargetMonitor } from "react-dnd";
import { Identifier } from "dnd-core";
import { ItemTypes } from "../../utilities/dnd";
import { QuickLink, QuickLinkGroup } from "../../api/dtos";
import produce from "immer";
import { uniqueId } from "lodash-es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faCog, faFilePlus, faFolderPlus, faFolderTimes, faGripLines } from "@fortawesome/pro-duotone-svg-icons";
import GroupList from "./GroupList";
import CardList from "./CardList";
import { ICardItem, ICardItemDropType } from './CardItem';
import GroupEdit from './GroupEdit';

export interface IGroupContext {
	parent?: QuickLinkGroup
	parentPath?: string;
	editMode: boolean;
	level: number;
    adGroups: string[];
    saving?: boolean;
}

export interface IGroupItem {
    items?: QuickLinkGroup[];
    item: QuickLinkGroup;
    context: IGroupContext;
    index: number;
    moveItem: (drop: IGroupItemDropType, dropZone: IGroupItem, monitor: DropTargetMonitor<IGroupItemDropType>, hoverBoundingRect: DOMRect) => void;
    moveCard: (drop: ICardItemDropType, dropZone: ICardItem, monitor: DropTargetMonitor<ICardItemDropType>, hoverBoundingRect: DOMRect) => void;
    addItem: (item: QuickLinkGroup, index: number) => void;
    addChildItem: (item: QuickLinkGroup, parentIndex: number, childIndex?: number) => void;
    addChildLink: (item: QuickLink, parentIndex: number, childIndex?: number) => void;
    removeItem: (index: number) => void;
    setItems: (setFn: (items: QuickLinkGroup[]) => QuickLinkGroup[]) => void;
    expanded: Record<string, boolean>;
    toggleExpanded: (id: string) => void;
}


enum BackgroundType {
    Above = 1,
    In = 2,
    Below = 3
}

const useStyles = makeStyles((theme) => ({
    groupContainer: {
        paddingTop: theme.spacing(3),
        //paddingBottom: (props: any) => props.hasChildren ? 0 : theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginTop: -1,
        //borderBottom: "1px solid lightgrey",
        borderBottom: (props: any) => props.level > 1 ? 'none' : "1px solid lightgrey",
        borderRadius: 0,

        '&:last-child': {
             paddingBottom: (props: any) => props.level > 1 ? 0 : theme.spacing(3),
             //borderBottom: (props: any) => props.level > 1 ? 'none' : "1px solid lightgrey"
        }
    },
    groupEditContainer: {
        padding: theme.spacing(3, 0, 3, 3),
        marginTop: -1,
        borderLeft:"1px solid lightgrey",
        borderRight:"1px solid lightgrey",
        borderTop:"1px solid lightgrey",
        borderBottom: "1px solid lightgrey",
        opacity: ({isDragging, disabled}: any) => isDragging ?  0 : 1,
        background: ({isOverShallow, backgroundType, color, disabled}: any) => {
            const defaultColor = disabled ? theme.palette.grey[300] : fade(theme.palette.common.white, 1);

            return isOverShallow ? 
                backgroundType === BackgroundType.In ?
                    fade(color, 0.5):
                    backgroundType === BackgroundType.Above ?
                        `linear-gradient(180deg, ${fade(color, 0.5)} 0%, ${defaultColor} 30px, ${defaultColor} 100%)` :
                        backgroundType === BackgroundType.Below ?
                            `linear-gradient(0deg, ${fade(color, 0.5)} 0%, ${defaultColor} 30px, ${defaultColor} 100%)` : 
                            defaultColor :
                defaultColor
        },

        '& $groupEditContainer': {
            borderRight: 'none'
        }
    },
    titleWrapper: { 
        display: 'flex',
        alignItems: 'center'
    },
    grabHandle: { 
        marginBottom: 3, 
        marginRight: 16, 
        cursor: 'grab' 
    },
    title: { 
        padding: 0, 
        margin: 0, 
        flex: '1 1 auto', 
        color: ({color}: any) => color
    },
    buttonBox: {
        marginLeft: 'auto',
        paddingLeft: 8,
        marginRight: 24
    },
    editIcon: {
        padding: 0,
        marginRight: 8
    },
    expandIcon: {
        padding: 0,
        transform: ({isExpanded}: any) => isExpanded ? 'rotate(180deg)' : null as any,
        transition: 'transform 0.25s linear'
    },
    links: {
        marginTop: ({isEdit}: any) => isEdit ? 16 : 0
    },
    groups: {
        marginTop: ({isEdit}: any) => isEdit ? 16 : 0
    }
}));

export interface IGroupItemDropType {
    item: QuickLinkGroup; 
    index: number;
    context?: IGroupContext;
}

export const GroupEdgeWidth = 40;

const GroupItem = (props: IGroupItem) => {
    const {
        item,
        index,
        context,
        addChildLink,
        addChildItem,
        removeItem,
        setItems,
        moveItem,
        moveCard,
        expanded,
        toggleExpanded
    } = props;

    const hasChildren = useMemo(() => {
        return (item.groups?.length ?? 0) !== 0;
    }, [item.groups?.length]);

    const ref = useRef<HTMLDivElement>(null)
    
    const [backgroundType, setBackgroundType] = useState<BackgroundType | undefined>();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [editConfiguration, setEditConfiguration] = useState(false);

    const [{ handlerId, isOverShallow }, drop] = useDrop<
        IGroupItemDropType,
        void,
        { handlerId: Identifier | null; isOverShallow: boolean; }
    >({
        accept: [ItemTypes.GROUP, ItemTypes.CARD],
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
                isOver: monitor.isOver(),
                isOverShallow: monitor.isOver({shallow: true})
            }
        },
        hover: (item, monitor) => {
            if (!ref.current) {
                return
            }

            if (!monitor.isOver({shallow: true})) {
                return;
            }

            if (monitor.getItemType() === ItemTypes.CARD) {
                setBackgroundType(BackgroundType.In)
                return;
            }

            if (item.context?.parent?.id === context?.parent?.id && item.index === index) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverHeight =
                (hoverBoundingRect.bottom - hoverBoundingRect.top);

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            const placeAbove = hoverClientY < GroupEdgeWidth;
            const placeBelow = hoverClientY > (hoverHeight - GroupEdgeWidth);
            const placeIn = !placeAbove && !placeBelow;

            // Top of item
            if (placeAbove) {
                setBackgroundType(BackgroundType.Above)
                return
            }

            // Bottom of item
            if (placeBelow) {
                setBackgroundType(BackgroundType.Below)
                return
            }

            // middle of item
            if (placeIn) {
                setBackgroundType(BackgroundType.In)
                return
            }
            
        },
        drop: (item, monitor) => {
            if (monitor.didDrop()) {
                return;
            }

            if (!ref.current) {
                return
            }

            if (item.context?.parent?.id === context?.parent?.id && item.index === index) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            moveItem(item, props, monitor, hoverBoundingRect);
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemTypes.GROUP,
        item: () => {
            return {
                item,
                index,
                context
            }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    
	const setChildItems = useCallback((setFn: (items: QuickLinkGroup[]) => QuickLinkGroup[]) => {		
		setItems((current) => {
            const newState = produce(current, draft => {
				const currentItem = draft[index];
				draft[index].groups = setFn(currentItem.groups ?? [])
            })

            return newState;
        })
	}, [setItems, index])

	const setChildLinks = useCallback((setFn: (items: QuickLink[]) => QuickLink[]) => {		
		setItems((current) => {
            const newState = produce(current, draft => {
				const currentItem = draft[index];
				draft[index].links = setFn(currentItem.links ?? [])
            })

            return newState;
        })
	}, [setItems, index])

    const theme = useTheme();

    const headingVariant = useMemo(() => {
        let headingLevel = context.level + 1;
    
        if (headingLevel > 6) {
            headingLevel = 6;
        }

        return `h${headingLevel}`;
    }, [context.level])

    const color = useMemo(() => {
        return item.color ?? (context.level  % 2 === 1 ? theme.palette.primary.main : theme.palette.secondary.main)
    }, [item.color, context.level, theme.palette.primary.main, theme.palette.secondary.main]);


    const isExpanded = useMemo(() => {
        return expanded[item.id ?? (item as any).reference] !== false;
    }, [expanded, item.id, (item as any).reference]); // eslint-disable-line react-hooks/exhaustive-deps

    const classes = useStyles({ level: context.level, hasChildren: hasChildren, backgroundType, isOverShallow, color, isDragging, isEdit: context.editMode, isExpanded, disabled: !item.enabled });

    const onTitleBlur = useCallback((e: any) => {
        const newData = e.currentTarget.textContent;

        setItems(current => {
            const newState = produce(current, draft => {
                draft[index].name = newData ?? '';
            })

            return newState;
        })
    }, [setItems, index])

    preview(drop(ref));

    const links = useMemo(() => {
        return item.links && item.links.length > 0 && (
            <Grid
                container 
                spacing={context.editMode ? 0 : 1} 
                className={classes.links}
            >
                <CardList
                    moveItem={moveCard}
                    items={item.links}
                    setItems={setChildLinks}
                    context={{ 
                        adGroups: context.adGroups,
                        parent: item, 
                        parentPath: context && context.parentPath ?  `${context.parentPath}[${index}].links` : `[${index}].links`,
                        editMode: context.editMode ?? false,
                        level: (context.level),
                        parentColor: color,
                        saving: context.saving
                    }}
                />
            </Grid>
        );
    }, [ // eslint-disable-line react-hooks/exhaustive-deps
        classes.links,
        item, 
        context.editMode, 
        context.parentPath, 
        context.level, 
        context.adGroups,
        context.saving,
        color, 
        index, 
        moveCard, 
        setChildLinks
    ])

    const groups = useMemo(() => {
        
        return item.groups && item.groups.length > 0 && (
            <Box 
                //style={{marginLeft: 40}}
                className={classes.groups}
            >
                <GroupList
                    toggleExpanded={toggleExpanded}
                    expanded={expanded}
                    moveItem={moveItem}
                    moveCard={moveCard}
                    items={item.groups}
                    setItems={setChildItems}
                    context={{
                        adGroups: context.adGroups,
                        parent: item, 
                        parentPath: context && context.parentPath ?  `${context.parentPath}[${index}].groups` : `[${index}].groups`,
                        editMode: context.editMode ?? false,
                        level: context.level + 1,
                        saving: context.saving
                    }}
                />
            </Box>
        );
    }, [ // eslint-disable-line react-hooks/exhaustive-deps
        classes.groups,
        item, 
        context.editMode, 
        context.parentPath, 
        context.level,
        context.adGroups,
        context.saving,
        index, 
        moveCard, 
        moveItem,
        toggleExpanded,
        expanded,
        setChildLinks
    ])

    return (
        <div 
            ref={ref}  
            data-handler-id={handlerId}
            className={context?.editMode ? classes.groupEditContainer : classes.groupContainer}
        >
            <ListItem disableGutters>
                    <ListItemText 
                        primary={
                            <Box
                                className={classes.titleWrapper}
                            >
                                
                                {
                                    context?.editMode && (
                                        <div ref={context.saving ? undefined : drag} className={classes.grabHandle}>
                                            <FontAwesomeIcon icon={faGripLines} size="lg" />
                                        </div>
                                    )
                                }
                                <Typography variant={headingVariant as any} className={classes.title}>
                                    <div 
                                        contentEditable={context.editMode && !context.saving} 
                                        placeholder="(Group Name)" 
                                        suppressContentEditableWarning 
                                        onBlur={onTitleBlur}
                                    >
                                        {item.name}
                                    </div>
                                </Typography>
                                <Box 
                                    className={classes.buttonBox}
                                >
                                    {context?.editMode && (
                                        <>
                                            <Tooltip title="Edit Group Configuration">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="add"
                                                    className={classes.editIcon}
                                                    onClick={() => {
                                                        setEditConfiguration(true);
                                                    }}
                                                    disabled={context.saving}
                                                >
                                                    <FontAwesomeIcon icon={faCog} size="lg" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Add Link">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="add"
                                                    className={classes.editIcon}
                                                    onClick={() => {
                                                        addChildLink({ reference: uniqueId('link-') } as any, index)
                                                    }}
                                                    disabled={context.saving}
                                                >
                                                    <FontAwesomeIcon icon={faFilePlus} size="lg" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Add Child Group">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="add"
                                                    className={classes.editIcon}
                                                    onClick={() => {
                                                        addChildItem({ reference: uniqueId('group-') } as any, index)
                                                    }}
                                                    disabled={context.saving}
                                                >
                                                    <FontAwesomeIcon icon={faFolderPlus} size="lg" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete Group">
                                                <IconButton
                                                    color="secondary"
                                                    aria-label="delete"
                                                    className={classes.editIcon}
                                                    onClick={() => {
                                                        setConfirmDelete(true);
                                                    }}
                                                    disabled={context.saving}
                                                >
                                                    <FontAwesomeIcon icon={faFolderTimes} size="lg" />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                    <IconButton
                                        color="default"
                                        aria-label="collapse"
                                        className={classes.expandIcon}
                                        onClick={() => {
                                            toggleExpanded(item.id ?? (item as any).reference)
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faChevronCircleDown} size="lg" />
                                    </IconButton>
                                </Box>
                            </Box>
                        }
                    />
            </ListItem>
            <GroupEdit
                adGroups={context.adGroups}
                open={editConfiguration}
                onClose={() => setEditConfiguration(false)}
                onOk={(newGroup) => {
                    setItems(current => {
                        const newState = produce(current, draft => {
                            draft[index] = newGroup ?? '';
                        })

                        return newState;
                    })
                }}
                group={item}
            />
            <Dialog
                open={confirmDelete}
                onClose={() => setConfirmDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Group?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Changes will not be finalized until the <strong>SAVE</strong> button is clicked.<br /><br />
                        This will remove all links and subgroups.<br /><br />
                        Are you sure you wish to delete the group?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDelete(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => {
                            removeItem(index)
                        }} 
                        color="primary" 
                        autoFocus
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <div>
                    {links}
                    {groups}
                </div>
            </Collapse>
            
        </div>
    );
};

export default GroupItem;