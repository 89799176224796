import { NgtFormsContext, FormRoute, IFormMapping } from '@ngt/forms';
import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import DefaultLayout from './components/layouts/DefaultLayout';
import BankGuaranteeRequest from './containers/form/BankGuarenteeRequest';
import CreateNewEftRequest from './containers/form/CreateNewEftRequest';
import IntercompanyEftRequest from './containers/form/IntercompanyEftRequest';
import NewCommitment from './containers/form/NewCommitment';
import NewJobRequest from './containers/form/NewJobRequest';
import NewVendorRequest from './containers/form/NewVendorRequest';
import SalesAdvice from './containers/form/SalesAdvice';
import AdminDashboard from './containers/AdminDashboard';
import FormsDashboard from './containers/FormsDashboard';
import { Home } from './containers/Home';
import AuthenticationContext from './contexts/AuthenticationContext';
import * as Dtos from './././api/dtos';
import { hasPermission } from './utilities/auth';
import { library } from '@fortawesome/fontawesome-svg-core';

import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';

library.add(fad);
library.add(fas);
library.add(far);
library.add(fal);
library.add(fab);
library.add(fat);

const formMapping: Record<string, IFormMapping> = {
    "bank-guarentee-request": {
        components: <BankGuaranteeRequest />
    },
    "create-new-eft-request": {
        components: <CreateNewEftRequest />
    },
    "intercompany-eft-request": {
        components: <IntercompanyEftRequest />
    },
    "new-purchase-order": {
        components: <NewCommitment />
    },
    "new-job-request": {
        components: <NewJobRequest />
    },
    "new-vendor-request": {
        components: <NewVendorRequest />
    },
    "sales-advice": {
        components: <SalesAdvice />
    }
}

function App() {

    const ngtFormsContext = useContext(NgtFormsContext) as any;
    const authedUser = useContext(AuthenticationContext);
   
    return (
        <DefaultLayout>
            <Switch>

                {
                    hasPermission(authedUser?.permissions, Dtos.Permissions.MarkProcessed) &&
                    <Route path="/admin-dashboard" exact component={AdminDashboard} />
                }
                {
                    hasPermission(authedUser?.permissions, Dtos.Permissions.SendForm) && (
                        <FormRoute
                            path={[
                                `/form/:${ngtFormsContext.routeParameters.formDefinitionCode}/:${ngtFormsContext.routeParameters.formId}`,
                                `/form/:${ngtFormsContext.routeParameters.formDefinitionCode}`
                            ]}
                            resolveBeforeLoad
                            formMapping={formMapping}
                            allowCreate
                        />
                    )
                }
                {
                    hasPermission(authedUser?.permissions, Dtos.Permissions.SendForm) &&
                    <Route path="/dashboard" exact component={FormsDashboard} />
                }

                <Route path="/" exact component={Home} />
                <Redirect to="/" />
            </Switch>
        </DefaultLayout>
    );
}

export default App;