import { Grid } from "@material-ui/core";
import { FC, useCallback, useMemo } from "react";
import CardItem, { ICardItem } from "./CardItem";
import {produce} from 'immer'
import { QuickLink } from '../../api/dtos';

export interface ICardList extends Omit<ICardItem, 'item' | 'index' | 'addItem' | 'addChildItem' |  'removeItem'> {
}

const CardList: FC<ICardList> = ({
    items,
    ...props
}) => {
    const {
        setItems,
        context,
        moveItem
    } = props;

    const addItem = useCallback((item: QuickLink, index: number) => {
        setItems((current) => {
            const newState = produce(current, draft => {
                draft.splice(index, 0, item as any);
            })

            return newState;
        })
    }, [setItems]);

    const removeItem = useCallback((index: number) => {
        setItems((current) => {
            const newState = produce(current, draft => {
                draft.splice(index, 1);
            })

            return newState;
        })
    }, [setItems]);


    const group = useMemo(() => {
        return items?.map((item, index) => {
            return (
                <CardItem
                    key={item.id ?? (item as any).reference} 
                    {...props}
                    moveItem={moveItem}
                    addItem={addItem}
                    removeItem={removeItem}
                    item={item}
                    index={index}
                />
            );
        });
    }, [// eslint-disable-line react-hooks/exhaustive-deps
        items, 
        moveItem, 
        addItem, 
        removeItem, 
        props.moveItem,
        props.setItems,
        props.context?.editMode,
        props.context?.level,
        props.context?.parent,
        props.context?.parentPath,
        props.context?.parentColor,
        props.context?.adGroups,
        props.context?.saving
    ])

    return (
        <Grid container spacing={1} style={{ flex: context?.editMode ? '1 1 auto': 'undefined'}}>
            {group}
        </Grid>
    );
};

export default CardList;