import { CssBaseline } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Router } from "react-router-dom";
import FormsContext from "../../contexts/FormsContext";
import FormsContextContainer from "../../contexts/FormsContextContainer";
import AuthenticationProvider from "./AuthenticationProvider";


interface IFormsProviderProps {
    forms: FormsContextContainer;
}


const FormsProvider: FunctionComponent<IFormsProviderProps> = ({
    forms,
    children
}) => {
    return (
        <FormsContext.Provider value={forms}>
            <AuthenticationProvider>
            {/*<ThemeProvider theme={forms.theme}>*/}
                {/*<MuiPickersUtilsProvider utils={LuxonUtils}>*/}
                    <CssBaseline />
                    <Router history={forms.history}>
                        {children}
                    </Router>
                {/*</MuiPickersUtilsProvider>*/}
                {/*</ThemeProvider>*/}
            </AuthenticationProvider>
        </FormsContext.Provider>
    );
};

export default FormsProvider;