import { useCallback, useContext } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { GetAuthedUserPermissions, GetAuthedUserPermissionsResponse } from '../api/dtos';
import FormsContext from "../contexts/FormsContext";
import FormsContextContainer from "../contexts/FormsContextContainer";
import { MutatorCallback } from "swr/dist/types";

export type BoundMutator<TData> = (data?: TData | Promise<TData> | MutatorCallback<TData>, shouldRevalidate?: boolean) => Promise<TData | undefined>;

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: FormsContextContainer) => {
    try {
        return await forms.client.get(new GetAuthedUserPermissions());
    }
    catch (error) {
        if (error?.responseStatus?.message === "Not Authenticated") {
            return null as unknown as GetAuthedUserPermissionsResponse;
        }

        throw error;
    }
}

const useAuthentication = (configuration?: SWRConfiguration<GetAuthedUserPermissionsResponse, GetAuthedUserPermissionsResponse>) => {
    const formsContext = useContext(FormsContext);

    const { data, error } = useSWR<GetAuthedUserPermissionsResponse, GetAuthedUserPermissionsResponse>(['/auth', formsContext], fetcher, configuration);

    const unauthenticated = error?.responseStatus?.message === "Not Authenticated";

    const boundMutate: BoundMutator<GetAuthedUserPermissionsResponse> = useCallback((data, shouldRevalidate) => {
        return mutate(['/auth', formsContext], data, shouldRevalidate);
    }, [formsContext]);

    return {
        data,
        error: unauthenticated ? undefined : error?.responseStatus,
        loading: data === undefined && error === undefined,
        mutate: boundMutate
    };
}

export default useAuthentication;