import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, TextField, Popover } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { cloneDeep } from "lodash-es";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { QuickLink } from '../../api/dtos';
import { SketchPicker } from 'react-color'
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconPicker, { iconDefaults } from './IconPicker';

interface ICardEdit {
    link: QuickLink;
    open: boolean;
    onOk: (link: QuickLink) => void;
    onClose: () => void;
    adGroups: string[];
}

const CardEdit: FC<ICardEdit> = ({open, onClose, link, onOk, adGroups: groups }) => {
    const [editLink, setEditLink] = useState<QuickLink>({} as any);

    useEffect(() => {
        if (open) {
            setEditLink(cloneDeep(link))
        }
    }, [open, link])

    const [showColor, setShowColor] = useState(false);

    const colorRef = useRef<HTMLDivElement>();

    const [showSubtitleColor, setShowSubtitleColor] = useState(false);

    const subtitleColorRef = useRef<HTMLDivElement>();

    const [showIconColor, setShowIconColor] = useState(false);

    const iconColorRef = useRef<HTMLDivElement>();

    const [showIcon, setShowIcon] = useState(false);

    const iconRef = useRef<HTMLDivElement>();

    
    const icon = useMemo(() => {
        if (!editLink.iconUrl) {
            return iconDefaults;
        }

        const iconParts = editLink.iconUrl?.trim().split(' ');

        if (iconParts.length !== 2) {
            return iconDefaults;
        }

        const prefix = iconParts[0];
        let iconName = iconParts[1];

        if (!iconName.startsWith('fa-') || iconName.length <= 3) {
            return iconDefaults;
        }

        iconName = iconName.substring(3)

        return {
            prefix,
            iconName
        }
    }, [editLink.iconUrl])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Edit Link Configuration</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TextField 
                        label="Title"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editLink.title ?? ''}
                        onChange={(e) => {
                            setEditLink(c => ({
                                ...c,
                                title: e.target.value && e.target.value !== '' ? e.target.value : undefined as any
                            }))
                        }}
                    />
                    <TextField 
                        label="Title Color" 
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editLink.color ?? ''}
                        inputProps={{ readOnly: true }}
                        onClick={() => setShowColor(true)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <div
                                        ref={colorRef as any}
                                        style={{
                                            height: 32,
                                            width: 32,
                                            background: editLink.color,
                                            border: `1px solid lightgray`
                                        }}
                                        onClick={() => setShowColor(true)}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton 
                                        style={{
                                            padding: 0,
                                            visibility: editLink.color ? 'visible' : 'hidden'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setEditLink(c => ({
                                                ...c,
                                                color: undefined as any
                                            }))
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} fixedWidth />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Popover
                      id={'edit.color'}
                      open={!!colorRef.current && showColor}
                      anchorEl={colorRef.current}
                      onClose={() => setShowColor(false)}
                      style={{width: 300}}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                        {/* <ClickAwayListener onClickAway={() => setShowColor(false)}> */}
                        <SketchPicker 
                            color={ editLink.color } 
                            onChange={(e) => {
                                setEditLink(c => ({
                                    ...c,
                                    color: e.hex
                                }))
                            }}
                            presetColors={["#004842","#cd1342"]}
                        />
                        {/* </ClickAwayListener> */}
                    </Popover>
                    <TextField 
                        label="Subtitle"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editLink.subtitle ?? ''}
                        onChange={(e) => {
                            setEditLink(c => ({
                                ...c,
                                subtitle: e.target.value && e.target.value !== '' ? e.target.value : undefined as any
                            }))
                        }}
                    />
                    <TextField 
                        label="Subtitle Color" 
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editLink.subtitleColor ?? ''}
                        inputProps={{ readOnly: true }}
                        onClick={() => setShowSubtitleColor(true)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <div
                                        ref={subtitleColorRef as any}
                                        style={{
                                            height: 32,
                                            width: 32,
                                            background: editLink.subtitleColor,
                                            border: `1px solid lightgray`
                                        }}
                                        onClick={() => setShowSubtitleColor(true)}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton 
                                        style={{
                                            padding: 0,
                                            visibility: editLink.subtitleColor ? 'visible' : 'hidden'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setEditLink(c => ({
                                                ...c,
                                                subtitleColor: undefined as any
                                            }))
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} fixedWidth />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Popover
                      id={'edit.color'}
                      open={!!subtitleColorRef.current && showSubtitleColor}
                      anchorEl={subtitleColorRef.current}
                      onClose={() => setShowSubtitleColor(false)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                        {/* <ClickAwayListener onClickAway={() => setShowColor(false)}> */}
                        <SketchPicker 
                            color={ editLink.subtitleColor } 
                            onChange={(e) => {
                                setEditLink(c => ({
                                    ...c,
                                    subtitleColor: e.hex
                                }))
                            }}
                            presetColors={["#004842","#cd1342"]}
                        />
                        {/* </ClickAwayListener> */}
                    </Popover>
                    <TextField 
                        label="Icon" 
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editLink.iconUrl ?? ''}
                        inputProps={{ readOnly: true }}
                        onClick={() => setShowIcon(true)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton 
                                        style={{
                                            padding: 0
                                        }}
                                        onClick={() => setShowIcon(true)}
                                        ref={iconRef as any}
                                    >
                                        <FontAwesomeIcon icon={icon as any} fixedWidth />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton 
                                        style={{
                                            padding: 0,
                                            visibility: editLink.iconUrl ? 'visible' : 'hidden'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setEditLink(c => ({
                                                ...c,
                                                iconUrl: undefined as any
                                            }))
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} fixedWidth />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
					<IconPicker 
                        value={editLink.iconUrl ? icon as any : undefined}
                        open={showIcon} 
                        onClose={() => setShowIcon(false)} 
                        anchorRef={iconRef}  
                        onUpdate={(icon) => {
                            setEditLink(c => ({
                                ...c,
                                iconUrl: `${icon.prefix} fa-${icon.iconName}`
                            }))
                        }}
                    />
                    <TextField 
                        label="Icon Color" 
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editLink.iconColor ?? ''}
                        inputProps={{ readOnly: true }}
                        onClick={() => setShowIconColor(true)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <div
                                        ref={iconColorRef as any}
                                        style={{
                                            height: 32,
                                            width: 32,
                                            background: editLink.iconColor,
                                            border: `1px solid lightgray`
                                        }}
                                        onClick={() => setShowIconColor(true)}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton 
                                        style={{
                                            padding: 0,
                                            visibility: editLink.iconColor ? 'visible' : 'hidden'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setEditLink(c => ({
                                                ...c,
                                                iconColor: undefined as any
                                            }))
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} fixedWidth />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Popover
                      id={'edit.color'}
                      open={!!iconColorRef.current && showIconColor}
                      anchorEl={iconColorRef.current}
                      onClose={() => setShowIconColor(false)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                        {/* <ClickAwayListener onClickAway={() => setShowColor(false)}> */}
                        <SketchPicker 
                            color={ editLink.iconColor } 
                            onChange={(e) => {
                                setEditLink(c => ({
                                    ...c,
                                    iconColor: e.hex
                                }))
                            }}
                            presetColors={["#004842","#cd1342"]}
                        />
                        {/* </ClickAwayListener> */}
                    </Popover>
                    <TextField 
                        label="URL"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editLink.url ?? ''}
                        onChange={(e) => {
                            setEditLink(c => ({
                                ...c,
                                url: e.target.value && e.target.value !== '' ? e.target.value : undefined as any
                            }))
                        }}
                    />
                    <TextField 
                        label="Open In New Tab" 
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Checkbox 
                                        checked={editLink.newTab}
                                        onChange={(e, checked) => {
                                            setEditLink(c => ({
                                                ...c,
                                                newTab: checked
                                            }))
                                        }}
                                    />
                                </InputAdornment>
                            )
                        }}
                        value={editLink.newTab ? "Link Will Open in a New Tab" : "Link Will Open in the Current Tab"}
                    />
                    <TextField 
                        label="Enabled" 
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Checkbox 
                                        checked={editLink.enabled}
                                        onChange={(e, checked) => {
                                            setEditLink(c => ({
                                                ...c,
                                                enabled: checked
                                            }))
                                        }}
                                    />
                                </InputAdornment>
                            )
                        }}
                        value={editLink.enabled ? "Enabled" : "Disabled"}
                    />
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={groups}
                        value={editLink.adGroups}
                        onChange={(event, newValue) => {
                            setEditLink(c => ({
                                ...c,
                                adGroups: newValue
                            }))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="standard"
                                margin="normal"
                                label="Restricted To"
                                helperText="AD Groups - Any of the above groups give access"
                            />
                        )}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button 
                    onClick={() => {
                        onOk(editLink);
                        onClose();
                    }} 
                    color="primary" 
                    autoFocus
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CardEdit;