import { Grid, Hidden, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Field, FieldProvider, Text, Numeric, DatePicker, FuzzySelect, FormLabel } from "@ngt/forms";
import { useFormActions, useScopedField } from "@ngt/forms-core";
import React, { FunctionComponent, useEffect } from "react";
import * as Dtos from '../../api/dtos';
import FormContainer from "../../components/layouts/FormContainer";
import { fieldMargin, compactGridFieldMargin } from "../../constants/forms";
import { DOLLAR_PREFIX } from "../../constants/inputAdornments";
import { INTEGER_FORMAT, MONEY_FORMAT } from "../../constants/numberFormats";

interface IBudgetTableProps {
    code: string;
    name: string;
    fields: Array<{ code: string; name: string; }>;
}

const useTableStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(2, 0, 2, 0)
    },
    code: {
        color: theme.palette.primary.main,
        width: 120,
        maxWidth: 120,
        minWidth: 120
    },
    name: {
        color: theme.palette.primary.main
    },
    budget: {
        color: theme.palette.primary.main,
        width: 180,
        maxWidth: 180,
        minWidth: 180
    }
}));

const BudgetTable: FunctionComponent<IBudgetTableProps> = ({
    code,
    fields,
    name
}) => {
    const classes = useTableStyles();

    return (
        <TableContainer component={Paper} className={classes.paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.code}><strong>{code}</strong></TableCell>
                        <TableCell className={classes.name}><strong>{name}</strong></TableCell>
                        <TableCell align="right" className={classes.budget}><strong>Original Budget</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields && fields.map((row) => (
                        <FieldProvider key={row.name} name={row.name} autoRegister={false}>
                            <TableRow>
                                <TableCell scope="row">
                                    {row.code}
                                </TableCell>
                                <TableCell>
                                    <FormLabel />
                                </TableCell>
                                <TableCell>
                                    <Field
                                        name={row.name}
                                        component={Numeric}
                                        numberFormat={MONEY_FORMAT}
                                        margin="none"
                                        label={null}
                                        FieldProps={{
                                            prependParentName: false
                                        }}
                                        InputProps={DOLLAR_PREFIX}
                                    />
                                </TableCell>
                            </TableRow>
                        </FieldProvider>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const salesExpenseProps: IBudgetTableProps = {
    code: "120-000",
    name: "Sales Expenses",
    fields: [
        {
            code: "120-020",
            name: "salesExpensesLegalFees"
        },
        {
            code: "120-040",
            name: "salesCommission"
        },
        {
            code: "120-050",
            name: "internalAgentsCommission"
        },
        {
            code: "120-060",
            name: "salesExpensesCouncilRates"
        },
        {
            code: "120-080",
            name: "salesExpensesWaterRates"
        },
        {
            code: "120-100",
            name: "salesExpensesLandTax"
        },
        {
            code: "120-120",
            name: "salesExpensesFinanceFees"
        },
        {
            code: "120-140",
            name: "interestPaid"
        },
        {
            code: "120-150",
            name: "pexaFee"
        }
    ]
}

const stateAuthorityProps: IBudgetTableProps = {
    code: "210-000",
    name: "State Authority Fees",
    fields: [
        {
            code: "210-010",
            name: "localGovernmentFees"
        },
        {
            code: "210-020",
            name: "stateGovernmentFees"
        },
        {
            code: "210-030",
            name: "federalGovernmentFees"
        },
        {
            code: "210-040",
            name: "landAndPropertyInformationFees"
        },
        {
            code: "210-050",
            name: "sectionNinetyFourContributions"
        },
        {
            code: "210-060",
            name: "stateInfrastructureContributions"
        },
        {
            code: "210-070",
            name: "stateAuthorityMiscellaneous"
        }
    ]
}

const supplyAuthorityProps: IBudgetTableProps = {
    code: "220-000",
    name: "Supply Authority Fees",
    fields: [
        {
            code: "220-010",
            name: "sewerAndWater"
        },
        {
            code: "220-020",
            name: "power"
        },
        {
            code: "220-030",
            name: "communications"
        },
        {
            code: "220-040",
            name: "gas"
        },
        {
            code: "220-050",
            name: "supplyAuthorityMiscellaneous"
        }
    ]
}

const professionalProps: IBudgetTableProps = {
    code: "230-000",
    name: "Professional Fees",
    fields: [
        {
            code: "230-010",
            name: "architect"
        },
        {
            code: "230-020",
            name: "structuralEngineer"
        },
        {
            code: "230-030",
            name: "civilEngineer"
        },
        {
            code: "230-040",
            name: "electricalEngineer"
        },
        {
            code: "230-050",
            name: "hydraulicEngineer"
        },
        {
            code: "230-060",
            name: "mechanicalEngineer"
        },
        {
            code: "230-070",
            name: "geotechnicalEngineer"
        },
        {
            code: "230-080",
            name: "communicationEngineer"
        },
        {
            code: "230-090",
            name: "surveyor"
        },
        {
            code: "230-100",
            name: "townPlanner"
        },
        {
            code: "230-110",
            name: "trafficEngineer"
        },
        {
            code: "230-120",
            name: "acousticEngineer"
        },
        {
            code: "230-130",
            name: "environmentalConsultant"
        },
        {
            code: "230-140",
            name: "floraAndFaunaConsultant"
        },
        {
            code: "230-150",
            name: "bushFireConsultant"
        },
        {
            code: "230-160",
            name: "landscapeArchitect"
        },
        {
            code: "230-170",
            name: "projectManager"
        },
        {
            code: "230-180",
            name: "valuerOrMarketAnalyst"
        },
        {
            code: "230-190",
            name: "strataOrCommunityTitleManager"
        },
        {
            code: "230-200",
            name: "pcaOrBcaSurveyor"
        },
        {
            code: "230-210",
            name: "superintendent"
        },
        {
            code: "230-215",
            name: "heritageConsultant"
        },
        {
            code: "230-220",
            name: "professionalMiscellaneous"
        }
    ]
}



const constructionWorksProps: IBudgetTableProps = {
    code: "240-000",
    name: "Construction Works",
    fields: [
        {
            code: "240-010",
            name: "demolition"
        },
        {
            code: "240-020",
            name: "civilWorks"
        },
        {
            code: "240-030",
            name: "buildingWorks"
        },
        {
            code: "240-040",
            name: "roadOrIntersectionConstruction"
        },
        {
            code: "240-050",
            name: "powerConstruction"
        },
        {
            code: "240-060",
            name: "waterConstruction"
        },
        {
            code: "240-070",
            name: "sewerConstruction"
        },
        {
            code: "240-080",
            name: "communicationsConstruction"
        },
        {
            code: "240-090",
            name: "otherServices"
        },
        {
            code: "240-100",
            name: "fencing"
        },
        {
            code: "240-110",
            name: "clearingOrSlashingOrMulching"
        },
        {
            code: "240-120",
            name: "streetTreePlanting"
        },
        {
            code: "240-130",
            name: "landscapingAndEstablishment"
        },
        {
            code: "240-140",
            name: "openSpaceEmbellishments"
        },
        {
            code: "240-150",
            name: "constructionContingency"
        },
        {
            code: "240-160",
            name: "constructionWorksMiscellaneous"
        },
    ]
}

const marketingProps: IBudgetTableProps = {
    code: "250-000",
    name: "Marketing",
    fields: [
        {
            code: "250-010",
            name: "marketingConsultant"
        },
        {
            code: "250-020",
            name: "advertising"
        },
        {
            code: "250-030",
            name: "signage"
        },
        {
            code: "250-040",
            name: "website"
        },
        {
            code: "250-050",
            name: "promotionalMaterial"
        },
        {
            code: "250-060",
            name: "events"
        },
        {
            code: "250-070",
            name: "sponsorships"
        },
        {
            code: "250-080",
            name: "referals"
        },
        {
            code: "250-090",
            name: "marketingMiscellaneous"
        },
    ]
}

const salesProps: IBudgetTableProps = {
    code: "260-000",
    name: "Sales",
    fields: [
        {
            code: "260-010",
            name: "salesCentreRAndM"
        },
        {
            code: "260-020",
            name: "salesCentreConsumerables"
        },
        {
            code: "260-030",
            name: "estatePresentation"
        },
        {
            code: "260-040",
            name: "membershipsOrNetworkingEvents"
        },
        {
            code: "260-050",
            name: "salesMiscellaneous"
        },
        {
            code: "260-060",
            name: "telephone"
        },
        {
            code: "260-070",
            name: "utilities"
        },
        {
            code: "260-080",
            name: "rent"
        },
    ]
}

const projectManagementProps: IBudgetTableProps = {
    code: "270-000",
    name: "Project Management",
    fields: [
        {
            code: "270-010",
            name: "developmentManagementFee"
        },
        {
            code: "270-020",
            name: "projectManagementLegalFees"
        },
        {
            code: "270-030",
            name: "projectManagementFinanceFees"
        },
        {
            code: "270-040",
            name: "printingAndStationary"
        },
        {
            code: "270-050",
            name: "security"
        },
        {
            code: "270-060",
            name: "travelAndAccommodation"
        },
        {
            code: "270-070",
            name: "projectContingency"
        },
        {
            code: "270-080",
            name: "projectManagementLandTax"
        },
        {
            code: "270-090",
            name: "projectManagementCouncilRates"
        },
        {
            code: "270-100",
            name: "projectManagementWaterRates"
        },
        {
            code: "270-110",
            name: "strataOrCommunityManagementFees"
        },
        {
            code: "270-120",
            name: "otherRatesAndTaxes"
        },
        {
            code: "270-130",
            name: "projectManagementMiscellaneous"
        },
        {
            code: "270-140",
            name: "gasAndElectricityUsage"
        },
    ]
}

interface INewJobRequestProps { }

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 2, 2, 2),
        margin: theme.spacing(2, 0, 2, 0)
    },
    subHeading: {
        padding: theme.spacing(0, 0, 0, 0),
        margin: theme.spacing(3, 0, 0, 0)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    column: {
        '& > :first-child': {
            margin: theme.spacing(0, 0, 2, 0)
        },

        '& > :last-child': {
            margin: theme.spacing(2, 0, 0, 0)
        }
    }
}));

// const valuesSubscription = { values: true };

//const jobNumberFilterParams: ILookupFilterProps<Dtos.NewJobRequest> = {
//    subscription: valuesSubscription,
//    filterFunction: (formState, _, lookup) => {
//        return {
//            propertyName: lookup?.propertyName ?? '',
//            items: lookup?.items.filter(item => {
//                return formState.values.projectId !== undefined &&
//                    (item as Dtos.LookupItem).parent === formState.values.projectId
//            }) ?? []
//        }
//    }
//}

//const stageNumberFilterParams: ILookupFilterProps<Dtos.NewJobRequest> = {
//    subscription: valuesSubscription,
//    filterFunction: (formState, _, lookup) => {
//        return {
//            propertyName: lookup?.propertyName ?? '',
//            items: lookup?.items.filter(item => {
//                return formState.values.projectId !== undefined &&
//                    (item as Dtos.LookupItem).parent == formState.values.projectId
//            }) ?? []
//        }
//    }
//}

const subscription = {};

const TotalsUpdater: FunctionComponent = () => {
    const { subscribe } = useFormActions<Dtos.NewJobRequest, Dtos.IValidationError>();
    const { context: { setValue: setTotalOriginalBudget } } = useScopedField<Dtos.NewJobRequest['totalOriginalBudget'], Dtos.IValidationError>('totalOriginalBudget', subscription);
    const { context: { setValue: setCostPerLot } } = useScopedField<Dtos.NewJobRequest['costPerLot'], Dtos.IValidationError>('costPerLot', subscription);

    useEffect(() => {
        const unsubscribe = subscribe(
            formState => {
                // const { items } = formState.values;

                const values = formState.values;

                const totalAmount =
                    (values.salesExpensesLegalFees ?? 0) +
                    (values.salesCommission ?? 0) +
                    (values.internalAgentsCommission ?? 0) +
                    (values.salesExpensesCouncilRates ?? 0) +
                    (values.salesExpensesWaterRates ?? 0) +
                    (values.salesExpensesLandTax ?? 0) +
                    (values.salesExpensesFinanceFees ?? 0) +
                    (values.interestPaid ?? 0) +
                    (values.pexaFee ?? 0) +
                    (values.localGovernmentFees ?? 0) +
                    (values.stateGovernmentFees ?? 0) +
                    (values.federalGovernmentFees ?? 0) +
                    (values.landAndPropertyInformationFees ?? 0) +
                    (values.sectionNinetyFourContributions ?? 0) +
                    (values.stateInfrastructureContributions ?? 0) +
                    (values.stateAuthorityMiscellaneous ?? 0) +
                    (values.sewerAndWater ?? 0) +
                    (values.power ?? 0) +
                    (values.communications ?? 0) +
                    (values.gas ?? 0) +
                    (values.supplyAuthorityMiscellaneous ?? 0) +
                    (values.architect ?? 0) +
                    (values.structuralEngineer ?? 0) +
                    (values.civilEngineer ?? 0) +
                    (values.electricalEngineer ?? 0) +
                    (values.hydraulicEngineer ?? 0) +
                    (values.mechanicalEngineer ?? 0) +
                    (values.geotechnicalEngineer ?? 0) +
                    (values.communicationEngineer ?? 0) +
                    (values.surveyor ?? 0) +
                    (values.townPlanner ?? 0) +
                    (values.trafficEngineer ?? 0) +
                    (values.acousticEngineer ?? 0) +
                    (values.environmentalConsultant ?? 0) +
                    (values.floraAndFaunaConsultant ?? 0) +
                    (values.bushFireConsultant ?? 0) +
                    (values.landscapeArchitect ?? 0) +
                    (values.projectManager ?? 0) +
                    (values.valuerOrMarketAnalyst ?? 0) +
                    (values.strataOrCommunityTitleManager ?? 0) +
                    (values.pcaOrBcaSurveyor ?? 0) +
                    (values.superintendent ?? 0) +
                    (values.heritageConsultant ?? 0) +
                    (values.professionalMiscellaneous ?? 0) +
                    (values.demolition ?? 0) +
                    (values.civilWorks ?? 0) +
                    (values.buildingWorks ?? 0) +
                    (values.roadOrIntersectionConstruction ?? 0) +
                    (values.powerConstruction ?? 0) +
                    (values.waterConstruction ?? 0) +
                    (values.sewerConstruction ?? 0) +
                    (values.communicationsConstruction ?? 0) +
                    (values.otherServices ?? 0) +
                    (values.fencing ?? 0) +
                    (values.clearingOrSlashingOrMulching ?? 0) +
                    (values.streetTreePlanting ?? 0) +
                    (values.landscapingAndEstablishment ?? 0) +
                    (values.openSpaceEmbellishments ?? 0) +
                    (values.constructionContingency ?? 0) +
                    (values.constructionWorksMiscellaneous ?? 0) +
                    (values.marketingConsultant ?? 0) +
                    (values.advertising ?? 0) +
                    (values.signage ?? 0) +
                    (values.website ?? 0) +
                    (values.promotionalMaterial ?? 0) +
                    (values.events ?? 0) +
                    (values.sponsorships ?? 0) +
                    (values.referals ?? 0) +
                    (values.marketingMiscellaneous ?? 0) +
                    (values.salesCentreRAndM ?? 0) +
                    (values.salesCentreConsumerables ?? 0) +
                    (values.estatePresentation ?? 0) +
                    (values.membershipsOrNetworkingEvents ?? 0) +
                    (values.salesMiscellaneous ?? 0) +
                    (values.telephone ?? 0) +
                    (values.utilities ?? 0) +
                    (values.rent ?? 0) +
                    (values.developmentManagementFee ?? 0) +
                    (values.projectManagementLegalFees ?? 0) +
                    (values.projectManagementFinanceFees ?? 0) +
                    (values.printingAndStationary ?? 0) +
                    (values.security ?? 0) +
                    (values.travelAndAccommodation ?? 0) +
                    (values.projectContingency ?? 0) +
                    (values.projectManagementLandTax ?? 0) +
                    (values.projectManagementCouncilRates ?? 0) +
                    (values.projectManagementWaterRates ?? 0) +
                    (values.strataOrCommunityManagementFees ?? 0) +
                    (values.otherRatesAndTaxes ?? 0) +
                    (values.projectManagementMiscellaneous ?? 0) +
                    (values.gasAndElectricityUsage ?? 0) ?? 0;

                setTotalOriginalBudget(totalAmount, false, true, true);
                setCostPerLot(totalAmount / (values.numberOfLots ? values.numberOfLots : 1), false, true, true);
            },
            {
                values: true,
                dirty: false,
                errors: false,
                fields: false,
                focused: false,
                initialValues: false,
                submitting: false,
                touched: false,
                validating: false
            }
        );

        return () => {
            unsubscribe();
        }
    }, [setTotalOriginalBudget, setCostPerLot, subscribe]);

    return null;
}

const NewJobRequest: React.FunctionComponent<INewJobRequestProps> = () => {

    const classes = useStyles();

    const authorisations = (
        <>
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                Authorisations
            </Typography>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Field
                            name="enteredBy"
                            component={Text}
                            margin={compactGridFieldMargin}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            name="enteredDate"
                            component={DatePicker}
                            margin={compactGridFieldMargin}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Field
                            name="approved"
                            component={FuzzySelect}
                            margin={compactGridFieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            name="approvedDate"
                            component={DatePicker}
                            margin={compactGridFieldMargin}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Field
                            name="authorised"
                            component={FuzzySelect}
                            margin={compactGridFieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            name="authorisedDate"
                            component={DatePicker}
                            margin={compactGridFieldMargin}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Field
                            name="finalised"
                            component={Text}
                            margin={compactGridFieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            name="finalisedDate"
                            component={DatePicker}
                            margin={compactGridFieldMargin}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );

    return (
        <FormContainer
            formType={ Dtos.NewJobRequest }
        >
            { /* FieldProvider is placed here so form level errors will appear on the form. */}
            <FieldProvider name="id" />
            <Grid container spacing={2} className={classes.row}>
                <Grid item xs={12} lg={6} className={classes.column}>
                    <Paper className={classes.paper}>
                        <Field
                            name="projectId"
                            component={FuzzySelect}
                            margin={fieldMargin}
                        />
                        <Field
                            name="jobCode"
                            // component={FuzzySelect}
                            component={Text}
                            margin={fieldMargin}
                            // LookupFilterProps={jobNumberFilterParams}
                        />
                        <Field
                            name="stageNumber"
                            // component={FuzzySelect}
                            component={Text}
                            margin={fieldMargin}
                            // LookupFilterProps={stageNumberFilterParams}
                        />
                        <Field
                            name="numberOfLots"
                            component={Numeric}
                            numberFormat={INTEGER_FORMAT}
                            margin={fieldMargin}
                        />
                        {/*<Field*/}
                        {/*    name="date"*/}
                        {/*    component={DatePicker}*/}
                        {/*    margin={fieldMargin}*/}
                        {/*/>*/}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6} className={classes.column}>
                    <BudgetTable
                        {...salesExpenseProps}
                    />
                    <BudgetTable
                        {...stateAuthorityProps}
                    />
                    <BudgetTable
                        {...supplyAuthorityProps}
                    />
                    <BudgetTable
                        {...professionalProps}
                    />
                    <Hidden mdDown>
                        {authorisations}
                    </Hidden>
                </Grid>
                <Grid item xs={12} lg={6} className={classes.column}>
                    <BudgetTable
                        {...constructionWorksProps}
                    />
                    <BudgetTable
                        {...marketingProps}
                    />
                    <BudgetTable
                        {...salesProps}
                    />
                    <BudgetTable
                        {...projectManagementProps}
                    />
                    <Paper className={classes.paper}>
                        <TotalsUpdater />
                        <Field
                            name="totalOriginalBudget"
                            component={Numeric}
                            numberFormat={MONEY_FORMAT}
                            margin={fieldMargin}
                            InputProps={DOLLAR_PREFIX}
                            disabled
                        />
                        <Field
                            name="costPerLot"
                            component={Numeric}
                            numberFormat={MONEY_FORMAT}
                            margin={fieldMargin}
                            InputProps={DOLLAR_PREFIX}
                            disabled
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Hidden lgUp>
                {authorisations}
            </Hidden>
        </FormContainer>
    );
}

export default NewJobRequest;