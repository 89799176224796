import { Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, fade, Grid, IconButton, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useMemo, useRef, useState } from "react";
import { useDrag, useDrop, XYCoord, DropTargetMonitor } from "react-dnd";
import { Identifier } from "dnd-core";
import { ItemTypes } from "../../utilities/dnd";
import { QuickLink, QuickLinkGroup } from "../../api/dtos";
import produce from "immer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faFileTimes, faGripLines } from "@fortawesome/pro-duotone-svg-icons";
import CardEdit from "./CardEdit";
import IconPicker, { iconDefaults } from "./IconPicker";

export interface ICardContext {
    parentColor?: string;
	parent?: QuickLinkGroup
	parentPath?: string;
	editMode: boolean;
	level: number;
    adGroups: string[],
    saving?: boolean
}

export interface ICardItem {
    items?: QuickLink[];
    item: QuickLink;
    context: ICardContext;
    index: number;
    moveItem: (drop: ICardItemDropType, dropZone: ICardItem, monitor: DropTargetMonitor<ICardItemDropType>, hoverBoundingRect: DOMRect) => void;
    addItem: (item: QuickLink, index: number) => void;
    removeItem: (index: number) => void;
    setItems: (setFn: (items: QuickLink[]) => QuickLink[]) => void;
}


enum BackgroundType {
    Left = 1,
    Right = 2
}

const useStyles = makeStyles((theme) => ({
    CardContainer: {
        paddingTop: theme.spacing(3),
        //paddingBottom: (props: any) => props.hasChildren ? 0 : theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginTop: -1,
        //borderBottom: "1px solid lightgrey",
        borderBottom: (props: any) => props.level > 1 ? 'none' : "1px solid lightgrey",
        borderRadius: 0,

        '&:last-child': {
             paddingBottom: (props: any) => props.level > 1 ? 0 : theme.spacing(3),
             //borderBottom: (props: any) => props.level > 1 ? 'none' : "1px solid lightgrey"
        }
    },
    CardEditContainer: {
        padding: theme.spacing(3, 0, 3, 3),
        marginTop: -1,
        borderLeft:"1px solid lightgrey",
        borderTop:"1px solid lightgrey",
        borderBottom: "1px solid lightgrey",

        '& $CardEditContainer': {
            borderRight: 'none'
        }
    },




    

    item: {
        height: '100%',
        display: 'flex',
        alignItems: 'stretch', 
        willChange: 'transform',

        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        cursor: ({isEdit}: any) => isEdit ? 'initial' : 'pointer',

        
        opacity: ({isDragging, disabled}: any) => isDragging ? 0 : 1,
        background: ({isOverShallow, backgroundType, titleColor, iconColor, disabled}: any) => {
            const defaultColor = disabled ? theme.palette.grey[300] : fade(theme.palette.common.white, 1);
            const defaultHoverColor = disabled ? theme.palette.grey[800] : titleColor;

            return isOverShallow ? 
                backgroundType === BackgroundType.Left ?
                    disabled ? defaultColor : theme.palette.common.white :
                    backgroundType === BackgroundType.Right ?
                        `linear-gradient(270deg, ${fade(defaultHoverColor, 0.5)} 0%, ${defaultColor} 30px, ${defaultColor} 100%)` : 
                        disabled ? defaultColor : theme.palette.common.white :
                        disabled ? defaultColor : theme.palette.common.white;
        },

        '&:hover': {
            background: (props: any) => !props.isEdit ? fade(props.titleColor, 0.15) : props.disabled ? 'transparent' : theme.palette.common.white
        }
    },
    titleWrapper: {
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: theme.spacing(1),
        display: 'flex'
    },
    title: {
        width: '100%',
        fontSize: '1rem',
        margin: 0,
        color: (props: any) => props.titleColor
    },
    subtitle: {
        fontSize: '0.875rem',
        margin: 0,
        color: (props: any) => props.subtitleColor
    },
    iconWrapper: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        background: ({isOverShallow, backgroundType, titleColor, iconColor, disabled}: any) => {
            const defaultBackgroundColor = disabled ? theme.palette.grey[800] : titleColor;
            const defaultIconColor = disabled ? fade(theme.palette.common.white, 0.5) : fade(iconColor, 0.5);

            return isOverShallow ? 
                backgroundType === BackgroundType.Left ?
                    `linear-gradient(90deg, ${defaultIconColor} 0%, ${defaultBackgroundColor} 30px, ${defaultBackgroundColor} 100%)` :
                    defaultBackgroundColor :
                    defaultBackgroundColor
        },
        color: (props: any) => props.iconColor,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    icon: {
        fontSize: '3em',
        overflow: 'visible'
    },
    editWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8
    }
}));

export interface ICardItemDropType {
    item: QuickLink; 
    index: number;
    context?: ICardContext;
}

export const CardEdgeWidth = 40;

const CardItem = (props: ICardItem) => {
    const {
        item,
        index,
        context,
        removeItem,
        setItems,
        moveItem
    } = props;

    const theme = useTheme();

    const ref = useRef<HTMLDivElement>(null)
    
    const [backgroundType, setBackgroundType] = useState<BackgroundType | undefined>();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [editConfiguration, setEditConfiguration] = useState(false);

    const [{ handlerId, isOverShallow }, drop] = useDrop<
        ICardItemDropType,
        void,
        { handlerId: Identifier | null; isOverShallow: boolean; }
    >({
        accept: ItemTypes.CARD,
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
                isOverShallow: monitor.isOver({shallow: true})
            }
        },
        hover: (item, monitor) => {
            if (!ref.current) {
                return
            }

            if (!monitor.isOver({shallow: true})) {
                return;
            }

            if (item.context?.parent?.id === context?.parent?.id && item.index === index) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverWidth =
                (hoverBoundingRect.right - hoverBoundingRect.left);

            // Get vertical middle
            const hoverMiddleX =
                hoverWidth / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

            const placeLeft = hoverClientX < hoverMiddleX;
            const placeRight = !placeLeft;

            // Top of item
            if (placeLeft) {
                setBackgroundType(BackgroundType.Left)
                return
            }

            // Bottom of item
            if (placeRight) {
                setBackgroundType(BackgroundType.Right)
                return
            }
            
        },
        drop: (item, monitor) => {
            if (monitor.didDrop()) {
                return;
            }

            if (!ref.current) {
                return
            }

            if (item.context?.parent?.id === context?.parent?.id && item.index === index) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            moveItem(item, props, monitor, hoverBoundingRect);
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return {
                item,
                index,
                context
            }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    
    const icon = useMemo(() => {
        if (!item.iconUrl) {
            return iconDefaults;
        }

        const iconParts = item.iconUrl?.trim().split(' ');

        if (iconParts.length !== 2) {
            return iconDefaults;
        }

        const prefix = iconParts[0];
        let iconName = iconParts[1];

        if (!iconName.startsWith('fa-') || iconName.length <= 3) {
            return iconDefaults;
        }

        iconName = iconName.substring(3)

        return {
            prefix,
            iconName
        }
    }, [item.iconUrl])

    const [showIcon, setShowIcon] = useState(false);

    const iconRef = useRef<HTMLDivElement>();


    const iconColor = useMemo(() => {
        return item.iconColor ?? theme.palette.common.white;
    }, [item.iconColor, theme.palette.common.white]);

    const titleColor = useMemo(() => {
        return item.color ?? context.parentColor;
    }, [item.color, context.parentColor]);

    const subtitleColor = useMemo(() => {
        return item.subtitleColor ?? context.parentColor;
    }, [item.subtitleColor, context.parentColor]);

    const classes = useStyles({ level: context.level,backgroundType, isOverShallow, iconColor, titleColor, subtitleColor, isDragging, isEdit: context.editMode, disabled: !item.enabled });

    preview(drop(ref))

    const itemProps = {
        component: context.editMode ? 'div' : 'a',
        href: context.editMode ? undefined : item.url
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <ButtonBase
                ref={ref as any}  
                data-handler-id={handlerId}
                target={ item.newTab ? "_blank" : undefined }
                color="inherit"
                className={classes.item}
                disableRipple={context.editMode}
                {...itemProps as any}
            >
                <div className={classes.iconWrapper}>
                    {
                        (!context.editMode || context.saving) && (
                            <FontAwesomeIcon icon={{ prefix: icon.prefix as any, iconName: icon.iconName as any }} size="2x" fixedWidth />
                        )
                    }
                    {
                        context.editMode && !context.saving && (
                            <ButtonBase 
                                ref={iconRef as any}
                                onClick={() => setShowIcon(true)}
                                style={{padding: 0, borderRadius: '50%'}}
                            >
                                <FontAwesomeIcon icon={{ prefix: icon.prefix as any, iconName: icon.iconName as any }} style={{color: iconColor}} size="2x" fixedWidth />
                            </ButtonBase>
                        )
                    }
                </div>
                <div className={classes.titleWrapper}>
                    <Typography
                        className={classes.title}
                        variant="h6"
                    >
                        <div 
                            contentEditable={context.editMode && !context.saving} 
                            placeholder="(Link Title)" 
                            suppressContentEditableWarning 
                            onBlur={(e) => {
                                const newData = e.currentTarget.textContent;

                                setItems(current => {
                                    const newState = produce(current, draft => {
                                        draft[index].title = newData ?? '';
                                    })

                                    return newState;
                                })
                            }}
                            style={{color: titleColor}}
                        >
                            {item.title}
                        </div>
                        <div 
                            contentEditable={context.editMode && !context.saving} 
                            placeholder="(Link Subtitle)" 
                            suppressContentEditableWarning 
                            onBlur={(e) => {
                                const newData = e.currentTarget.textContent;

                                setItems(current => {
                                    const newState = produce(current, draft => {
                                        draft[index].subtitle = newData ?? '';
                                    })

                                    return newState;
                                })
                            }}
                            style={{color: subtitleColor, fontSize: '0.875rem'}}
                        >
                            {item.subtitle}
                        </div>
                    </Typography>
                </div>
                {
                    context.editMode && (
                        <div className={classes.editWrapper}>
                            <IconButton
                                color="inherit"
                                aria-label="add"
                                ref={!context.saving ? drag : undefined}
                                style={{
                                    padding: 0,
                                    fontSize: '1.2em'
                                }}
                                disabled={context.saving}
                            >
                                <FontAwesomeIcon icon={faGripLines} size="sm" />
                            </IconButton>
                            <Tooltip title="Edit Link Configuration">
                                <IconButton
                                    color="inherit"
                                    aria-label="add"
                                    style={{
                                        padding: 0,
                                        marginTop: 8,
                                        marginBottom: 8,
                                        fontSize: '1.2em'
                                    }}
                                    onClick={() => {
                                        setEditConfiguration(true);
                                    }}
                                    disabled={context.saving}
                                >
                                    <FontAwesomeIcon icon={faCog} size="sm" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Link">
                                <IconButton
                                    color="secondary"
                                    aria-label="delete"
                                    style={{
                                        padding: 0,
                                        fontSize: '1.2em'
                                    }}
                                    onClick={() => {
                                        setConfirmDelete(true);
                                    }}
                                    disabled={context.saving}
                                >
                                    <FontAwesomeIcon icon={faFileTimes} size="sm" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
            </ButtonBase>
            {
                context.editMode && (
                    <>
                        <IconPicker 
                            value={item.iconUrl ? icon as any : undefined}
                            open={showIcon} 
                            onClose={() => setShowIcon(false)} 
                            anchorRef={iconRef}  
                            onUpdate={(icon) => {
                                setItems(current => {
                                    const newState = produce(current, draft => {
                                        draft[index].iconUrl = `${icon.prefix} fa-${icon.iconName}`;
                                    })

                                    return newState;
                                })
                            }}
                        />
                        <CardEdit
                            adGroups={context.adGroups}
                            open={editConfiguration}
                            onClose={() => setEditConfiguration(false)}
                            onOk={(newLink) => {
                                setItems(current => {
                                    const newState = produce(current, draft => {
                                        draft[index] = newLink ?? '';
                                    })

                                    return newState;
                                })
                            }}
                            link={item}
                        />
                        <Dialog
                            open={confirmDelete}
                            onClose={() => setConfirmDelete(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Delete Item?</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Changes will not be finalized until the <strong>SAVE</strong> button is clicked.<br /><br />
                                    Are you sure you wish to delete the Link?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setConfirmDelete(false)} color="secondary">
                                    Cancel
                                </Button>
                                <Button 
                                    onClick={() => {
                                        removeItem(index)
                                    }} 
                                    color="primary" 
                                    autoFocus
                                >
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )
            }
        </Grid>
    );
};

export default CardItem;