import { ResponseStatus } from '@servicestack/client';
import BasicError from './BasicError';
import RequestError from './RequestError';


interface IErrorProps {
    responseStatus?: ResponseStatus;
    title?: string;
    description?: string;
}

const Error: React.FunctionComponent<IErrorProps> = ({
    responseStatus,
    title,
    description
}) => {

    if (responseStatus) {
        return (
            <RequestError responseStatus={responseStatus} />
        )
    }

    return (
        <BasicError
            title={title}
            description={description}
        />
    );
};

export default Error;