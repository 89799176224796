import { DateTime } from 'luxon';

/**
 * Takes date string in ISO format and returns date in short display format.
 * @param isoDate The ISO date we want converted.
 */
export const isoDateToShortDisplayDate = (isoDate: string | undefined) => {
    if (!isoDate) {
        return '';
    }

    const longDate: DateTime = DateTime.fromISO(isoDate);

    if (!longDate.isValid) {
        return '';
    }

    return longDate.toFormat(`dd MMM yyyy`);
}