import { Container, LinearProgress, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Permissions, SaveQuickLinkGroups } from '../api/dtos';
import useQuickLinkGroups from '../hooks/useQuickLinkGroups';
import { DndProvider } from 'react-dnd';
import GroupList from '../components/GroupedCards/GroupList';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { hasPermission } from '../utilities/auth';
import AuthenticationContext from '../contexts/AuthenticationContext';
import FormsContext from "../contexts/FormsContext";
import { ProgressButton, useSnackbar } from '@ngt/forms';
import { AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	relativeWrapper: {
		position: 'relative'
	},
	stickyWrapper: {
		position: 'sticky',
		top: 0,
		right: 0,

		'@media (max-width: 1780px)': {
			display: 'none'
		}
	},
	absoluteWrapper: {
		//position: 'absolute',
		right: 0,
		top: 0,
		padding: theme.spacing(1),
		'& > *': {
			width: 120
		},
		textAlign: 'right',
		marginBottom: -68.5
	},
	containerWrapper: {
		marginTop: 1
	}
}));

export const Home: React.FunctionComponent<any> = () => {
	const classes = useStyles();
	const [editMode, setEditMode] = useState(false);
	const [saving, setSaving] = useState(false);
    const authedUser = useContext(AuthenticationContext);
    const forms = useContext(FormsContext);
	
    const { data, adGroups, loading } = useQuickLinkGroups(editMode, editMode ? { revalidateOnFocus: false, revalidateOnReconnect: false} : undefined);

	const [items, setItems] = useState(data ?? []);

	useEffect(() => {
		setItems(data ?? [])
	}, [data, setItems]);

	const [expanded, setExpanded] = useState<Record<string, boolean>>(() => {
		const storedData = localStorage.getItem("expanded-quicklinks");

		if (!storedData) {
			return {};
		}

		return JSON.parse(storedData);
	});

	const toggleExpanded = useCallback((id: string) => {
		setExpanded((currentExpanded) => {
			const newExpanded = {
				...currentExpanded,
				[id]: !(currentExpanded[id] !== false)
			};

			localStorage.setItem("expanded-quicklinks", JSON.stringify(Object.keys(newExpanded).filter(x => !x.startsWith('group-')).reduce<Record<string, boolean>>((a,b) => { a[b] = newExpanded[b]; return a }, {}) ))

			return newExpanded;
		});
    }, [setExpanded]);

	const {enqueueSnackbar} = useSnackbar();


	const buttons = (
		<div
			className={classes.absoluteWrapper}
		>
			{
				!editMode && (
					<ProgressButton 
						variant="contained" 
						color="secondary" 
						fullWidth 
						onClick={() => setEditMode(c => true)}
					>
						Edit
					</ProgressButton>
				)
			}
			{
				editMode && (
					<>
						<ProgressButton 
							variant="contained" 
							color="secondary" 
							fullWidth 
							loading={saving}
							onClick={async () => {
								setSaving(true);
								try {
									await forms.client.post(new SaveQuickLinkGroups({ data: items }));
									setEditMode(c => false)

									enqueueSnackbar(
										<>
											<AlertTitle>
												Save Successful!
											</AlertTitle>
											The quick links have been successfully saved.
										</>,
										{
											variant: 'success' 
										}
									)
								}
								catch {

									enqueueSnackbar(
										<>
											<AlertTitle>
												Save Failed!
											</AlertTitle>
											An error occurred while attempting to save the quick links.
										</>,
										{
											variant: 'error' 
										}
									)
								}
								setSaving(false);
							}}

						>
							Save
						</ProgressButton>
						<ProgressButton 
							variant="contained" 
							style={{marginLeft: 8}} 
							fullWidth 
							onClick={() => setEditMode(c => false)}
							loading={saving}
						>
							Cancel
						</ProgressButton>
					</>
				)
			}
		</div>
	);

	// const testRef = useRef();
	return (
		<div
			className={classes.relativeWrapper}
		>
			{ loading && <LinearProgress color="secondary" /> }
			{
				hasPermission(authedUser?.permissions, Permissions.EditQuickLinks) && (
					<div 
						className={classes.stickyWrapper}
					>
						{buttons}
					</div>
				)
			}
			<DndProvider backend={HTML5Backend}>
				<Container
					className={classes.containerWrapper}
				>
					{/* <div ref={testRef} />
					<IconPicker anchorRef={testRef} open={true} onClose={() => {}}/> */}
					<GroupList
						expanded={expanded}
						toggleExpanded={toggleExpanded}
						items={items}
						setItems={setItems}
						context={{ editMode: editMode ?? false, level: 1, adGroups: adGroups ?? [], saving }}
					/>
					{/* <IntranetLinkCardList editMode={editMode} /> */}
				</Container>
			</DndProvider>
			{
				hasPermission(authedUser?.permissions, Permissions.EditQuickLinks) && (
					buttons
				)
			}
		</div>
	);
};
