import { CircularProgress, Container, makeStyles, Typography } from "@material-ui/core"
import { FunctionComponent, useCallback, useState, useEffect } from "react"
import FormSummaryCard from "../components/FormSummaryCard";
import useParentFormSummariesByAuth from "../hooks/useParentFormsByAuth";
import { FixedSizeList } from "react-window";
import { ReactWindowScroller } from 'react-window-scroller';
import useSetFormProcessState from "../hooks/useSetFormProcessState";
import BasicInformation from "../components/layouts/BasicInformation";
import LoadState from "../constants/loadState";
import Error from '../components/layouts/Error';
import { ParentFormSummary, ProcessState } from '../api/dtos';

const useStyles = makeStyles((theme) => ({
    virtualWindowCorrecter: {
        marginLeft: '-8px',
        marginRight: '-8px'
    },
    notification: {
        padding: theme.spacing(3, 0)
    }
}));

const FormsDashboard: FunctionComponent = () => {
    const classes = useStyles();
    const { parentFormSummaries, refreshParentForms, parentFormsLoading } = useParentFormSummariesByAuth();
    const refreshFormsCallback = useCallback(() => { refreshParentForms() }, [refreshParentForms]);
    const { setFormProcessState } = useSetFormProcessState(() => { refreshFormsCallback() });
    
    const [draftForms, setDraftForms] = useState<ParentFormSummary[]>([]);
    const [unprocessedForms, setUnprocessedForms] = useState<ParentFormSummary[]>([]);
    const [processedForms, setProcessedForms] = useState<ParentFormSummary[]>([]);
    const [archivedForms, setArchivedForms] = useState<ParentFormSummary[]>([]);

    useEffect(() => {
        if(parentFormSummaries?.length > 0){
            setDraftForms(parentFormSummaries.filter(f => f.form.processState == ProcessState.Draft)) // eslint-disable-line eqeqeq
            setUnprocessedForms(parentFormSummaries.filter(f => f.form.processState == ProcessState.Unprocessed)) // eslint-disable-line eqeqeq
            setProcessedForms(parentFormSummaries.filter(f => f.form.processState == ProcessState.Processed)) // eslint-disable-line eqeqeq
            setArchivedForms(parentFormSummaries.filter(f => f.form.processState == ProcessState.Archived)) // eslint-disable-line eqeqeq
        }
    }, [parentFormSummaries])

    const genDraftRow = useCallback(({ index, style }) => {
        const parentFormSummary = draftForms[index];

        return <FormSummaryCard
            parentFormSummary={parentFormSummary}
            key={`${parentFormSummary?.formDefinition?.id}-${parentFormSummary?.form?.id}`}
            setFormProcessState={setFormProcessState}
            style={style}
        />
    }, [draftForms, setFormProcessState]);

    const genUnprocessedRow = useCallback(({ index, style }) => {
        const parentFormSummary = unprocessedForms[index];

        return <FormSummaryCard
            parentFormSummary={parentFormSummary}
            key={`${parentFormSummary?.formDefinition?.id}-${parentFormSummary?.form?.id}`}
            setFormProcessState={setFormProcessState}
            style={style}
        />
    }, [unprocessedForms, setFormProcessState]);

    const genProcessedRow = useCallback(({ index, style }) => {
        const parentFormSummary = processedForms[index];

        return <FormSummaryCard
            parentFormSummary={parentFormSummary}
            key={`${parentFormSummary?.formDefinition?.id}-${parentFormSummary?.form?.id}`}
            setFormProcessState={setFormProcessState}
            style={style}
        />
    }, [processedForms, setFormProcessState]);

    const genArchivedRow = useCallback(({ index, style }) => {
        const parentFormSummary = archivedForms[index];

        return <FormSummaryCard
            parentFormSummary={parentFormSummary}
            key={`${parentFormSummary?.formDefinition?.id}-${parentFormSummary?.form?.id}`}
            setFormProcessState={setFormProcessState}
            style={style}
        />
    }, [archivedForms, setFormProcessState]);

    return <Container>
        <Typography
            variant="h1"
        >
            Dashboard
        </Typography>
        {
            parentFormsLoading === LoadState.LoadFail ?
                <div className={classes.notification}>
                    <Error
                        title="Error"
                        description="An error occurred and the list of forms could not be loaded. Please refresh the page and try again."
                    />
                </div> :
                null
        }
        <Typography
            variant="h2"
        >
            Draft Forms
        </Typography>
        {
            parentFormsLoading === LoadState.Loading ?
                <CircularProgress color="secondary"/> :
                null
        }
        {
            parentFormsLoading === LoadState.LoadSuccess ?
                (draftForms?.length ?? 0) === 0 ?
                    <div className={classes.notification}>
                        <BasicInformation
                            title="No Draft Forms"
                            description={`You have no draft forms.`}
                        />
                    </div> :
                    <div className={classes.virtualWindowCorrecter}>
                        <ReactWindowScroller>
                            {({ ref, outerRef, style, onScroll }: any) => (
                                <FixedSizeList
                                    ref={ref}
                                    outerRef={outerRef}
                                    style={style}
                                    height={window.innerHeight}
                                    width={undefined as any}
                                    itemCount={draftForms?.length ?? 0}
                                    itemSize={72}
                                    onScroll={onScroll}
                                    overscanCount={2}
                                >
                                    {genDraftRow}
                                </FixedSizeList>
                            )}
                        </ReactWindowScroller>
                    </div> :
                null
        }
        <Typography
            variant="h2"
        >
            Submitted Forms
        </Typography>
        {
            parentFormsLoading === LoadState.Loading ?
                <CircularProgress color="secondary"/> :
                null
        }
        {
            parentFormsLoading === LoadState.LoadSuccess ?
                (unprocessedForms?.length ?? 0) === 0 ?
                    <div className={classes.notification}>
                        <BasicInformation
                            title="No Submitted Forms"
                            description={`You have no submitted forms.`}
                        />
                    </div> :
                    <div className={classes.virtualWindowCorrecter}>
                        <ReactWindowScroller>
                            {({ ref, outerRef, style, onScroll }: any) => (
                                <FixedSizeList
                                    ref={ref}
                                    outerRef={outerRef}
                                    style={style}
                                    height={window.innerHeight}
                                    width={undefined as any}
                                    itemCount={unprocessedForms?.length ?? 0}
                                    itemSize={72}
                                    onScroll={onScroll}
                                    overscanCount={2}
                                >
                                    {genUnprocessedRow}
                                </FixedSizeList>
                            )}
                        </ReactWindowScroller>
                    </div> :
                null
        }
        <Typography
            variant="h2"
        >
            Processed Forms
        </Typography>
        {
            parentFormsLoading === LoadState.Loading ?
                <CircularProgress color="secondary"/> :
                null
        }
        {
            parentFormsLoading === LoadState.LoadSuccess ?
                (processedForms?.length ?? 0) === 0 ?
                    <div className={classes.notification}>
                        <BasicInformation
                            title="No Processed Forms"
                            description={`You have no processed forms.`}
                        />
                    </div> :
                    <div className={classes.virtualWindowCorrecter}>
                        <ReactWindowScroller>
                            {({ ref, outerRef, style, onScroll }: any) => (
                                <FixedSizeList
                                    ref={ref}
                                    outerRef={outerRef}
                                    style={style}
                                    height={window.innerHeight}
                                    width={undefined as any}
                                    itemCount={processedForms?.length ?? 0}
                                    itemSize={72}
                                    onScroll={onScroll}
                                    overscanCount={2}
                                >
                                    {genProcessedRow}
                                </FixedSizeList>
                            )}
                        </ReactWindowScroller>
                    </div> :
                null
        }
        <Typography
            variant="h2"
        >
            Archived Forms
        </Typography>
        {
            parentFormsLoading === LoadState.Loading ?
                <CircularProgress color="secondary"/> :
                null
        }
        {
            parentFormsLoading === LoadState.LoadSuccess ?
                (archivedForms?.length ?? 0) === 0 ?
                    <div className={classes.notification}>
                        <BasicInformation
                            title="No Archived Forms"
                            description={`You have no processed forms.`}
                        />
                    </div> :
                    <div className={classes.virtualWindowCorrecter}>
                        <ReactWindowScroller>
                            {({ ref, outerRef, style, onScroll }: any) => (
                                <FixedSizeList
                                    ref={ref}
                                    outerRef={outerRef}
                                    style={style}
                                    height={window.innerHeight}
                                    width={undefined as any}
                                    itemCount={archivedForms?.length ?? 0}
                                    itemSize={72}
                                    onScroll={onScroll}
                                    overscanCount={2}
                                >
                                    {genArchivedRow}
                                </FixedSizeList>
                            )}
                        </ReactWindowScroller>
                    </div> :
                null
        }
    </Container>
}

export default FormsDashboard;