import { List } from "@material-ui/core";
import { FC, useCallback, useMemo } from "react";
import { DropTargetMonitor, XYCoord } from 'react-dnd';
import GroupItem, { GroupEdgeWidth, IGroupItem, IGroupItemDropType } from "./GroupItem";
import {produce} from 'immer'
import { QuickLink, QuickLinkGroup } from '../../api/dtos';
import { get, set } from 'lodash-es';
import { ICardItem, ICardItemDropType } from "./CardItem";
import { ItemTypes } from "../../utilities/dnd";

export interface IGroupList extends Omit<IGroupItem, 'item' | 'index' | 'moveItem' | 'addItem' | 'addChildItem' | 'addChildLink' |  'removeItem' | 'moveCard'> {
    moveItem?: (drop: IGroupItemDropType, dropZone: IGroupItem, monitor: DropTargetMonitor<IGroupItemDropType>, hoverBoundingRect: DOMRect) => void;
    
    moveCard?: (drop: ICardItemDropType, dropZone: ICardItem, monitor: DropTargetMonitor<ICardItemDropType>, hoverBoundingRect: DOMRect) => void;
}

const GroupList: FC<IGroupList> = ({
    items,
    ...props
}) => {
    const {
        setItems,
        moveItem: parentMoveItem,
        moveCard: parentMoveCard
    } = props;

    const moveCard = useCallback((drop: ICardItemDropType, dropZone: ICardItem, monitor: DropTargetMonitor<ICardItemDropType>, hoverBoundingRect: DOMRect) => {
        if (parentMoveCard) {
            parentMoveCard(drop, dropZone, monitor, hoverBoundingRect);
            return;
        }

        setItems(current => {
            const newState = produce(current, draft => {

                // Get vertical middle
                const hoverWidth =
                    (hoverBoundingRect.right - hoverBoundingRect.left);

                // Get vertical middle
                const hoverMiddleX =
                    hoverWidth / 2;

                // Determine mouse position
                const clientOffset = monitor.getClientOffset()

                // Get pixels to the top
                const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

                const placeLeft = hoverClientX < hoverMiddleX;
                const placeRight = !placeLeft;

                let sourceParent: QuickLink[] | undefined = undefined;
                let targetParent: QuickLink[] | undefined = undefined;
                
                let currentIndex = drop.index;
                let newIndex = dropZone.index;
                
                if (!sourceParent) {
                    sourceParent = drop.context?.parentPath ? get(draft, drop.context.parentPath) : draft;
                }

                let targetParentPath: string | undefined = undefined;
                
                if (dropZone.context?.parentPath) {
                    targetParentPath = dropZone.context.parentPath;

                    targetParent = get(draft, targetParentPath);
                }
                else {
                    return;
                }
                    
                if (!targetParent && targetParentPath) {
                    set(draft, targetParentPath, []) 
                    targetParent = get(draft, targetParentPath);
                } 

                const currentItem = get(current, drop.context!.parentPath!)[currentIndex];

                // If same parent
                if (dropZone.context?.parent?.id === drop.context?.parent?.id) {
                    // Place above
                    if (placeLeft) {
                        // as the item is same parent, if the drag index is lower than the 
                        // hover index removing the drag index as the first set will shift the indices
                        // and require the hover index to be reduce by 1
                        newIndex = newIndex > currentIndex ?
                            newIndex - 1 : 
                            newIndex;
                    }
    
                    // Place below
                    else if (placeRight) {
                        newIndex = newIndex < currentIndex ?
                            newIndex + 1 : 
                            newIndex;
                    }
                }
                else {
                    // Place below
                    if (placeRight) {
                        newIndex = newIndex + 1
                    }
                }

                sourceParent!.splice(currentIndex, 1);
                targetParent!.splice(newIndex, 0, currentItem as any);
            });

            return newState;
        })
    }, [setItems, parentMoveCard])
    
    const moveItem = useCallback((drop: IGroupItemDropType, dropZone: IGroupItem, monitor: DropTargetMonitor<IGroupItemDropType>, hoverBoundingRect: DOMRect) => {
        if (parentMoveItem) {
            parentMoveItem(drop, dropZone, monitor, hoverBoundingRect);
            return;
        }

        setItems(current => {
            const newState = produce(current, draft => {
                if (monitor.getItemType() === ItemTypes.CARD) {
                    let sourceParent: QuickLink[] | undefined = undefined;
                    let targetParent: QuickLink[] | undefined = undefined;
                    
                    let currentIndex = drop.index;
                    
                    if (!sourceParent) {
                        sourceParent = drop.context?.parentPath ? get(draft, drop.context.parentPath) : draft;
                    }

                    let targetParentPath: string | undefined = undefined;
                    
                    if (dropZone.context?.parentPath) {
                        targetParentPath = `${dropZone.context.parentPath}[${dropZone.index}].links`;
    
                        targetParent = get(draft, targetParentPath);
                    }
                    else {
                        targetParentPath = `[${dropZone.index}].links`;

                        targetParent = get(draft, targetParentPath);
                    }
                        
                    if (!targetParent) {
                        set(draft, targetParentPath, []) 
                        targetParent = get(draft, targetParentPath);
                    } 

                    const currentItem = get(current, drop.context!.parentPath!)[currentIndex];

                    sourceParent!.splice(currentIndex, 1);
                    targetParent!.push(currentItem);

                    return;
                }

                // Get vertical middle
                const hoverHeight =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top);
    
                // Determine mouse position
                const clientOffset = monitor.getClientOffset()
    
                // Get pixels to the top
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

                let sourceParent: QuickLinkGroup[] | undefined = undefined;
                let targetParent: QuickLinkGroup[] | undefined = undefined;
                
                let currentIndex = drop.index;
                let newIndex = dropZone.index;

                const placeAbove = hoverClientY < GroupEdgeWidth;
                const placeBelow = hoverClientY > (hoverHeight - GroupEdgeWidth);
                const placeIn = !placeAbove && !placeBelow;
                
                if (!sourceParent) {
                    sourceParent = drop.context?.parentPath ? get(draft, drop.context.parentPath) : draft;
                }

                let targetParentPath: string | undefined = undefined;
                
                if (placeIn) {
                    targetParentPath = dropZone.context && dropZone.context.parentPath ?  `${dropZone.context.parentPath}[${dropZone.index}].groups` : `[${dropZone.index}].groups`;

                    targetParent = get(draft, targetParentPath);

                    newIndex = targetParent?.length ?? 0;
                }
                else if (dropZone.context?.parentPath) {
                    targetParentPath = dropZone.context.parentPath;

                    targetParent = get(draft, targetParentPath);
                }
                else {
                    targetParent = draft;
                }
                    
                if (!targetParent && targetParentPath) {
                    set(draft, targetParentPath, []) 
                    targetParent = get(draft, targetParentPath);
                } 

                const currentItem = (drop.context?.parentPath ? get(current, drop.context.parentPath) : current)[currentIndex];

                // If same parent
                if (dropZone.context?.parent?.id === drop.context?.parent?.id) {
                    // Place above
                    if (placeAbove) {
                        // as the item is same parent, if the drag index is lower than the 
                        // hover index removing the drag index as the first set will shift the indices
                        // and require the hover index to be reduce by 1
                        newIndex = newIndex > currentIndex ?
                            newIndex - 1 : 
                            newIndex;
                    }
    
                    // Place below
                    else if (placeBelow) {
                        newIndex = newIndex < currentIndex ?
                            newIndex + 1 : 
                            newIndex;
                    }
                }
                else {
                    // Place below
                    if (placeBelow) {
                        newIndex = newIndex + 1
                    }
                }

                sourceParent!.splice(currentIndex, 1);
                targetParent!.splice(newIndex, 0, currentItem as any);
            });

            return newState;
        })
    }, [setItems, parentMoveItem])

    const addItem = useCallback((item: QuickLinkGroup, index: number) => {
        setItems((current) => {
            const newState = produce(current, draft => {
                draft.splice(index, 0, item as any);
            })

            return newState;
        })
    }, [setItems]);

    const addChildItem = useCallback((item: QuickLinkGroup, parentIndex: number, childIndex?: number) => {
        setItems((current) => {
            const newState = produce(current, draft => {
                if (!draft[parentIndex].groups) {
                    draft[parentIndex].groups = [];
                }

                let insertIndex = childIndex;

                if (insertIndex === undefined || insertIndex > draft[parentIndex].groups.length) {
                    insertIndex = draft[parentIndex].groups.length;
                }
                
                draft[parentIndex].groups.splice(insertIndex, 0, item as any);
            })

            return newState;
        })
    }, [setItems]);

    const addChildLink = useCallback((item: QuickLink, parentIndex: number, childIndex?: number) => {
        setItems((current) => {
            const newState = produce(current, draft => {
                if (!draft[parentIndex].links) {
                    draft[parentIndex].links = [];
                }

                let insertIndex = childIndex;

                if (insertIndex === undefined || insertIndex > draft[parentIndex].links.length) {
                    insertIndex = draft[parentIndex].links.length;
                }
                
                draft[parentIndex].links.splice(insertIndex, 0, item as any);
            })

            return newState;
        })
    }, [setItems]);

    const removeItem = useCallback((index: number) => {
        setItems((current) => {
            const newState = produce(current, draft => {
                draft.splice(index, 1);
            })

            return newState;
        })
    }, [setItems]);


    const group = useMemo(() => {
        return items?.map((item, index) => {
            return (
                <GroupItem
                    key={item.id ?? (item as any).reference} 
                    {...props}
                    moveItem={moveItem}
                    moveCard={moveCard}
                    addItem={addItem}
                    addChildItem={addChildItem}
                    addChildLink={addChildLink}
                    removeItem={removeItem}
                    item={item}
                    index={index}
                />
            );
        });
    }, [ // eslint-disable-line react-hooks/exhaustive-deps
        items, 
        moveItem, 
        moveCard, 
        addItem, 
        addChildItem, 
        addChildLink, 
        removeItem, 
        props.moveCard,
        props.moveItem,
        props.setItems,
        props.context.saving,
        props.context.editMode,
        props.context.level,
        props.context.parent,
        props.context.parentPath,
        props.expanded,
        props.toggleExpanded,
        props.context.adGroups
    ])

    return (
        <List disablePadding>
            {
                group
            }
        </List>
    );
};

export default GroupList;