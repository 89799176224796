import { Grid, makeStyles, Paper } from "@material-ui/core";
import { Field, FieldProvider, Text, TextArea, Numeric, DatePicker, FuzzySelect } from "@ngt/forms";
import { useFormActions, useScopedField } from "@ngt/forms-core";
import { FunctionComponent, useEffect } from "react";
import * as Dtos from '../../api/dtos';
import FormContainer from "../../components/layouts/FormContainer";
import { fieldMargin, gridFieldMargin } from "../../constants/forms";
import { DOLLAR_PREFIX, PERCENT_SUFFIX } from "../../constants/inputAdornments";
import { INTEGER_FORMAT, MONEY_FORMAT, PERCENTAGE_FORMAT } from "../../constants/numberFormats";

interface IBankGuarenteeRequestProps { }

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 2, 2, 2),
        margin: theme.spacing(2, 0, 2, 0)
    },
}));
const subscription = {};
const TotalAmountUpdater: FunctionComponent = () => {
    const { subscribe } = useFormActions<Dtos.BankGuaranteeRequest, Dtos.IValidationError>();
    const { context: { setValue: setTotalAmount } } = useScopedField<Dtos.BankGuaranteeRequest['totalAmountOfBankGuarantees'], Dtos.IValidationError>('totalAmountOfBankGuarantees', subscription);

    useEffect(() => {
        const unsubscribe = subscribe(
            formState => {
                const { numberOfGuarantees, amountOfEachGuarantee } = formState.values;

                const totalAmount = (numberOfGuarantees ?? 0) * (amountOfEachGuarantee ?? 0);
                setTotalAmount(totalAmount, false, true, true);
            },
            {
                values: true,
                dirty: false,
                errors: false,
                fields: false,
                focused: false,
                initialValues: false,
                submitting: false,
                touched: false,
                validating: false
            }
        );

        return () => {
            unsubscribe();
        }
    }, [setTotalAmount, subscribe])

    return null;
}

const BankGuaranteeRequest: FunctionComponent<IBankGuarenteeRequestProps> = () => {
    const classes = useStyles();

    return (
        <FormContainer
            maxWidth="md"
            formType={ Dtos.BankGuaranteeRequest }
        >
            { /* FieldProvider is placed here so form level errors will appear on the form. */}
            <FieldProvider name="id" />
            <Paper className={classes.paper}>
                <Field
                    name="ourCompany"
                    component={FuzzySelect}
                    margin={fieldMargin}
                />
                <Field
                    name="nameBankGuaranteesToBeInFavourOf"
                    component={TextArea}
                    rows={2}
                    rowsMax={2}
                    margin={fieldMargin}
                />
                <Field
                    name="address"
                    component={TextArea}
                    rows={2}
                    rowsMax={2}
                    margin={fieldMargin}
                />
                <Field
                    name="projectId"
                    component={FuzzySelect}
                    margin={fieldMargin}
                />
                <Field
                    name="projectDetails"
                    component={TextArea}
                    rows={2}
                    rowsMax={2}
                    margin={fieldMargin}
                />
                <Field
                    name="contractSum"
                    component={Numeric}
                    numberFormat={MONEY_FORMAT}
                    margin={fieldMargin}
                    InputProps={DOLLAR_PREFIX}
                />
                <Field
                    name="percentOfBankGuarantees"
                    component={Numeric}
                    numberFormat={PERCENTAGE_FORMAT}
                    margin={fieldMargin}
                    InputProps={PERCENT_SUFFIX}
                />
                <Field
                    name="numberOfGuarantees"
                    component={Numeric}
                    numberFormat={INTEGER_FORMAT}
                    margin={fieldMargin}
                />
                <Field
                    name="amountOfEachGuarantee"
                    component={Numeric}
                    numberFormat={MONEY_FORMAT}
                    margin={fieldMargin}
                    InputProps={DOLLAR_PREFIX}
                />
                <TotalAmountUpdater />
                <Field
                    name="totalAmountOfBankGuarantees"
                    component={Numeric}
                    numberFormat={MONEY_FORMAT}
                    margin={fieldMargin}
                    InputProps={DOLLAR_PREFIX}
                    disabled
                />
                <Field
                    name="expiryDateRequired"
                    component={DatePicker}
                    margin={fieldMargin}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Field
                            name="enteredBy"
                            component={Text}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            name="enteredDate"
                            component={DatePicker}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>
        </FormContainer>
    );
}

export default BankGuaranteeRequest;