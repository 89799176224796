import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import React, { FunctionComponent, ReactNode } from 'react';
import BasicAlert from './BasicAlert';


interface IBasicErrorProps {
    title?: string;
    description?: ReactNode;
}

const BasicError: FunctionComponent<IBasicErrorProps> = ({
    title,
    description
}) => {
    return (
        <BasicAlert
            icon={faExclamationCircle}
            title={title}
            description={description}
            severity="error"
        />
    )
};

export default BasicError;