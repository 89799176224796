import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { Grid, Hidden, Link, makeStyles, Paper, Typography } from "@material-ui/core";
import { Field, FieldProvider, Text, TextArea, Numeric, DatePicker } from "@ngt/forms";
import React from "react";
import * as Dtos from '../../api/dtos';
import BasicAlert from "../../components/layouts/BasicAlert";
import FormContainer from "../../components/layouts/FormContainer";
import { fieldMargin } from "../../constants/forms";
import { DOLLAR_PREFIX } from "../../constants/inputAdornments";
import { INTEGER_FORMAT, MONEY_FORMAT } from "../../constants/numberFormats";

interface ISalesAdviceProps { }

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0, 2, 2, 2),
        margin: theme.spacing(2, 0, 2, 0)
    },
    subHeading: {
        padding: theme.spacing(0, 0, 0, 0),
        margin: theme.spacing(3, 0, 0, 0)
    },
    text: {
        padding: theme.spacing(2, 0, 0, 0)
    }
}));

const SalesAdvice: React.FunctionComponent<ISalesAdviceProps> = () => {

    const classes = useStyles();

    // const { context: { setValue: setDate } } = useScopedField<Dtos.SalesAdvice['date'], Dtos.IValidationError>('date', {});

    const purchaserNote = (
        <div
            className={classes.text}
        >
            <BasicAlert
                icon={faInfoCircle}
                severity="info"
                description="Please note that if the purchase of your land is in a business name you will need to provide the full business name, ABN, ACN, all directors' details and contact details including email and phone numbers"
            />
        </div>
    );

    return (
        <FormContainer
            formType={ Dtos.SalesAdvice }
        >
            { /* FieldProvider is placed here so form level errors will appear on the form. */}
            <FieldProvider name="id" />

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <Field
                            name="date"
                            component={DatePicker}
                            margin={fieldMargin}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        Sale Details
                    </Typography>
                    <Paper className={classes.paper}>
                        {/*<Field*/}
                        {/*    name="lotNumber"*/}
                        {/*    component={FuzzySelect}*/}
                        {/*    margin={fieldMargin}*/}
                        {/*/>*/}
                        <Field
                            name="lotNumber"
                            component={Numeric}
                            numberFormat={INTEGER_FORMAT}
                            margin={fieldMargin}
                        />
                        <Field
                            name="address"
                            component={TextArea}
                            margin={fieldMargin}
                            rows={5}
                            rowsMax={5}
                        />
                        <Field
                            name="purchasePrice"
                            component={Numeric}
                            numberFormat={MONEY_FORMAT}
                            InputProps={DOLLAR_PREFIX}
                            margin={fieldMargin}
                        />
                        <Field
                            name="deposit"
                            component={Numeric}
                            numberFormat={MONEY_FORMAT}
                            InputProps={DOLLAR_PREFIX}
                            margin={fieldMargin}
                        />
                        <Field
                            name="specialConditions"
                            component={TextArea}
                            margin={fieldMargin}
                            rows={2}
                            rowsMax={20}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        Purchaser's Details
                    </Typography>
                    <Hidden mdUp>
                        {purchaserNote}
                    </Hidden>
                    <Paper className={classes.paper}>
                        <Field
                            name="purchaserName"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="purchaserContact"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="purchaserAddress"
                            component={TextArea}
                            margin={fieldMargin}
                            rows={5}
                            rowsMax={5}
                        />
                        <Field
                            name="purchaserPhone"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="purchaserEmail"
                            component={Text}
                            margin={fieldMargin}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        Purchaser's Solicitor Details
                    </Typography>
                    <Paper className={classes.paper}>
                        <Field
                            name="purchaserSolicitorName"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="purchaserSolicitorContact"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="purchaserSolicitorAddress"
                            component={TextArea}
                            margin={fieldMargin}
                            rows={5}
                            rowsMax={5}
                        />
                        <Field
                            name="purchaserSolicitorPhone"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="purchaserSolicitorEmail"
                            component={Text}
                            margin={fieldMargin}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Hidden smDown>
                {purchaserNote}
            </Hidden>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        Vendor's Details
                    </Typography>
                    <Paper className={classes.paper}>
                        <Field
                            name="vendorName"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="vendorContact"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="vendorAddress"
                            component={TextArea}
                            margin={fieldMargin}
                            rows={2}
                            rowsMax={5}
                        />
                        <Field
                            name="vendorPhone"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="vendorEmail"
                            component={Text}
                            margin={fieldMargin}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        Vendor's Solicitor Details
                    </Typography>
                    <Paper className={classes.paper}>
                        <Field
                            name="vendorSolicitorName"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="vendorSolicitorContact"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="vendorSolicitorAddress"
                            component={TextArea}
                            margin={fieldMargin}
                            rows={2}
                            rowsMax={5}
                        />
                        <Field
                            name="vendorSolicitorPhone"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="vendorSolicitorEmail"
                            component={Text}
                            margin={fieldMargin}
                        />
                    </Paper>
                </Grid>
            </Grid>




            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        Payment Details
                    </Typography>
                    <Paper className={classes.paper}>
                        <Typography
                            variant="body1"
                            className={classes.text}
                        >
                            The Vendor's Solicitor's Trust Account Details are as follows:
                        </Typography>
                        <Field
                            name="bankAccountName"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="bsbNumber"
                            component={Text}
                            margin={fieldMargin}
                        />
                        <Field
                            name="accountNumber"
                            component={Text}
                            margin={fieldMargin}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <div
                className={classes.text}
            >
                <BasicAlert
                    icon={faInfoCircle}
                    severity="info"
                    description="Please ensure that any electronic transfers to the Trust Account state the name McCloy, the lot number and the purchaser's surname as a reference, for example 'McCloy185Smith'."
                />
            </div>

            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                Privacy
            </Typography>
            <Paper className={classes.paper}>
                <Typography
                    variant="body1"
                    className={classes.text}
                >
                    By completing this form you give permission for your details to be added to the McCloy Group database and to receiving future promotional communications from the McCloy Group from which you can choose to unsubscribe at any time by clicking the unsubscribe link at the bottom of the email or text. A copy of the McCloy Group's privacy policy can be found at <Link href="https://mccloygroup.com.au/privacypolicy" target="_blank">mccloygroup.com.au/privacypolicy</Link>.
                </Typography>
            </Paper>
         
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                Execution
            </Typography>
            <Paper className={classes.paper}>
                <Typography
                    variant="body1"
                    className={classes.text}
                >
                    You acknowledge that no binding agreement for the sale of the Property shall arise until a formal exchange of contracts occurs. You confirm that by signing this form you will exchange contracts for sale within 21 days from the date of this form. If exchange of contracts has not occurred within this timeframe the Vendor reserves its rights to re-list the Property and to sell to a third party. You acknowledge that once a contract for sale is issued by the Vendor's lawyers the sum of $500 of the holding deposit becomes non-refundable. Should you not proceed with the purchase you hereby authorise the vendor to retain $500 sum as an administration fee before returning the balance to you.
                </Typography>


                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="nameOfPurchaser"
                            component={Text}
                            margin={fieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="nameOfSalesConsultant"
                            component={Text}
                            margin={fieldMargin}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </FormContainer>
    );
}

export default SalesAdvice;