import { faFileAlt, faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCogs } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Divider, IconButton, makeStyles, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import React, { FunctionComponent, MouseEvent, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthenticationContext from '../../contexts/AuthenticationContext';
import FormsContext from '../../contexts/FormsContext';
import * as Dtos from '../../api/dtos';
import { hasPermission } from '../../utilities/auth';

interface IHeaderProps {
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        width: 40,
        height: 40,
        padding: 4,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        background: theme.palette.common.white
    },
    logoImage: {
        width: '100%'
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    titleLink: {
        marginRight: 'auto',
        textDecoration: 'none'
    },
    title: {
        color: theme.palette.common.white,
        textDecoration: 'none'
    },
}));

const Header: FunctionComponent<IHeaderProps> = () => {
    const forms = useContext(FormsContext);
    // const authentication = useContext(AuthenticationContext);
    // const [ user ] = useAuthenticatedUser();

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);

    const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const authedUser = useContext(AuthenticationContext);

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    {/*<IconButton*/}
                    {/*    edge="start"*/}
                    {/*    className={classes.menuButton}*/}
                    {/*    color="inherit"*/}
                    {/*    aria-label="menu"*/}
                    {/*>*/}
                    {/*    <FontAwesomeIcon fixedWidth icon={faBars} />*/}
                    {/*</IconButton>*/}
                    {
                        forms.branding.logo && (
                            <div className={classes.logo}>
                                <img className={classes.logoImage} alt={forms.branding.name} src={forms.branding.logo} />
                            </div>
                        )
                    }
                    <Link to="/" className={classes.titleLink}>
                        <Typography variant="h6" className={classes.title}>
                            {forms.name}
                        </Typography>
                    </Link>
                    {
                        hasPermission(authedUser?.permissions, Dtos.Permissions.MarkProcessed) &&
                        <IconButton
                            aria-label="admin dashboard"
                            component={Link}
                            to="/admin-dashboard"
                            color="inherit"
                        >
                            <FontAwesomeIcon icon={faCogs} size="lg" />
                        </IconButton>
                    }
                    {
                        hasPermission(authedUser?.permissions, Dtos.Permissions.SendForm) &&
                        <IconButton
                            aria-label="dashboard"
                            component={Link}
                            to="/dashboard"
                            color="inherit"
                        >
                            <FontAwesomeIcon icon={faFileAlt} size="lg" />
                        </IconButton>
                    }
                    {
                        authedUser && (
                            <>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={faUserCircle} size="lg" />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem>{authedUser.displayName ?? authedUser.userName}</MenuItem>
                                    <Divider />
                                    {/*<MenuItem onClick={handleClose}>Profile</MenuItem>*/}
                                    <MenuItem component="a" href="/auth/logout">Logout</MenuItem>
                                </Menu>
                            </>
                        )
                    }
                </Toolbar>
            </AppBar>
        </div>
    )
};

export default Header;