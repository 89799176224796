/* Options:
Date: 2022-08-31 13:46:37
Version: 5.110
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44317

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IDelete
{
}

export enum ProcessState
{
    Unprocessed = 1,
    Processed = 2,
    Archived = 3,
    Draft = 4,
}

export interface IHasFormId
{
    formId?: number;
}

export class QuickLink
{
    public id?: number;
    public url: string;
    public iconUrl: string;
    public order: number;
    public title: string;
    public subtitle: string;
    public color: string;
    public iconColor: string;
    public subtitleColor: string;
    public newTab?: boolean;
    public enabled?: boolean;
    public adGroups: string[];
    // @References("typeof(Forms.ServiceModel.Types.QuickLinkGroup)")
    public quickLinkGroupId?: number;

    public constructor(init?: Partial<QuickLink>) { (Object as any).assign(this, init); }
}

export class QuickLinkGroup
{
    public id?: number;
    public parentId?: number;
    public name: string;
    public order: number;
    public color: string;
    public enabled?: boolean;
    public adGroups: string[];
    public groups: QuickLinkGroup[];
    public links: QuickLink[];

    public constructor(init?: Partial<QuickLinkGroup>) { (Object as any).assign(this, init); }
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IFormDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IFormDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IFormDefinitionGetCollection extends IGet
{
}

export interface IFormGetSingleById extends IGet
{
    id?: number;
    allowCreate?: boolean;
}

export interface IFormGetCollection extends IGet
{
}

export class BankGuaranteeRequest implements IProcessForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public ourCompany: string;
    public nameBankGuaranteesToBeInFavourOf: string;
    public address: string;
    public projectId?: number;
    public projectDetails: string;
    public contractSum?: number;
    public percentOfBankGuarantees?: number;
    public totalAmountOfBankGuarantees?: number;
    public numberOfGuarantees?: number;
    public amountOfEachGuarantee?: number;
    public expiryDateRequired?: string;
    public processState?: ProcessState;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BankGuaranteeRequest>) { (Object as any).assign(this, init); }
}

export class FileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<FileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class File
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public upload: FileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class CreateNewEftRequestItem implements IForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public commitment: string;
    public jobCode: string;
    public costCode: string;
    public amount?: number;
    public gstAmount?: number;
    public gstFree?: number;
    public account: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CreateNewEftRequestItem>) { (Object as any).assign(this, init); }
}

export class CreateNewEftRequest implements IProcessForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public projectId?: number;
    public payableTo: string;
    public description: string;
    public totalAmount?: number;
    public dateRequired?: string;
    public authorisations: string;
    // @Ignore()
    public file: File;

    public items: CreateNewEftRequestItem[];
    public processState?: ProcessState;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CreateNewEftRequest>) { (Object as any).assign(this, init); }
}

export class IntercompanyEftRequestItem implements IForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public commitment: string;
    public jobCode: string;
    public costCode: string;
    public extra: string;
    public amount?: number;
    public gstAmount?: number;
    public gstFree?: number;
    public account: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<IntercompanyEftRequestItem>) { (Object as any).assign(this, init); }
}

export class IntercompanyEftRequest implements IProcessForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public fromCompany: string;
    public bankName: string;
    public toCompany: string;
    public toBankName: string;
    public dateTransferRequired?: string;
    public reason: string;
    public totalAmount?: number;
    public chequeNumber: string;
    public dateChequeDrawn?: string;
    public items: IntercompanyEftRequestItem[];
    public processState?: ProcessState;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<IntercompanyEftRequest>) { (Object as any).assign(this, init); }
}

export class NewCommitmentItem implements IForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public costCode: string;
    public description: string;
    public amount?: number;
    public gstCode?: number;
    public gstAmount?: number;
    public total?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NewCommitmentItem>) { (Object as any).assign(this, init); }
}

export class NewCommitment implements IProcessForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public vendor: string;
    public jobCode: string;
    public description: string;
    public projectManager: string;
    public urgency?: number;
    public items: NewCommitmentItem[];
    public processState?: ProcessState;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NewCommitment>) { (Object as any).assign(this, init); }
}

export class NewJobRequest implements IProcessForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public projectId?: number;
    public jobCode: string;
    public stageNumber: string;
    public numberOfLots?: number;
    public date?: string;
    public salesExpensesLegalFees?: number;
    public salesCommission?: number;
    public internalAgentsCommission?: number;
    public salesExpensesCouncilRates?: number;
    public salesExpensesWaterRates?: number;
    public salesExpensesLandTax?: number;
    public salesExpensesFinanceFees?: number;
    public interestPaid?: number;
    public pexaFee?: number;
    public localGovernmentFees?: number;
    public stateGovernmentFees?: number;
    public federalGovernmentFees?: number;
    public landAndPropertyInformationFees?: number;
    public sectionNinetyFourContributions?: number;
    public stateInfrastructureContributions?: number;
    public stateAuthorityMiscellaneous?: number;
    public sewerAndWater?: number;
    public power?: number;
    public communications?: number;
    public gas?: number;
    public supplyAuthorityMiscellaneous?: number;
    public architect?: number;
    public structuralEngineer?: number;
    public civilEngineer?: number;
    public electricalEngineer?: number;
    public hydraulicEngineer?: number;
    public mechanicalEngineer?: number;
    public geotechnicalEngineer?: number;
    public communicationEngineer?: number;
    public surveyor?: number;
    public townPlanner?: number;
    public trafficEngineer?: number;
    public acousticEngineer?: number;
    public environmentalConsultant?: number;
    public floraAndFaunaConsultant?: number;
    public bushFireConsultant?: number;
    public landscapeArchitect?: number;
    public projectManager?: number;
    public valuerOrMarketAnalyst?: number;
    public strataOrCommunityTitleManager?: number;
    public pcaOrBcaSurveyor?: number;
    public superintendent?: number;
    public heritageConsultant?: number;
    public professionalMiscellaneous?: number;
    public demolition?: number;
    public civilWorks?: number;
    public buildingWorks?: number;
    public roadOrIntersectionConstruction?: number;
    public powerConstruction?: number;
    public waterConstruction?: number;
    public sewerConstruction?: number;
    public communicationsConstruction?: number;
    public otherServices?: number;
    public fencing?: number;
    public clearingOrSlashingOrMulching?: number;
    public streetTreePlanting?: number;
    public landscapingAndEstablishment?: number;
    public openSpaceEmbellishments?: number;
    public constructionContingency?: number;
    public constructionWorksMiscellaneous?: number;
    public marketingConsultant?: number;
    public advertising?: number;
    public signage?: number;
    public website?: number;
    public promotionalMaterial?: number;
    public events?: number;
    public sponsorships?: number;
    public referals?: number;
    public marketingMiscellaneous?: number;
    public salesCentreRAndM?: number;
    public salesCentreConsumerables?: number;
    public estatePresentation?: number;
    public membershipsOrNetworkingEvents?: number;
    public salesMiscellaneous?: number;
    public telephone?: number;
    public utilities?: number;
    public rent?: number;
    public developmentManagementFee?: number;
    public projectManagementLegalFees?: number;
    public projectManagementFinanceFees?: number;
    public printingAndStationary?: number;
    public security?: number;
    public travelAndAccommodation?: number;
    public projectContingency?: number;
    public projectManagementLandTax?: number;
    public projectManagementCouncilRates?: number;
    public projectManagementWaterRates?: number;
    public strataOrCommunityManagementFees?: number;
    public otherRatesAndTaxes?: number;
    public projectManagementMiscellaneous?: number;
    public gasAndElectricityUsage?: number;
    public totalOriginalBudget?: number;
    public costPerLot?: number;
    public approved: string;
    public approvedDate?: string;
    public authorised: string;
    public authorisedDate?: string;
    public finalised: string;
    public finalisedDate?: string;
    public processState?: ProcessState;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NewJobRequest>) { (Object as any).assign(this, init); }
}

export class NewVendorRequest implements IProcessForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public name: string;
    public address: string;
    public contactName: string;
    public phone: string;
    public email: string;
    public abn?: number;
    public abnNotProvided?: boolean;
    // @Ignore()
    public file: File;

    public processState?: ProcessState;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NewVendorRequest>) { (Object as any).assign(this, init); }
}

export class SalesAdvice implements IProcessForm
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public date?: string;
    public lotNumber?: number;
    public address: string;
    public purchasePrice?: number;
    public deposit?: number;
    public specialConditions: string;
    public purchaserName: string;
    public purchaserContact: string;
    public purchaserAddress: string;
    public purchaserPhone: string;
    public purchaserEmail: string;
    public purchaserSolicitorName: string;
    public purchaserSolicitorContact: string;
    public purchaserSolicitorAddress: string;
    public purchaserSolicitorPhone: string;
    public purchaserSolicitorEmail: string;
    public vendorName: string;
    public vendorContact: string;
    public vendorAddress: string;
    public vendorPhone: string;
    public vendorEmail: string;
    public vendorSolicitorName: string;
    public vendorSolicitorContact: string;
    public vendorSolicitorAddress: string;
    public vendorSolicitorPhone: string;
    public vendorSolicitorEmail: string;
    public bankAccountName: string;
    public bsbNumber: string;
    public accountNumber: string;
    public nameOfPurchaser: string;
    public nameOfSalesConsultant: string;
    public processState?: ProcessState;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SalesAdvice>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export interface IProcessForm extends IForm
{
    processState?: ProcessState;
}

export enum FormType
{
    Standard = 1,
}

export enum ValidationErrorType
{
    Warning = 1,
    Ineligible = 2,
    Normal = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel
{
    type?: FormType;
    name: string;
    code: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export class ParentFormSummary
{
    public form: IProcessForm;
    public formDefinition: IFormDefinition;

    public constructor(init?: Partial<ParentFormSummary>) { (Object as any).assign(this, init); }
}

export enum Permissions
{
    DontUseThis = '_DontUseThis_',
    SendForm = 'SendForm',
    ViewCompleted = 'ViewCompleted',
    MarkProcessed = 'MarkProcessed',
    DeleteForm = 'DeleteForm',
    EditQuickLinks = 'EditQuickLinks',
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export enum UserType
{
    Standard = 1,
}

export class LookupItem implements ILookupItem
{
    public id: string;
    public value: string;
    public order?: number;
    public type: string;
    public parent: string;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: FormType;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Ineligible = 2,
    Error = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class FormValidationError implements IFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public formId?: number;
    public formDefinitionId?: number;
    public completed?: boolean;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: FormValidationError[];

    public constructor(init?: Partial<FormValidationResult>) { (Object as any).assign(this, init); }
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface ILookupItem
{
    id: string;
    value: string;
    order?: number;
    type: string;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormValidationError extends IValidationError
{
    property: string;
}

export interface IFormValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    formId?: number;
    formDefinitionId?: number;
    completed?: boolean;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IFormValidationError[];
}

export interface IValidationError
{
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IFile extends IDataModel, IHasFormDefinitionId, IHasFormId, IFileUpload
{
    propertyName: string;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IFileUpload
{
    extension: string;
    fileSize?: number;
    contentType: string;
    exists: boolean;
    image: boolean;
    upload: IFileUploadTemporary;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class GetTestResponse
{
    public test: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTestResponse>) { (Object as any).assign(this, init); }
}

export class GetParentFormsResponse
{
    public parentFormSummaries: ParentFormSummary[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetParentFormsResponse>) { (Object as any).assign(this, init); }
}

export class SetFormProcessStateResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SetFormProcessStateResponse>) { (Object as any).assign(this, init); }
}

export class GetAuthedUserPermissionsResponse
{
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public permissions: Permissions[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAuthedUserPermissionsResponse>) { (Object as any).assign(this, init); }
}

export class SaveQuickLinkGroupsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaveQuickLinkGroupsResponse>) { (Object as any).assign(this, init); }
}

export class GetQuickLinkGroupsResponse
{
    public quickLinkGroups: QuickLinkGroup[];
    public adGroups: string[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetQuickLinkGroupsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public type?: UserType;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public formDefinition: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public formDefinitions: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BankGuaranteeRequestSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bank guarantee request.
    */
    // @DataMember(Order=2)
    public form: BankGuaranteeRequest;

    public constructor(init?: Partial<BankGuaranteeRequestSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BankGuaranteeRequestCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bank guarantee requests.
    */
    // @DataMember(Order=2)
    public forms: BankGuaranteeRequest[];

    public constructor(init?: Partial<BankGuaranteeRequestCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BankGuaranteeRequestValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BankGuaranteeRequestValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CreateNewEftRequestSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved create new eft request.
    */
    // @DataMember(Order=2)
    public form: CreateNewEftRequest;

    public constructor(init?: Partial<CreateNewEftRequestSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CreateNewEftRequestCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved create new eft requests.
    */
    // @DataMember(Order=2)
    public forms: CreateNewEftRequest[];

    public constructor(init?: Partial<CreateNewEftRequestCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CreateNewEftRequestValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<CreateNewEftRequestValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CreateNewEftRequestItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved create new eft request item.
    */
    // @DataMember(Order=2)
    public form: CreateNewEftRequestItem;

    public constructor(init?: Partial<CreateNewEftRequestItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CreateNewEftRequestItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved create new eft request items.
    */
    // @DataMember(Order=2)
    public forms: CreateNewEftRequestItem[];

    public constructor(init?: Partial<CreateNewEftRequestItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CreateNewEftRequestItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<CreateNewEftRequestItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class IntercompanyEftRequestSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved intercompany eft request.
    */
    // @DataMember(Order=2)
    public form: IntercompanyEftRequest;

    public constructor(init?: Partial<IntercompanyEftRequestSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class IntercompanyEftRequestCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved intercompany eft requests.
    */
    // @DataMember(Order=2)
    public forms: IntercompanyEftRequest[];

    public constructor(init?: Partial<IntercompanyEftRequestCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class IntercompanyEftRequestValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<IntercompanyEftRequestValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class IntercompanyEftRequestItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved intercompany eft request item.
    */
    // @DataMember(Order=2)
    public form: IntercompanyEftRequestItem;

    public constructor(init?: Partial<IntercompanyEftRequestItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class IntercompanyEftRequestItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved intercompany eft request items.
    */
    // @DataMember(Order=2)
    public forms: IntercompanyEftRequestItem[];

    public constructor(init?: Partial<IntercompanyEftRequestItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class IntercompanyEftRequestItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<IntercompanyEftRequestItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewCommitmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved new commitment.
    */
    // @DataMember(Order=2)
    public form: NewCommitment;

    public constructor(init?: Partial<NewCommitmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewCommitmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved new commitments.
    */
    // @DataMember(Order=2)
    public forms: NewCommitment[];

    public constructor(init?: Partial<NewCommitmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewCommitmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<NewCommitmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewCommitmentItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved new commitment item.
    */
    // @DataMember(Order=2)
    public form: NewCommitmentItem;

    public constructor(init?: Partial<NewCommitmentItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewCommitmentItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved new commitment items.
    */
    // @DataMember(Order=2)
    public forms: NewCommitmentItem[];

    public constructor(init?: Partial<NewCommitmentItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewCommitmentItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<NewCommitmentItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewJobRequestSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved new job request.
    */
    // @DataMember(Order=2)
    public form: NewJobRequest;

    public constructor(init?: Partial<NewJobRequestSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewJobRequestCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved new job requests.
    */
    // @DataMember(Order=2)
    public forms: NewJobRequest[];

    public constructor(init?: Partial<NewJobRequestCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewJobRequestValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<NewJobRequestValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewVendorRequestSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved new vendor request.
    */
    // @DataMember(Order=2)
    public form: NewVendorRequest;

    public constructor(init?: Partial<NewVendorRequestSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewVendorRequestCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved new vendor requests.
    */
    // @DataMember(Order=2)
    public forms: NewVendorRequest[];

    public constructor(init?: Partial<NewVendorRequestCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewVendorRequestValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<NewVendorRequestValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SalesAdviceSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved sales advice.
    */
    // @DataMember(Order=2)
    public form: SalesAdvice;

    public constructor(init?: Partial<SalesAdviceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SalesAdviceCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved sales advices.
    */
    // @DataMember(Order=2)
    public forms: SalesAdvice[];

    public constructor(init?: Partial<SalesAdviceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SalesAdviceValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SalesAdviceValidationResponse>) { (Object as any).assign(this, init); }
}

// @Route("/pdf-test/{FormDefId}/{Id}")
export class PdfTest
{
    public id?: number;
    public formDefId?: number;
    public mmmPdf?: boolean;

    public constructor(init?: Partial<PdfTest>) { (Object as any).assign(this, init); }
}

// @Route("/whateveriwant")
export class GetTest implements IReturn<GetTestResponse>
{

    public constructor(init?: Partial<GetTest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTestResponse(); }
    public getTypeName() { return 'GetTest'; }
}

// @Route("/GetParentForms")
export class GetParentForms implements IReturn<GetParentFormsResponse>
{

    public constructor(init?: Partial<GetParentForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParentFormsResponse(); }
    public getTypeName() { return 'GetParentForms'; }
}

// @Route("/get-forms-by-auth")
export class GetParentFormsByAuth implements IReturn<GetParentFormsResponse>
{

    public constructor(init?: Partial<GetParentFormsByAuth>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParentFormsResponse(); }
    public getTypeName() { return 'GetParentFormsByAuth'; }
}

export class SetFormProcessState implements IReturn<SetFormProcessStateResponse>, IHasFormId
{
    public formId?: number;
    public formDefinitionId?: number;
    public newProcessState: ProcessState;

    public constructor(init?: Partial<SetFormProcessState>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetFormProcessStateResponse(); }
    public getTypeName() { return 'SetFormProcessState'; }
}

export class GetAuthedUserPermissions implements IReturn<GetAuthedUserPermissionsResponse>
{

    public constructor(init?: Partial<GetAuthedUserPermissions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAuthedUserPermissionsResponse(); }
    public getTypeName() { return 'GetAuthedUserPermissions'; }
}

// @Route("/SaveQuickLinkGroups")
export class SaveQuickLinkGroups implements IReturn<SaveQuickLinkGroupsResponse>
{
    public data: QuickLinkGroup[];

    public constructor(init?: Partial<SaveQuickLinkGroups>) { (Object as any).assign(this, init); }
    public createResponse() { return new SaveQuickLinkGroupsResponse(); }
    public getTypeName() { return 'SaveQuickLinkGroups'; }
}

// @Route("/GetQuickLinkGroups")
export class GetQuickLinkGroups implements IReturn<GetQuickLinkGroupsResponse>
{
    public edit?: boolean;

    public constructor(init?: Partial<GetQuickLinkGroups>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetQuickLinkGroupsResponse(); }
    public getTypeName() { return 'GetQuickLinkGroups'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/forms/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'PostUploadFile'; }
}

// @Route("/forms/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'GetUploadInfo'; }
}

// @Route("/forms/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetUpload'; }
}

// @Route("/forms/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadUpload'; }
}

// @Route("/forms/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'DeleteUpload'; }
}

// @Route("/forms/user")
export class GetUser implements IReturn<GetUserResponse>, IGet
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserResponse(); }
    public getTypeName() { return 'GetUser'; }
}

/**
* Lookup
*/
// @Route("/forms/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupCollectionResponse(); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
}

// @Route("/forms/form-names")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFormMetadataResponse(); }
    public getTypeName() { return 'GetFormMetadata'; }
}

/**
* Form Definition
*/
// @Route("/forms/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
}

/**
* Form Definition
*/
// @Route("/forms/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
}

/**
* Form Definition
*/
// @Route("/forms/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IFormDefinitionGetCollection
{

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
}

/**
* Form - Bank Guarantee Request
*/
// @Route("/forms/form/bank-guarantee-request/single/id/{Id}", "GET")
// @Api(Description="Form - Bank Guarantee Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=500)
// @DataContract
export class BankGuaranteeRequestGetSingleById implements IReturn<BankGuaranteeRequestSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the bank guarantee request to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new bank guarantee request form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<BankGuaranteeRequestGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BankGuaranteeRequestSingleResponse(); }
    public getTypeName() { return 'BankGuaranteeRequestGetSingleById'; }
}

/**
* Form - Bank Guarantee Request
*/
// @Route("/forms/form/bank-guarantee-request/collection", "GET")
// @Api(Description="Form - Bank Guarantee Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestCollectionResponse)", StatusCode=500)
// @DataContract
export class BankGuaranteeRequestGetCollection implements IReturn<BankGuaranteeRequestCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BankGuaranteeRequestGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new BankGuaranteeRequestCollectionResponse(); }
    public getTypeName() { return 'BankGuaranteeRequestGetCollection'; }
}

/**
* Form - Bank Guarantee Request
*/
// @Route("/forms/form/bank-guarantee-request/save", "POST")
// @Api(Description="Form - Bank Guarantee Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestSingleResponse)", StatusCode=500)
// @DataContract
export class BankGuaranteeRequestPostSave implements IReturn<BankGuaranteeRequestSingleResponse>
{
    /**
    * The bank guarantee request to save.
    */
    // @DataMember(Order=1)
    public form: BankGuaranteeRequest;

    public constructor(init?: Partial<BankGuaranteeRequestPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new BankGuaranteeRequestSingleResponse(); }
    public getTypeName() { return 'BankGuaranteeRequestPostSave'; }
}

/**
* Form - Bank Guarantee Request
*/
// @Route("/forms/form/bank-guarantee-request/validate", "POST")
// @Api(Description="Form - Bank Guarantee Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BankGuaranteeRequestValidationResponse)", StatusCode=500)
// @DataContract
export class BankGuaranteeRequestPostValidate implements IReturn<BankGuaranteeRequestValidationResponse>
{
    /**
    * The bank guarantee request to validate.
    */
    // @DataMember(Order=1)
    public form: BankGuaranteeRequest;

    public constructor(init?: Partial<BankGuaranteeRequestPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BankGuaranteeRequestValidationResponse(); }
    public getTypeName() { return 'BankGuaranteeRequestPostValidate'; }
}

/**
* Form - Create New Eft Request
*/
// @Route("/forms/form/create-new-eft-request/single/id/{Id}", "GET")
// @Api(Description="Form - Create New Eft Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=500)
// @DataContract
export class CreateNewEftRequestGetSingleById implements IReturn<CreateNewEftRequestSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the create new eft request to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new create new eft request form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<CreateNewEftRequestGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNewEftRequestSingleResponse(); }
    public getTypeName() { return 'CreateNewEftRequestGetSingleById'; }
}

/**
* Form - Create New Eft Request
*/
// @Route("/forms/form/create-new-eft-request/collection", "GET")
// @Api(Description="Form - Create New Eft Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestCollectionResponse)", StatusCode=500)
// @DataContract
export class CreateNewEftRequestGetCollection implements IReturn<CreateNewEftRequestCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<CreateNewEftRequestGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNewEftRequestCollectionResponse(); }
    public getTypeName() { return 'CreateNewEftRequestGetCollection'; }
}

/**
* Form - Create New Eft Request
*/
// @Route("/forms/form/create-new-eft-request/save", "POST")
// @Api(Description="Form - Create New Eft Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestSingleResponse)", StatusCode=500)
// @DataContract
export class CreateNewEftRequestPostSave implements IReturn<CreateNewEftRequestSingleResponse>
{
    /**
    * The create new eft request to save.
    */
    // @DataMember(Order=1)
    public form: CreateNewEftRequest;

    public constructor(init?: Partial<CreateNewEftRequestPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNewEftRequestSingleResponse(); }
    public getTypeName() { return 'CreateNewEftRequestPostSave'; }
}

/**
* Form - Create New Eft Request
*/
// @Route("/forms/form/create-new-eft-request/validate", "POST")
// @Api(Description="Form - Create New Eft Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestValidationResponse)", StatusCode=500)
// @DataContract
export class CreateNewEftRequestPostValidate implements IReturn<CreateNewEftRequestValidationResponse>
{
    /**
    * The create new eft request to validate.
    */
    // @DataMember(Order=1)
    public form: CreateNewEftRequest;

    public constructor(init?: Partial<CreateNewEftRequestPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNewEftRequestValidationResponse(); }
    public getTypeName() { return 'CreateNewEftRequestPostValidate'; }
}

/**
* Form - Create New Eft Request Item
*/
// @Route("/forms/form/create-new-eft-request-item/single/id/{Id}", "GET")
// @Api(Description="Form - Create New Eft Request Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=500)
// @DataContract
export class CreateNewEftRequestItemGetSingleById implements IReturn<CreateNewEftRequestItemSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the create new eft request item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new create new eft request item form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<CreateNewEftRequestItemGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNewEftRequestItemSingleResponse(); }
    public getTypeName() { return 'CreateNewEftRequestItemGetSingleById'; }
}

/**
* Form - Create New Eft Request Item
*/
// @Route("/forms/form/create-new-eft-request-item/collection", "GET")
// @Api(Description="Form - Create New Eft Request Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemCollectionResponse)", StatusCode=500)
// @DataContract
export class CreateNewEftRequestItemGetCollection implements IReturn<CreateNewEftRequestItemCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<CreateNewEftRequestItemGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNewEftRequestItemCollectionResponse(); }
    public getTypeName() { return 'CreateNewEftRequestItemGetCollection'; }
}

/**
* Form - Create New Eft Request Item
*/
// @Route("/forms/form/create-new-eft-request-item/save", "POST")
// @Api(Description="Form - Create New Eft Request Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemSingleResponse)", StatusCode=500)
// @DataContract
export class CreateNewEftRequestItemPostSave implements IReturn<CreateNewEftRequestItemSingleResponse>
{
    /**
    * The create new eft request item to save.
    */
    // @DataMember(Order=1)
    public form: CreateNewEftRequestItem;

    public constructor(init?: Partial<CreateNewEftRequestItemPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNewEftRequestItemSingleResponse(); }
    public getTypeName() { return 'CreateNewEftRequestItemPostSave'; }
}

/**
* Form - Create New Eft Request Item
*/
// @Route("/forms/form/create-new-eft-request-item/validate", "POST")
// @Api(Description="Form - Create New Eft Request Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CreateNewEftRequestItemValidationResponse)", StatusCode=500)
// @DataContract
export class CreateNewEftRequestItemPostValidate implements IReturn<CreateNewEftRequestItemValidationResponse>
{
    /**
    * The create new eft request item to validate.
    */
    // @DataMember(Order=1)
    public form: CreateNewEftRequestItem;

    public constructor(init?: Partial<CreateNewEftRequestItemPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateNewEftRequestItemValidationResponse(); }
    public getTypeName() { return 'CreateNewEftRequestItemPostValidate'; }
}

/**
* Form - Intercompany Eft Request
*/
// @Route("/forms/form/intercompany-eft-request/single/id/{Id}", "GET")
// @Api(Description="Form - Intercompany Eft Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=500)
// @DataContract
export class IntercompanyEftRequestGetSingleById implements IReturn<IntercompanyEftRequestSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the intercompany eft request to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new intercompany eft request form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<IntercompanyEftRequestGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new IntercompanyEftRequestSingleResponse(); }
    public getTypeName() { return 'IntercompanyEftRequestGetSingleById'; }
}

/**
* Form - Intercompany Eft Request
*/
// @Route("/forms/form/intercompany-eft-request/collection", "GET")
// @Api(Description="Form - Intercompany Eft Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestCollectionResponse)", StatusCode=500)
// @DataContract
export class IntercompanyEftRequestGetCollection implements IReturn<IntercompanyEftRequestCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<IntercompanyEftRequestGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new IntercompanyEftRequestCollectionResponse(); }
    public getTypeName() { return 'IntercompanyEftRequestGetCollection'; }
}

/**
* Form - Intercompany Eft Request
*/
// @Route("/forms/form/intercompany-eft-request/save", "POST")
// @Api(Description="Form - Intercompany Eft Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestSingleResponse)", StatusCode=500)
// @DataContract
export class IntercompanyEftRequestPostSave implements IReturn<IntercompanyEftRequestSingleResponse>
{
    /**
    * The intercompany eft request to save.
    */
    // @DataMember(Order=1)
    public form: IntercompanyEftRequest;

    public constructor(init?: Partial<IntercompanyEftRequestPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new IntercompanyEftRequestSingleResponse(); }
    public getTypeName() { return 'IntercompanyEftRequestPostSave'; }
}

/**
* Form - Intercompany Eft Request
*/
// @Route("/forms/form/intercompany-eft-request/validate", "POST")
// @Api(Description="Form - Intercompany Eft Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestValidationResponse)", StatusCode=500)
// @DataContract
export class IntercompanyEftRequestPostValidate implements IReturn<IntercompanyEftRequestValidationResponse>
{
    /**
    * The intercompany eft request to validate.
    */
    // @DataMember(Order=1)
    public form: IntercompanyEftRequest;

    public constructor(init?: Partial<IntercompanyEftRequestPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new IntercompanyEftRequestValidationResponse(); }
    public getTypeName() { return 'IntercompanyEftRequestPostValidate'; }
}

/**
* Form - Intercompany Eft Request Item
*/
// @Route("/forms/form/intercompany-eft-request-item/single/id/{Id}", "GET")
// @Api(Description="Form - Intercompany Eft Request Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=500)
// @DataContract
export class IntercompanyEftRequestItemGetSingleById implements IReturn<IntercompanyEftRequestItemSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the intercompany eft request item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new intercompany eft request item form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<IntercompanyEftRequestItemGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new IntercompanyEftRequestItemSingleResponse(); }
    public getTypeName() { return 'IntercompanyEftRequestItemGetSingleById'; }
}

/**
* Form - Intercompany Eft Request Item
*/
// @Route("/forms/form/intercompany-eft-request-item/collection", "GET")
// @Api(Description="Form - Intercompany Eft Request Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemCollectionResponse)", StatusCode=500)
// @DataContract
export class IntercompanyEftRequestItemGetCollection implements IReturn<IntercompanyEftRequestItemCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<IntercompanyEftRequestItemGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new IntercompanyEftRequestItemCollectionResponse(); }
    public getTypeName() { return 'IntercompanyEftRequestItemGetCollection'; }
}

/**
* Form - Intercompany Eft Request Item
*/
// @Route("/forms/form/intercompany-eft-request-item/save", "POST")
// @Api(Description="Form - Intercompany Eft Request Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemSingleResponse)", StatusCode=500)
// @DataContract
export class IntercompanyEftRequestItemPostSave implements IReturn<IntercompanyEftRequestItemSingleResponse>
{
    /**
    * The intercompany eft request item to save.
    */
    // @DataMember(Order=1)
    public form: IntercompanyEftRequestItem;

    public constructor(init?: Partial<IntercompanyEftRequestItemPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new IntercompanyEftRequestItemSingleResponse(); }
    public getTypeName() { return 'IntercompanyEftRequestItemPostSave'; }
}

/**
* Form - Intercompany Eft Request Item
*/
// @Route("/forms/form/intercompany-eft-request-item/validate", "POST")
// @Api(Description="Form - Intercompany Eft Request Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.IntercompanyEftRequestItemValidationResponse)", StatusCode=500)
// @DataContract
export class IntercompanyEftRequestItemPostValidate implements IReturn<IntercompanyEftRequestItemValidationResponse>
{
    /**
    * The intercompany eft request item to validate.
    */
    // @DataMember(Order=1)
    public form: IntercompanyEftRequestItem;

    public constructor(init?: Partial<IntercompanyEftRequestItemPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new IntercompanyEftRequestItemValidationResponse(); }
    public getTypeName() { return 'IntercompanyEftRequestItemPostValidate'; }
}

/**
* Form - New Commitment
*/
// @Route("/forms/form/new-commitment/single/id/{Id}", "GET")
// @Api(Description="Form - New Commitment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=500)
// @DataContract
export class NewCommitmentGetSingleById implements IReturn<NewCommitmentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the new commitment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new new commitment form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<NewCommitmentGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewCommitmentSingleResponse(); }
    public getTypeName() { return 'NewCommitmentGetSingleById'; }
}

/**
* Form - New Commitment
*/
// @Route("/forms/form/new-commitment/collection", "GET")
// @Api(Description="Form - New Commitment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewCommitmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewCommitmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewCommitmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewCommitmentCollectionResponse)", StatusCode=500)
// @DataContract
export class NewCommitmentGetCollection implements IReturn<NewCommitmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<NewCommitmentGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewCommitmentCollectionResponse(); }
    public getTypeName() { return 'NewCommitmentGetCollection'; }
}

/**
* Form - New Commitment
*/
// @Route("/forms/form/new-commitment/save", "POST")
// @Api(Description="Form - New Commitment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewCommitmentSingleResponse)", StatusCode=500)
// @DataContract
export class NewCommitmentPostSave implements IReturn<NewCommitmentSingleResponse>
{
    /**
    * The new commitment to save.
    */
    // @DataMember(Order=1)
    public form: NewCommitment;

    public constructor(init?: Partial<NewCommitmentPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewCommitmentSingleResponse(); }
    public getTypeName() { return 'NewCommitmentPostSave'; }
}

/**
* Form - New Commitment
*/
// @Route("/forms/form/new-commitment/validate", "POST")
// @Api(Description="Form - New Commitment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewCommitmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewCommitmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewCommitmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewCommitmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewCommitmentValidationResponse)", StatusCode=500)
// @DataContract
export class NewCommitmentPostValidate implements IReturn<NewCommitmentValidationResponse>
{
    /**
    * The new commitment to validate.
    */
    // @DataMember(Order=1)
    public form: NewCommitment;

    public constructor(init?: Partial<NewCommitmentPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewCommitmentValidationResponse(); }
    public getTypeName() { return 'NewCommitmentPostValidate'; }
}

/**
* Form - New Commitment Item
*/
// @Route("/forms/form/new-commitment-item/single/id/{Id}", "GET")
// @Api(Description="Form - New Commitment Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=500)
// @DataContract
export class NewCommitmentItemGetSingleById implements IReturn<NewCommitmentItemSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the new commitment item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new new commitment item form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<NewCommitmentItemGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewCommitmentItemSingleResponse(); }
    public getTypeName() { return 'NewCommitmentItemGetSingleById'; }
}

/**
* Form - New Commitment Item
*/
// @Route("/forms/form/new-commitment-item/collection", "GET")
// @Api(Description="Form - New Commitment Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewCommitmentItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewCommitmentItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewCommitmentItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewCommitmentItemCollectionResponse)", StatusCode=500)
// @DataContract
export class NewCommitmentItemGetCollection implements IReturn<NewCommitmentItemCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<NewCommitmentItemGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewCommitmentItemCollectionResponse(); }
    public getTypeName() { return 'NewCommitmentItemGetCollection'; }
}

/**
* Form - New Commitment Item
*/
// @Route("/forms/form/new-commitment-item/save", "POST")
// @Api(Description="Form - New Commitment Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewCommitmentItemSingleResponse)", StatusCode=500)
// @DataContract
export class NewCommitmentItemPostSave implements IReturn<NewCommitmentItemSingleResponse>
{
    /**
    * The new commitment item to save.
    */
    // @DataMember(Order=1)
    public form: NewCommitmentItem;

    public constructor(init?: Partial<NewCommitmentItemPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewCommitmentItemSingleResponse(); }
    public getTypeName() { return 'NewCommitmentItemPostSave'; }
}

/**
* Form - New Commitment Item
*/
// @Route("/forms/form/new-commitment-item/validate", "POST")
// @Api(Description="Form - New Commitment Item")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewCommitmentItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewCommitmentItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewCommitmentItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewCommitmentItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewCommitmentItemValidationResponse)", StatusCode=500)
// @DataContract
export class NewCommitmentItemPostValidate implements IReturn<NewCommitmentItemValidationResponse>
{
    /**
    * The new commitment item to validate.
    */
    // @DataMember(Order=1)
    public form: NewCommitmentItem;

    public constructor(init?: Partial<NewCommitmentItemPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewCommitmentItemValidationResponse(); }
    public getTypeName() { return 'NewCommitmentItemPostValidate'; }
}

/**
* Form - New Job Request
*/
// @Route("/forms/form/new-job-request/single/id/{Id}", "GET")
// @Api(Description="Form - New Job Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=500)
// @DataContract
export class NewJobRequestGetSingleById implements IReturn<NewJobRequestSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the new job request to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new new job request form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<NewJobRequestGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewJobRequestSingleResponse(); }
    public getTypeName() { return 'NewJobRequestGetSingleById'; }
}

/**
* Form - New Job Request
*/
// @Route("/forms/form/new-job-request/collection", "GET")
// @Api(Description="Form - New Job Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewJobRequestCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewJobRequestCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewJobRequestCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewJobRequestCollectionResponse)", StatusCode=500)
// @DataContract
export class NewJobRequestGetCollection implements IReturn<NewJobRequestCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<NewJobRequestGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewJobRequestCollectionResponse(); }
    public getTypeName() { return 'NewJobRequestGetCollection'; }
}

/**
* Form - New Job Request
*/
// @Route("/forms/form/new-job-request/save", "POST")
// @Api(Description="Form - New Job Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewJobRequestSingleResponse)", StatusCode=500)
// @DataContract
export class NewJobRequestPostSave implements IReturn<NewJobRequestSingleResponse>
{
    /**
    * The new job request to save.
    */
    // @DataMember(Order=1)
    public form: NewJobRequest;

    public constructor(init?: Partial<NewJobRequestPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewJobRequestSingleResponse(); }
    public getTypeName() { return 'NewJobRequestPostSave'; }
}

/**
* Form - New Job Request
*/
// @Route("/forms/form/new-job-request/validate", "POST")
// @Api(Description="Form - New Job Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewJobRequestValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewJobRequestValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewJobRequestValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewJobRequestValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewJobRequestValidationResponse)", StatusCode=500)
// @DataContract
export class NewJobRequestPostValidate implements IReturn<NewJobRequestValidationResponse>
{
    /**
    * The new job request to validate.
    */
    // @DataMember(Order=1)
    public form: NewJobRequest;

    public constructor(init?: Partial<NewJobRequestPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewJobRequestValidationResponse(); }
    public getTypeName() { return 'NewJobRequestPostValidate'; }
}

/**
* Form - New Vendor Request
*/
// @Route("/forms/form/new-vendor-request/single/id/{Id}", "GET")
// @Api(Description="Form - New Vendor Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=500)
// @DataContract
export class NewVendorRequestGetSingleById implements IReturn<NewVendorRequestSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the new vendor request to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new new vendor request form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<NewVendorRequestGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewVendorRequestSingleResponse(); }
    public getTypeName() { return 'NewVendorRequestGetSingleById'; }
}

/**
* Form - New Vendor Request
*/
// @Route("/forms/form/new-vendor-request/collection", "GET")
// @Api(Description="Form - New Vendor Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewVendorRequestCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewVendorRequestCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewVendorRequestCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewVendorRequestCollectionResponse)", StatusCode=500)
// @DataContract
export class NewVendorRequestGetCollection implements IReturn<NewVendorRequestCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<NewVendorRequestGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewVendorRequestCollectionResponse(); }
    public getTypeName() { return 'NewVendorRequestGetCollection'; }
}

/**
* Form - New Vendor Request
*/
// @Route("/forms/form/new-vendor-request/save", "POST")
// @Api(Description="Form - New Vendor Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewVendorRequestSingleResponse)", StatusCode=500)
// @DataContract
export class NewVendorRequestPostSave implements IReturn<NewVendorRequestSingleResponse>
{
    /**
    * The new vendor request to save.
    */
    // @DataMember(Order=1)
    public form: NewVendorRequest;

    public constructor(init?: Partial<NewVendorRequestPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewVendorRequestSingleResponse(); }
    public getTypeName() { return 'NewVendorRequestPostSave'; }
}

/**
* Form - New Vendor Request
*/
// @Route("/forms/form/new-vendor-request/validate", "POST")
// @Api(Description="Form - New Vendor Request")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewVendorRequestValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewVendorRequestValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewVendorRequestValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewVendorRequestValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewVendorRequestValidationResponse)", StatusCode=500)
// @DataContract
export class NewVendorRequestPostValidate implements IReturn<NewVendorRequestValidationResponse>
{
    /**
    * The new vendor request to validate.
    */
    // @DataMember(Order=1)
    public form: NewVendorRequest;

    public constructor(init?: Partial<NewVendorRequestPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewVendorRequestValidationResponse(); }
    public getTypeName() { return 'NewVendorRequestPostValidate'; }
}

/**
* Form - Sales Advice
*/
// @Route("/forms/form/sales-advice/single/id/{Id}", "GET")
// @Api(Description="Form - Sales Advice")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=500)
// @DataContract
export class SalesAdviceGetSingleById implements IReturn<SalesAdviceSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the sales advice to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * Allow for the creation of a new sales advice form.
    */
    // @DataMember(Order=2)
    public allowCreate?: boolean;

    public constructor(init?: Partial<SalesAdviceGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SalesAdviceSingleResponse(); }
    public getTypeName() { return 'SalesAdviceGetSingleById'; }
}

/**
* Form - Sales Advice
*/
// @Route("/forms/form/sales-advice/collection", "GET")
// @Api(Description="Form - Sales Advice")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SalesAdviceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SalesAdviceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SalesAdviceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SalesAdviceCollectionResponse)", StatusCode=500)
// @DataContract
export class SalesAdviceGetCollection implements IReturn<SalesAdviceCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SalesAdviceGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SalesAdviceCollectionResponse(); }
    public getTypeName() { return 'SalesAdviceGetCollection'; }
}

/**
* Form - Sales Advice
*/
// @Route("/forms/form/sales-advice/save", "POST")
// @Api(Description="Form - Sales Advice")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SalesAdviceSingleResponse)", StatusCode=500)
// @DataContract
export class SalesAdvicePostSave implements IReturn<SalesAdviceSingleResponse>
{
    /**
    * The sales advice to save.
    */
    // @DataMember(Order=1)
    public form: SalesAdvice;

    public constructor(init?: Partial<SalesAdvicePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SalesAdviceSingleResponse(); }
    public getTypeName() { return 'SalesAdvicePostSave'; }
}

/**
* Form - Sales Advice
*/
// @Route("/forms/form/sales-advice/validate", "POST")
// @Api(Description="Form - Sales Advice")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SalesAdviceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SalesAdviceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SalesAdviceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SalesAdviceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SalesAdviceValidationResponse)", StatusCode=500)
// @DataContract
export class SalesAdvicePostValidate implements IReturn<SalesAdviceValidationResponse>
{
    /**
    * The sales advice to validate.
    */
    // @DataMember(Order=1)
    public form: SalesAdvice;

    public constructor(init?: Partial<SalesAdvicePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SalesAdviceValidationResponse(); }
    public getTypeName() { return 'SalesAdvicePostValidate'; }
}

// @Route("/forms/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFile'; }
}

// @Route("/forms/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadFile'; }
}

