import { Button, Container, ContainerProps, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext } from "react";
import { IForm, ProcessState, IFormDefinition } from '../../api/dtos';
import {Form, FormByIdContext, FormDefinitionContext, IDtoClass, SubmitButton} from '@ngt/forms'
import { Link, useHistory } from "react-router-dom";
import useSetFormProcessState from "../../hooks/useSetFormProcessState";
import * as Dtos from "../../api/dtos";
import FormsContext from '../../contexts/FormsContext';
import AuthenticationContext from "../../contexts/AuthenticationContext";
import { hasPermission } from '../../utilities/auth';
import { IFormAllowSubmit, useFormActions } from '@ngt/forms-core';
import FormsContextContainer from '../../contexts/FormsContextContainer';
import hasOwnProperty from "../../utilities/hasOwnProperty";

interface IFormContainerProps {
    header?: string;
    maxWidth?: ContainerProps["maxWidth"];
    formType: IDtoClass<IForm>;
    onSubmit?: () => void;
}

const useStyles = makeStyles(theme => ({
    heading: {
        padding: theme.spacing(3, 3, 1, 3)
    },
    paper: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3)
    },
    buttonsContainer: {
        display: 'flex',
        marginTop: theme.spacing(2),
        '& :nth-child(3)': {
            marginLeft: theme.spacing(2)
        }
    },
    cancelButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submitButtonContainer: {
        marginRight: 0
    },
    processedButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto'
    },
    buttonGrid:{
        justifyContent: 'end'
    }
}));

const FormContainer: FC<IFormContainerProps> = ({
    header,
    formType,
    maxWidth,
    children,
}) => {
    
    const formsContext = useContext(FormsContext);
    const { formDefinition } = useContext(FormDefinitionContext);
    const { form } = useContext(FormByIdContext);

    const history = useHistory();

    const canEditForm = form?.id && ((hasOwnProperty(form, 'processState') && form.processState !== ProcessState.Draft) || !hasOwnProperty(form, 'processState')) ? false : true;

    const afterFormSaveAndReturn = React.useCallback((formValue?: IForm | null) => {
        history.push('/');
    }, [history])

    const allowSubmit: IFormAllowSubmit = React.useCallback( async ({
        values: {
            processState
        },
        errors
    }) => {
        if(processState == ProcessState.Draft){ // eslint-disable-line eqeqeq
            return true;
        }

        const errorPaths = Object.keys(errors);

        return !(errorPaths.some(path => errors[path]?.length > 0));

    }, [])

    return (
        <Container maxWidth={maxWidth}>
        {
            (header || formDefinition?.name) && <Typography
                variant="h1"
            >
                {(header ?? formDefinition?.name)}
            </Typography>
        }
            <Form
                formType={formType}
                validateOn={2}
                canEdit={canEditForm}
                afterFormSaveAndReturn={afterFormSaveAndReturn}
                allowSubmit={allowSubmit}
            >
                <FormContainerInner 
                    header={header}
                    formType={formType}
                    maxWidth={maxWidth}
                    form={form}
                    formsContext={formsContext}
                    formDefinition={formDefinition}
                    canEditForm={canEditForm}
                >
                    {children}
                </FormContainerInner>
            </Form>
        </Container>
    )

}

interface IFormContainerInnerProps {
    header?: string;
    maxWidth?: ContainerProps["maxWidth"];
    formType: IDtoClass<IForm>;
    form: IForm | null;
    formsContext: FormsContextContainer;
    formDefinition: IFormDefinition | null;
    canEditForm: boolean;
}

const FormContainerInner: React.FunctionComponent<IFormContainerInnerProps> = ({
    children,
    form,
    formsContext,
    formDefinition,
    canEditForm
}) => {
    const classes = useStyles();

    const history = useHistory();
    const {setFieldValue} = useFormActions();

    const routeOnProcessSuccess = useCallback(() => {
        history.push(formsContext.uris.adminDashboard)
    }, [history, formsContext]);
    
    const { setFormProcessState } = useSetFormProcessState(routeOnProcessSuccess);

    const onProcessedClick = useCallback(() => {
        setFormProcessState(form?.id, formDefinition?.id, Dtos.ProcessState.Processed);
    }, [form, formDefinition, setFormProcessState]);

    const authedUser = useContext(AuthenticationContext);

    const handleSave = useCallback(() => {
        setFieldValue?.("processState", ProcessState.Draft)
    }, [setFieldValue])

    const handleSubmit = useCallback(() => {
        setFieldValue?.("processState", ProcessState.Unprocessed)
    }, [setFieldValue])

    return (
        <>
            {children}
            <div className={classes.buttonsContainer}>
                <Button color="secondary" component={Link} to={'/'} className={classes.cancelButton}>
                    Cancel
                </Button>
                {canEditForm ?
                    <Grid 
                        container
                        spacing={3}
                        className={classes.buttonGrid}
                    >
                        <Grid 
                            item
                        >
                            <SubmitButton
                                variant="outlined"
                                color="primary"
                                containerClass={classes.submitButtonContainer}
                                onClick={handleSave}
                            >
                                Save &amp; Exit
                            </SubmitButton>
                        </Grid>
                        <Grid 
                            item
                        >
                            <SubmitButton
                                variant="contained"
                                color="primary"
                                containerClass={classes.submitButtonContainer}
                                onClick={handleSubmit}
                            >
                                Submit
                            </SubmitButton>
                        </Grid>
                    </Grid> :
                    hasPermission(authedUser?.permissions, Dtos.Permissions.MarkProcessed) ?
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onProcessedClick}
                            className={classes.processedButton}
                        >
                            Processed
                        </Button> :
                        <></>
                }
            </div>
        </>
    )
}

export default FormContainer;