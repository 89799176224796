import MuiAutocomplete, { AutocompleteProps, AutocompleteRenderGroupParams } from "@material-ui/lab/Autocomplete";
import React from "react";
import { TextField as MuiTextField, ListSubheader, useTheme, useMediaQuery, FormControlProps } from "@material-ui/core";
import {IInputRenderProps, useFormErrorBoundary} from "@ngt/forms-core";
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import {FormLookupContext, ILookupItem, IValidationError, FormOptionsContext} from '@ngt/forms';

export type OmitFieldProps<T> = Omit<T, 'onChange' | 'freeSolo' | 'multiple' | 'disableClearable' | 'autoComplete' | 'margin'>; 

type FuzzySelectFieldsProps =
    Partial<OmitFieldProps<AutocompleteProps<ILookupItem | undefined, false, false, false>>> &
    IInputRenderProps<string | undefined, IValidationError>

export interface IFuzzySelectProps extends FuzzySelectFieldsProps {
    onChange?: (value: ILookupItem | undefined) => void;
    label: string;
    autoComplete: string;
    margin?: FormControlProps['margin'];
}


const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];

const FuzzySelect: React.FunctionComponent<IFuzzySelectProps> = ({
    onChange,
    label,
    inputRender: {
        state: { name, value, errors, ...restInputState },
        actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    fullWidth,
    autoComplete,
    margin,
    disabled,
    //children,
    ...rest
}) => {

    const lookup = React.useContext(FormLookupContext);

    // Manually provide autocompletes selected Option in order to fully connect
    // the component to the controlled form state.
    const selectedOption = React.useMemo(() => {
        return lookup?.items?.find(item => item.id === value) ?? null;
    }, [value, lookup]);

    // Manually control autocompletes Text component value so that it always reflects 
    // the form state value.
    const [textInputValue, setTextInputValue] = React.useState<string>('');
    React.useEffect(() => {
        setTextInputValue(selectedOption?.value ?? '');
    }, [selectedOption]);

    // Call Field onInputChange for updating form state, and provided onChange function.
    const onChangeCombined =
        React.useCallback((
            event: React.ChangeEvent<{}>,
            newValue: ILookupItem | undefined
        ) => {
            if (onChange) {
                onChange(newValue);
            }

            onInputChange(newValue?.id);

        }, [onChange, onInputChange]);

    const formErrors = useFormErrorBoundary<any, IValidationError>({
        subscription: { touched: true },
        includeError: (name, error, { touched }) => touched[name]
    });
    
    const formOptions = React.useContext(FormOptionsContext);
    const inputDisabled = disabled || formOptions.readOnly;

    return <MuiAutocomplete
        {...rest as any}

        selectOnFocus
        disableListWrap
        // autoSelect

        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
        options={lookup?.items ?? []}
        getOptionLabel={(option: ILookupItem) => {
            return option.value ?? '';
        }}

        // Manual control of autocomplete and text component values.
        value={selectedOption}
        inputValue={textInputValue}
        onInputChange={(event, newInputValue) => {
            setTextInputValue(newInputValue);
        }}

        onChange={onChangeCombined}
        onBlur={onBlur}
        onFocus={onFocus}

        renderGroup={renderGroup}
        renderInput={(params) => {

            return <>
                <MuiTextField
                    {...params}
                    fullWidth={fullWidth === undefined ? true : fullWidth}
                    label={label}
                    autoComplete={autoComplete}

                    margin={margin}

                    error={!!formErrors && formErrors.length > 0}
                    helperText={formErrors && formErrors.length > 0 ? errors[0].message : undefined}
                />
            </>
        }}

        disabled={inputDisabled}
    />
}

export default FuzzySelect;