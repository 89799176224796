
import FormsContext from "../contexts/FormsContext";
import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { GetQuickLinkGroupsResponse, GetQuickLinkGroups } from '../api/dtos';
import { BoundMutator } from './useAuthentication';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: any, edit?:boolean) => {
    const response = await forms.client.get(new GetQuickLinkGroups({edit}));

    return response;
}

const useQuickLinkGroups = (edit?: boolean, configuration?: SWRConfiguration<GetQuickLinkGroupsResponse, GetQuickLinkGroupsResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<GetQuickLinkGroupsResponse, GetQuickLinkGroupsResponse>([`quick-links`, forms, edit], fetcher, configuration);

    const boundMutate: BoundMutator<GetQuickLinkGroupsResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate([`quick-links`, forms, edit], newData, shouldRevalidate);
    }, [forms, edit]);

    const result = useMemo(() => {
        return {
            data: data?.quickLinkGroups,
            adGroups: data?.adGroups,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default useQuickLinkGroups;