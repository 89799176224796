import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fad, IconDefinition, IconPack } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { AppBar, Grid, Paper, Tab, Tabs, ButtonBase, TextField, Divider, InputAdornment, Chip, Popover, CircularProgress, fade } from '@material-ui/core';
import { FC, useState, forwardRef, useMemo, useEffect, useRef } from "react";
import { GridComponents, VirtuosoGrid, VirtuosoGridHandle } from "react-virtuoso";



const dedupeIconPack = (pack: IconPack) => {
    const deduplicated = Object.keys(pack)
        .map(x => ({ key: x, icon: pack[x] }))
        .reduce<Record<string, any>>((a , b) => {
            if (!a[b.icon.iconName]) {
                a[b.icon.iconName] = b;
            }

            return a;
        }, {});

    return Object.keys(deduplicated)
        .reduce<IconPack>((a,b) => {
            a[deduplicated[b].key] = deduplicated[b].icon;

            return a;
        }, {});
        
}

const dedupedFat = dedupeIconPack(fat);
const dedupedFal = dedupeIconPack(fal);
const dedupedFar = dedupeIconPack(far);
const dedupedFas = dedupeIconPack(fas);
const dedupedFad = dedupeIconPack(fad);
const dedupedFab = dedupeIconPack(fab);

const fadKeys = Object.keys(dedupedFad);
const falKeys = Object.keys(dedupedFal);
const farKeys = Object.keys(dedupedFar)
const fasKeys = Object.keys(dedupedFas);
const fatKeys = Object.keys(dedupedFat);
const fabKeys = Object.keys(dedupedFab);

export const iconDefaults = {
    iconName: 'exclamation-circle',
    prefix: 'fad'
};

const components: GridComponents = {
    List:  forwardRef((props, ref) => {
        return (
            <div style={{padding: 8}}>
                <Grid container spacing={1} {...props} ref={ref} />
            </div>
        )
    }),
    Item: ({children, ...props}) => {
        return (
            <Grid xs={2} item {...props}>
                {children}
            </Grid>
        )
    }
}

interface IIconPicker {
    value?: IconDefinition;
    open: boolean;
    onClose: () => void;
    anchorRef: any;
    onUpdate: (icon: IconDefinition) => void;
}

const IconPicker: FC<IIconPicker> = ({
    value,
    open,
    onClose,
    anchorRef,
    onUpdate
}) => {
    const [tab, setTab] = useState(0);
    const [search, setSearch] = useState<string>();

    const icons = useMemo(() => {
        const lowerCaseSearch = search?.toLowerCase();

        return [
            { 
                keys: lowerCaseSearch ? fatKeys.filter(x => dedupedFat[x].iconName.includes(lowerCaseSearch)) : fatKeys,
                icons: dedupedFat
            },
            { 
                keys: lowerCaseSearch ? falKeys.filter(x => dedupedFal[x].iconName.includes(lowerCaseSearch)) : falKeys, 
                icons: dedupedFal
            },
            { 
                keys: lowerCaseSearch ? farKeys.filter(x => dedupedFar[x].iconName.includes(lowerCaseSearch)) : farKeys, 
                icons: dedupedFar
            },
            { 
                keys: lowerCaseSearch ? fasKeys.filter(x => dedupedFas[x].iconName.includes(lowerCaseSearch)) : fasKeys, 
                icons: dedupedFas
            },
            { 
                keys: lowerCaseSearch ? fadKeys.filter(x => dedupedFad[x].iconName.includes(lowerCaseSearch)) : fadKeys, 
                icons: dedupedFad
            },
            { 
                keys: lowerCaseSearch ? fabKeys.filter(x => dedupedFab[x].iconName.includes(lowerCaseSearch)) : fabKeys, 
                icons: dedupedFab
            }
        ]
    }, [search])

    const [loading, setLoading] = useState(false);


    const [redirected, setRedirected] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        setSearch(undefined);

        const id = setTimeout(() => {
            setLoading(false)
            setRedirected(false)
        }, 500);

        return () => {
            clearTimeout(id);
        }
    }, [open, setLoading, setRedirected, setSearch])

    const virtuosoRef = useRef<VirtuosoGridHandle>(null);

    const selectedIcon = useMemo(() => {
        if (!value) {
            return undefined;
        }

        let selectedTab = 0

        switch (value.prefix) {
            case 'fat':
                selectedTab = 0;
                break;
            case 'fal':
                selectedTab = 1;
                break;
            case 'far':
                selectedTab = 2;
                break;
            case 'fas':
                selectedTab = 3;
                break;
            case 'fad':
                selectedTab = 4;
                break;
            case 'fab':
                selectedTab = 5;
                break;
        }

        let iconSet = icons[selectedTab];
        
        let iconName = value.iconName;

        let iconInfo = Object.keys(iconSet.icons).map(x => ({ key: x, icon: iconSet.icons[x] })).filter(x => x.icon.iconName === iconName || x.icon.icon[2]?.includes(iconName)).find(() => true);

        let index = iconInfo ? iconSet.keys.indexOf(iconInfo.key) : -1;

        if (index === -1) {
            return undefined;
        }

        return {
            tab: selectedTab,
            index,
            ...iconInfo
        }
    }, [value, icons])

    useEffect(() => {
        if (!selectedIcon || loading || redirected) {
            return;
        }

        setRedirected(true);

        setTab(selectedIcon.tab);

        setTimeout(() => virtuosoRef.current?.scrollToIndex({
            index: selectedIcon.index
        }), 150);
    }, [selectedIcon, virtuosoRef, loading, setTab, redirected, setRedirected])

    return (
        
        <Popover
            id={'edit.color'}
            open={!!anchorRef.current && open}
            anchorEl={anchorRef.current}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div
                style={{width: 480, height: 505}}
            >
                <AppBar position="static">
                    <Tabs
                        value={tab}
                        onChange={(event, newValue) => {
                            setTab(newValue);
                        }}
                        variant="scrollable"
                        scrollButtons="off"
                        aria-label="scrollable prevent tabs example"
                    >
                        <Tab 
                            style={{ minWidth: 80}}
                            icon={(
                                <FontAwesomeIcon icon={{ prefix: 'fat', iconName: 'icons' }} fixedWidth />
                            )}
                            label={
                                <Chip size="small" label={icons[0].keys.length} style={{transform: 'scale(0.75)'}} />
                            }
                            aria-label="thin" 
                        />
                        <Tab 
                            style={{ minWidth: 80}}
                            icon={(
                                    <FontAwesomeIcon icon={{ prefix: 'fal', iconName: 'icons' }} fixedWidth />
                            )} 
                            label={
                                <Chip size="small" label={icons[1].keys.length} style={{transform: 'scale(0.75)'}} />
                            }
                            aria-label="light" 
                        />
                        <Tab 
                            style={{ minWidth: 80}}
                            icon={(
                                    <FontAwesomeIcon icon={{ prefix: 'far', iconName: 'icons' }} fixedWidth />
                            )} 
                            label={
                                <Chip size="small" label={icons[2].keys.length} style={{transform: 'scale(0.75)'}} />
                            }
                            aria-label="regular" 
                        />
                        <Tab 
                            style={{ minWidth: 80}}
                            icon={(
                                    <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'icons' }} fixedWidth />
                            )} 
                            label={
                                <Chip size="small" label={icons[3].keys.length} style={{transform: 'scale(0.75)'}} />
                            }
                            aria-label="solid" 
                        />
                        <Tab
                            style={{ minWidth: 80}} 
                            icon={(
                                    <FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'icons' }} fixedWidth />
                            )} 
                            label={
                                <Chip size="small" label={icons[4].keys.length} style={{transform: 'scale(0.75)'}} />
                            }
                            aria-label="duotone" 
                        />
                        <Tab 
                            style={{ minWidth: 80}}
                            icon={(
                                    <FontAwesomeIcon icon={{ prefix: 'fab', iconName: 'font-awesome-flag' }} fixedWidth />
                            )} 
                            label={
                                <Chip size="small" label={icons[5].keys.length} style={{transform: 'scale(0.75)'}} />
                            }
                            aria-label="brands" 
                        />
                    </Tabs>
                </AppBar>
                <TextField 
                    value={search ?? ''} 
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSearch(e.target.value !== '' ? e.target.value : undefined);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'magnifying-glass' }} fixedWidth />
                            </InputAdornment>
                        )
                    }}
                />
                <Divider />
                {
                    open && !!anchorRef.current && loading  && (
                        <CircularProgress style={{marginTop: 150, marginLeft: 180}} size={100} />
                    )
                }
                {
                    open && !!anchorRef.current && !loading && (
                        <VirtuosoGrid
                            ref={virtuosoRef}
                            style={{ height: 400 }}
                            components={components}
                            totalCount={icons[tab].keys.length}
                            itemContent={(index) => {
                                const iconName = icons[tab].keys[index];
                                return (
                                    <Paper
                                        component={ButtonBase}
                                        style={{position: 'relative', width: '100%', height: 0, paddingBottom: '100%', background: tab === selectedIcon?.tab && index === selectedIcon?.index ? fade("#cd1342", 0.5) : undefined}}
                                        onClick={() => { onUpdate(icons[tab].icons[iconName]); }}
                                    >
                                        <FontAwesomeIcon icon={icons[tab].icons[iconName] as any} fixedWidth style={{position: 'absolute', width: '90%', height: '90%', top: '5%', left: '5%'}} />
                                    </Paper>
                                )
                            }}
                        />
                    )
                }
            </div>
        </Popover>
    )
}

export default IconPicker;