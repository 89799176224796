import * as Dtos from "../api/dtos";
import { FunctionComponent, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Field, FileUpload, FormOptionsContext } from '@ngt/forms';

const useStyles = makeStyles((theme) => ({
    attachmentContainerOuter: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    attachmentContainerInner: {
        display: 'flex',
        alignItems: 'flex-end',
        flexGrow: 1,
        marginTop: '16px',
        marginBottom: '8px',
        '& > div:nth-child(2)': {
            flexGrow: 1
        },
    },
    attachmentLabel: {
        marginRight: '16px',
        marginBottom: '4px',
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.body1.fontFamily,
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

interface IAttachmentProps {
    attachmentLabel?: string;
    fileName?: string;
}

const existingLink = (value?: Dtos.IFileUpload) => {
    const file = value as Dtos.File;

    return `/forms/file/view/${file?.id}`;
}

const existingDownloadLink = (value?: Dtos.IFileUpload) => {
    const file = value as Dtos.File;

    return `/forms/file/download/${file?.id}`;
}

const Attachment: FunctionComponent<IAttachmentProps> = ({ attachmentLabel, fileName }) => {
    const classes = useStyles();
    const resolvedAttachmentLabel = attachmentLabel ?? 'Attachment';
    const resolvedFileName = fileName ?? 'file';

    const formOptions = useContext(FormOptionsContext);

    return <div className={classes.attachmentContainerOuter}>
        <div className={classes.attachmentContainerInner}>
            <div className={classes.attachmentLabel}>
                {
                    resolvedAttachmentLabel
                }
            </div>
            <Field
                name={resolvedFileName}
                component={FileUpload}
                existingLink={existingLink}
                existingDownloadLink={existingDownloadLink}
                disabled={formOptions.readOnly}
            />
        </div>
    </div>;
}

export default Attachment;