import { Theme, ThemeOptions } from '@material-ui/core';
import { JsonServiceClient } from '@servicestack/client'
import { createBrowserHistory, History } from 'history';
// import { AuthenticateResponse } from "../apis/dtos";

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

//const defaultThemeOptions: ThemeOptions = {
//    typography: {
//        h1: {
//            fontSize: '3rem',
//        },
//        h2: {
//            fontSize: '1.5rem'
//        },
//        h3: {
//            fontSize: '1.25rem'
//        },
//        h4: {
//            fontSize: '1.20rem'
//        },
//        h5: {
//            fontSize: '1.15rem'
//        },
//        h6: {
//            fontSize: '1.10rem'
//        }
//    }
//};

interface IFormsBrandOptions {
    name: string;
    logo?: string;
}

interface IFormsUris {
    form: string;
    adminDashboard: string;
    formsDashboard: string;
    //login: string;
    //profile: string;
    //logout: string;
}

interface IFormsOptions {
    /** The name of the identity server, displayed in the app bar, etc. */
    name: string;
    /** The branding of the identity server */
    branding: IFormsBrandOptions;
    theme?: ThemeOptions;
    uris?: IFormsUris;
    client?: JsonServiceClient
}


class FormsContextContainer {
    // initialAuthentication: AuthenticateResponse | null;
    history: History<unknown>;
    theme: Theme;
    name: string;
    branding: IFormsBrandOptions;
    uris: IFormsUris;
    client: JsonServiceClient;

    constructor(options: IFormsOptions) {
        // this.initialAuthentication = global.AUTH ?? null;
        this.history = createBrowserHistory();
        this.name = options?.name ?? '';
        this.branding = options?.branding;
        this.uris = options?.uris ?? {form: ''};
        this.client = options?.client ?? new JsonServiceClient();

        // this.initialiseTheme(options?.theme);
    }

    //private initialiseTheme(theme?: ThemeOptions) {
    //    const primaryColor = (theme?.palette?.primary as any)?.main;
    //    const secondaryColor = (theme?.palette?.secondary as any)?.main;

    //    const defaults = {
    //        ...defaultThemeOptions,
    //        typography: {
    //            ...defaultThemeOptions?.typography,
    //            h1: {
    //                ...(defaultThemeOptions?.typography as any)?.h1,
    //                color: secondaryColor
    //            },
    //            h2: {
    //                ...(defaultThemeOptions?.typography as any)?.h2,
    //                color: primaryColor
    //            },
    //            h3: {
    //                ...(defaultThemeOptions?.typography as any)?.h3,
    //                color: secondaryColor
    //            },
    //            h4: {
    //                ...(defaultThemeOptions?.typography as any)?.h4,
    //                color: primaryColor
    //            },
    //            h5: {
    //                ...(defaultThemeOptions?.typography as any)?.h5,
    //                color: secondaryColor
    //            },
    //            h6: {
    //                ...(defaultThemeOptions?.typography as any)?.h6,
    //                color: primaryColor
    //            }
    //        }
    //    }

    //    this.theme = createMuiTheme({ ...defaults, ...theme });
    //}
}

export default FormsContextContainer;