import { Grid, makeStyles, Paper } from "@material-ui/core";
import { Field, FieldProvider, Text, TextArea, Numeric, DatePicker, IConditionParameters, Checkbox, Condition } from "@ngt/forms";
import { ConditionMode } from '@ngt/forms-core';
import { FC } from "react";
import * as Dtos from '../../api/dtos';
import Attachment from "../../components/Attachment";
import FormContainer from "../../components/layouts/FormContainer";
import { fieldMargin, gridFieldMargin } from "../../constants/forms";

interface INewVendorRequestProps { }

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 2, 2, 2),
        margin: theme.spacing(2, 0, 2, 0)
    },
    gridItemCenter:{
        display: 'flex',
        alignItems: 'center'
    }
}));

const valuesSubscription = { values: true };

const abnProvided = ({ formState }: IConditionParameters<Dtos.NewVendorRequest>) => {
    
    return formState?.values.abnNotProvided !== true;
}

const NewVendorRequest : FC<INewVendorRequestProps> = () => {

    const classes = useStyles();

    return (
        <FormContainer
            maxWidth="md"
            formType={ Dtos.NewVendorRequest }
        >
            { /* FieldProvider is placed here so form level errors will appear on the form. */}
            <FieldProvider name="id" />
            <Paper className={classes.paper}>
                <Field
                    name="name"
                    component={Text}
                    margin={fieldMargin}
                />
                <Field
                    name="address"
                    component={TextArea}
                    rows={2}
                    rowsMax={2}
                    margin={fieldMargin}
                />
                <Field
                    name="contactName"
                    component={Text}
                    margin={fieldMargin}
                />
                <Field
                    name="phone"
                    component={Text}
                    margin={fieldMargin}
                />
                <Field
                    name="email"
                    component={Text}
                    margin={fieldMargin}
                />
                <Grid
                    container
                    spacing={6}
                    justify="space-between"
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={9}
                    >
                        <Condition
                            type={Dtos.NewVendorRequest}
                            mode={ConditionMode.Enable}
                            condition={abnProvided}
                            subscription={valuesSubscription}
                            registerParent
                        >
                            <Field
                                name="abn"
                                component={Numeric}
                                margin={fieldMargin}
                                inputProps={{maxLength: 11}}
                            />
                        </Condition>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        className={classes.gridItemCenter}
                    >
                        <Field
                            name="abnNotProvided"
                            component={Checkbox}
                            inputProps={{maxLength: 11}}
                        />
                    </Grid>
                </Grid>
                <Attachment />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Field
                            name="enteredBy"
                            component={Text}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            name="enteredDate"
                            component={DatePicker}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>
        </FormContainer>
    )
}

export default NewVendorRequest;