import { Grid, makeStyles, Paper, TableContainer } from "@material-ui/core";
import { Field, FieldProvider, Text, TextArea, Numeric, DatePicker, FormGrid, IFormGridCell, FuzzySelect, ILookupFilterProps, LookupFilter, getParentPropertyPath, Condition, IConditionParameters } from "@ngt/forms";
import { ConditionMode, Input, useFormActions, useScopedField } from "@ngt/forms-core";
import React, { FunctionComponent, useEffect } from "react";
import * as Dtos from '../../api/dtos';
import FormContainer from "../../components/layouts/FormContainer";
import { fieldMargin, gridFieldMargin } from "../../constants/forms";
import { MONEY_FORMAT } from "../../constants/numberFormats";
import { get } from 'lodash-es';
import { DOLLAR_PREFIX } from "../../constants/inputAdornments";
import Attachment from "../../components/Attachment";

interface ICreateNewEftRequestProps { }

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 2, 2, 2),
        margin: theme.spacing(2, 0, 2, 0)
    },
    subHeading: {
        padding: theme.spacing(1, 0, 1, 0)
    }
}));

const valuesSubscription = { values: true };

const commitmentFilter: ILookupFilterProps<Dtos.CreateNewEftRequest> = {
    subscription: valuesSubscription,
    filterFunction: (formState, _, lookup) => {
        return {
            propertyName: lookup?.propertyName ?? '',
            items: lookup?.items.filter(item => formState.values.payableTo !== undefined && (item as Dtos.LookupItem).parent === formState.values.payableTo) ?? []
        };
    }
}

const getGridRowValues = ({ formState, parentFieldName }: any) => {
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return null;
    }

    const row: Dtos.CreateNewEftRequestItem = get(formState?.values, parentPropertyPath);

    return row;
}

const costCodeFilter: ILookupFilterProps<Dtos.CreateNewEftRequestItem> = {
    subscription: valuesSubscription,
    filterFunction: (formState, fieldName, lookup) => {
        const row: Dtos.CreateNewEftRequestItem | null = getGridRowValues({formState, parentFieldName: fieldName});
        const jobCode = row?.jobCode;

        //const filteredLookupItems = lookup?.items.filter(item =>
        //    jobCode !== undefined &&
        //    ((item as Dtos.LookupItem).metadata?.jobCodes as string[])?.includes(jobCode)) ?? [];

        const filteredLookupItems = lookup?.items.filter(item =>
            jobCode !== undefined &&
            ((item as Dtos.LookupItem).parent === jobCode)) ?? [];

        return {
            propertyName: lookup?.propertyName ?? '',
            items: filteredLookupItems
        };
    }
}

const jobFilter: ILookupFilterProps<Dtos.CreateNewEftRequest> = {
    subscription: valuesSubscription,
    filterFunction: (formState, _, lookup) => {

        // const row: Dtos.CreateNewEftRequestItem | null = getGridRowValues({ formState, parentFieldName: fieldName });
        const projectId = formState?.values?.projectId;

        //const filteredLookupItems = lookup?.items.filter(item =>
        //    jobCode !== undefined &&
        //    ((item as Dtos.LookupItem).metadata?.jobCodes as string[])?.includes(jobCode)) ?? [];

        const filteredLookupItems =
            !projectId ?
                [] :
                lookup?.items.filter(item =>
                    ((item as Dtos.LookupItem).parent === projectId.toString())) ?? [];

        return {
            propertyName: lookup?.propertyName ?? '',
            items: filteredLookupItems
        };
    }
}


const isCommitmentUnselected = ({ formState, parentFieldName }: IConditionParameters<Dtos.CreateNewEftRequestItem>) => {

    const row: Dtos.CreateNewEftRequestItem | null = getGridRowValues({ formState, parentFieldName });

    if (!row) {
        return false;
    }

    return !row.commitment;
}

const isAccountEnabled = ({ lookups, formState, parentFieldName }: IConditionParameters<Dtos.CreateNewEftRequestItem>) => {
    const commitmentUnselected = isCommitmentUnselected({ lookups, formState, parentFieldName });

    if (!commitmentUnselected) {
        return false;
    }

    const row: Dtos.CreateNewEftRequestItem | null = getGridRowValues({ formState, parentFieldName });

    if (!row) {
        return false;
    }

    return !(row.jobCode && row.costCode);
}

const columns: Array<IFormGridCell<Dtos.CreateNewEftRequestItem>> = [
    {
        name: 'commitment',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <LookupFilter {...commitmentFilter}>
                <Input
                    component={FuzzySelect}
                />
            </LookupFilter>
        )
    },
    {
        name: 'jobCode',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Condition
                type={Dtos.CreateNewEftRequestItem}
                mode={ConditionMode.Enable}
                condition={isCommitmentUnselected}
                subscription={valuesSubscription}
                registerParent
            >
                <LookupFilter {...jobFilter}>
                    <Input
                        component={FuzzySelect}
                    />
                </LookupFilter>
            </Condition>
        )
    },
    {
        name: 'costCode',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <LookupFilter {...costCodeFilter}>
                <Condition
                    type={Dtos.CreateNewEftRequestItem}
                    mode={ConditionMode.Enable}
                    condition={isCommitmentUnselected}
                    subscription={valuesSubscription}
                    registerParent
                >
                    <Input
                        component={FuzzySelect}
                    />
                </Condition>
            </LookupFilter>
        )
    },
    {
        name: 'amount',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Numeric}
                numberFormat={MONEY_FORMAT}
                InputProps={DOLLAR_PREFIX}
            />
        )
    },
    {
        name: 'gstAmount',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Numeric}
                numberFormat={MONEY_FORMAT}
                InputProps={DOLLAR_PREFIX}
            />
        )
    },
    {
        name: 'gstFree',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={FuzzySelect}
            />
        )
    },
    //{
    //    name: 'company',
    //    minWidth: 140,
    //    maxWidth: 140,
    //    width: 140,
    //    content: (
    //        <Input
    //            component={Select}
    //        />
    //    )
    //},
    //{
    //    name: 'department',
    //    minWidth: 140,
    //    maxWidth: 140,
    //    width: 140,
    //    content: (
    //        <Input
    //            component={Select}
    //        />
    //    )
    //},
    {
        name: 'account',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Condition
                type={Dtos.CreateNewEftRequestItem}
                mode={ConditionMode.Enable}
                condition={isAccountEnabled}
                subscription={valuesSubscription}
                registerParent
            >
                <Input
                    component={FuzzySelect}
                />
            </Condition>
        )
    }
]

const subscription = {};

const TotalAmountUpdater: FunctionComponent = () => {
    
    const { subscribe } = useFormActions<Dtos.CreateNewEftRequest, Dtos.IValidationError>();
    const { context: { setValue: setTotalAmount } } = useScopedField<Dtos.CreateNewEftRequest['totalAmount'], Dtos.IValidationError>('totalAmount', subscription);

    useEffect(() => {
        const unsubscribe = subscribe(
            formState => {
                const { items } = formState.values;

                const totalAmount = items?.reduce((a, b) => a + (b.amount ?? 0) + (b.gstAmount ?? 0), 0) ?? 0;
                setTotalAmount(totalAmount, false, true, true);
            },
            {
                values: true,
                dirty: false,
                errors: false,
                fields: false,
                focused: false,
                initialValues: false,
                submitting: false,
                touched: false,
                validating: false
            }
        );

        return () => {
            unsubscribe();
        }
    }, [setTotalAmount, subscribe])

    return null;
};

const CreateNewEftRequest: React.FunctionComponent<ICreateNewEftRequestProps> = () => {

    const classes = useStyles();

    return (
        <FormContainer
            maxWidth={false}
            formType={ Dtos.CreateNewEftRequest }
        >
            { /* FieldProvider is placed here so form level errors will appear on the form. */}
            <FieldProvider name="id" />
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="projectId"
                            component={FuzzySelect}
                            margin={gridFieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="payableTo"
                            component={FuzzySelect}
                            margin={gridFieldMargin}
                        />
                    </Grid>
                </Grid>

                <Field
                    name="description"
                    component={TextArea}
                    margin={fieldMargin}
                    rows={2}
                    rowsMax={5}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TotalAmountUpdater />
                        <Field
                            name="totalAmount"
                            component={Numeric}
                            numberFormat={MONEY_FORMAT}
                            InputProps={DOLLAR_PREFIX}
                            margin={gridFieldMargin}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}  md={6}>
                        <Field
                            name="dateRequired"
                            component={DatePicker}
                            margin={gridFieldMargin}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <TableContainer component={Paper}>
                <FormGrid
                    type={Dtos.CreateNewEftRequestItem}
                    name="items"
                    columns={columns}
                />
            </TableContainer>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="authorisations"
                            component={Text}
                            margin={fieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Attachment />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="enteredBy"
                            component={Text}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="enteredDate"
                            component={DatePicker}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>
        </FormContainer>
    );
}

export default CreateNewEftRequest;