import FormsContextContainer from "./FormsContextContainer";
import logo from '../assets/img/logo-small.png'

const formsContextConfigured = new FormsContextContainer({
    name: 'Forms',
    branding: {
        name: 'McCloy Group',
        logo: logo
    },
    uris: {
        form: '/form',
        adminDashboard: '/admin-dashboard',
        formsDashboard: '/dashboard'
    }
    //theme: {
    //    palette: {
    //        primary: {
    //            main: "#004842"
    //        },
    //        secondary: {
    //            main: "#cd1342"
    //        }
    //    }
    //}
});

export default formsContextConfigured;