import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { ReactNode } from 'react';


interface IBasicInformationProps {
    title?: string;
    description?: ReactNode;
}

const BasicInformation: React.FunctionComponent<IBasicInformationProps> = ({
    title,
    description
}) => {
    return (
        <Alert
            icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />}
            severity="info"
        >
            {title && <AlertTitle>{title}</AlertTitle>}
            {description}
        </Alert>
    )
};

export default BasicInformation;