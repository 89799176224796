import { Grid, makeStyles, Paper, TableContainer } from "@material-ui/core";
import { Field, FieldProvider, Text, TextArea, Numeric, DatePicker, FormGrid, IFormGridCell, FuzzySelect, ILookupFilterProps, getParentPropertyPath, LookupFilter, Condition, IConditionParameters } from "@ngt/forms";
import { ConditionMode, Input, useFormActions, useScopedField } from "@ngt/forms-core";
import { get } from "lodash-es";
import { FunctionComponent, useEffect } from "react";
import * as Dtos from '../../api/dtos';
import FormContainer from "../../components/layouts/FormContainer";
import { fieldMargin, gridFieldMargin } from "../../constants/forms";
import { DOLLAR_PREFIX } from "../../constants/inputAdornments";
import { MONEY_FORMAT } from "../../constants/numberFormats";

interface IIntercompanyEftRequestProps { }

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 2, 2, 2),
        margin: theme.spacing(2, 0, 2, 0)
    },
    table: {
        margin: theme.spacing(2, 0, 2, 0)
    },
    subHeading: {
        padding: theme.spacing(3, 0, 1, 0)
    }
}));

const valuesSubscription = { values: true };

const costCodeFilter: ILookupFilterProps<Dtos.IntercompanyEftRequestItem> = {
    subscription: valuesSubscription,
    filterFunction: (formState, fieldName, lookup) => {
        const row: Dtos.IntercompanyEftRequestItem | null = getGridRowValues({ formState, parentFieldName: fieldName });
        const jobCode = row?.jobCode;

        //const filteredLookupItems = lookup?.items.filter(item =>
        //    jobCode !== undefined &&
        //    ((item as Dtos.LookupItem).metadata?.jobCodes as string[])?.includes(jobCode)) ?? [];

        const filteredLookupItems = lookup?.items.filter(item =>
            jobCode !== undefined &&
            ((item as Dtos.LookupItem).parent === jobCode)) ?? [];

        return {
            propertyName: lookup?.propertyName ?? '',
            items: filteredLookupItems
        };
    }
}

const getGridRowValues = ({ formState, parentFieldName }: any) => {
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return null;
    }

    const row: Dtos.IntercompanyEftRequestItem = get(formState?.values, parentPropertyPath);

    return row;
}

const isCommitmentUnselected = ({ formState, parentFieldName }: IConditionParameters<Dtos.IntercompanyEftRequestItem>) => {

    const row: Dtos.IntercompanyEftRequestItem | null = getGridRowValues({ formState, parentFieldName });

    if (!row) {
        return false;
    }

    return !row.commitment;
}

const isAccountEnabled = ({ lookups, formState, parentFieldName }: IConditionParameters<Dtos.IntercompanyEftRequestItem>) => {
    const commitmentUnselected = isCommitmentUnselected({ lookups, formState, parentFieldName });

    if (!commitmentUnselected) {
        return false;
    }

    const row: Dtos.IntercompanyEftRequestItem | null = getGridRowValues({ formState, parentFieldName });

    if (!row) {
        return false;
    }

    return !(row.jobCode && row.costCode);
}

const subscription = {};

const TotalAmountUpdater: FunctionComponent = () => {

    const { subscribe } = useFormActions<Dtos.IntercompanyEftRequest, Dtos.IValidationError>();
    const { context: { setValue: setTotalAmount } } = useScopedField<Dtos.IntercompanyEftRequest['totalAmount'], Dtos.IValidationError>('totalAmount', subscription);

    useEffect(() => {
        const unsubscribe = subscribe(
            formState => {
                const { items } = formState.values;

                const totalAmount = items?.reduce((a, b) => a + (b.amount ?? 0) + (b.gstAmount ?? 0), 0) ?? 0;
                setTotalAmount(totalAmount, false, true, true);
            },
            {
                values: true,
                dirty: false,
                errors: false,
                fields: false,
                focused: false,
                initialValues: false,
                submitting: false,
                touched: false,
                validating: false
            }
        );

        return () => {
            unsubscribe();
        }
    }, [setTotalAmount, subscribe])

    return null;
};

const columns: Array<IFormGridCell<Dtos.IntercompanyEftRequestItem>> = [
    //{
    //    name: 'commitment',
    //    minWidth: 140,
    //    maxWidth: 140,
    //    width: 140,
    //    content: (
    //        <Input
    //            component={FuzzySelect}
    //        />
    //    )
    //},
    {
        name: 'jobCode',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (

            <Condition
                type={Dtos.IntercompanyEftRequestItem}
                mode={ConditionMode.Enable}
                condition={isCommitmentUnselected}
                subscription={valuesSubscription}
                registerParent
            >
                <Input
                component={FuzzySelect}
                />
            </Condition>
        )
    },
    {
        name: 'costCode',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <LookupFilter {...costCodeFilter}>
                <Condition
                    type={Dtos.IntercompanyEftRequestItem}
                    mode={ConditionMode.Enable}
                    condition={isCommitmentUnselected}
                    subscription={valuesSubscription}
                    registerParent
                >
                    <Input
                        component={FuzzySelect}
                    />
                </Condition>
            </LookupFilter>
        )
    },
    {
        name: 'extra',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'amount',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Numeric}
                numberFormat={MONEY_FORMAT}
                InputProps={DOLLAR_PREFIX}
            />
        )
    },
    {
        name: 'gstAmount',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Numeric}
                numberFormat={MONEY_FORMAT}
                InputProps={DOLLAR_PREFIX}
            />
        )
    },
    {
        name: 'gstFree',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={FuzzySelect}
            />
        )
    },
    //{
    //    name: 'company',
    //    minWidth: 140,
    //    maxWidth: 140,
    //    width: 140,
    //    content: (
    //        <Input
    //            component={Text}
    //        />
    //    )
    //},
    //{
    //    name: 'department',
    //    minWidth: 140,
    //    maxWidth: 140,
    //    width: 140,
    //    content: (
    //        <Input
    //            component={Text}
    //        />
    //    )
    //},
    {
        name: 'account',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Condition
                type={Dtos.IntercompanyEftRequestItem}
                mode={ConditionMode.Enable}
                condition={isAccountEnabled}
                subscription={valuesSubscription}
                registerParent
            >
                <Input
                    component={FuzzySelect}
                />
            </Condition>
        )
    }
]

const IntercompanyEftRequest: React.FunctionComponent<IIntercompanyEftRequestProps> = () => {

    const classes = useStyles();

    return (
        <FormContainer
            maxWidth={false}
            formType={Dtos.IntercompanyEftRequest}
        >
            { /* FieldProvider is placed here so form level errors will appear on the form. */}
            <FieldProvider name="id" />
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="fromCompany"
                            component={FuzzySelect}
                            margin={gridFieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="bankName"
                            component={Text}
                            margin={gridFieldMargin}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="toCompany"
                            component={FuzzySelect}
                            margin={gridFieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="toBankName"
                            component={Text}
                            margin={gridFieldMargin}
                        />
                    </Grid>
                </Grid>
                <Field
                    name="reason"
                    component={TextArea}
                    margin={fieldMargin}
                    rows={2}
                    rowsMax={5}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="dateTransferRequired"
                            component={DatePicker}
                            margin={gridFieldMargin}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TotalAmountUpdater />
                        <Field
                            name="totalAmount"
                            component={Numeric}
                            numberFormat={MONEY_FORMAT}
                            InputProps={DOLLAR_PREFIX}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>
            <TableContainer component={Paper} className={classes.table}>
                <FormGrid
                    type={Dtos.IntercompanyEftRequestItem}
                    name="items"
                    columns={columns}
                />
            </TableContainer>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="enteredBy"
                            component={Text}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="enteredDate"
                            component={DatePicker}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>
            {/*Christian advised this is not required.*/}
            {/*<Paper className={classes.paper}>*/}
            {/*    <Grid container spacing={2}>*/}
            {/*        <Grid item xs={12} md={6}>*/}
            {/*            <Field*/}
            {/*                name="chequeNumber"*/}
            {/*                component={Text}*/}
            {/*                margin={gridFieldMargin}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} md={6}>*/}
            {/*            <Field*/}
            {/*                name="dateChequeDrawn"*/}
            {/*                component={DatePicker}*/}
            {/*                margin={gridFieldMargin}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Paper>*/}
        </FormContainer>
    );
}

export default IntercompanyEftRequest;