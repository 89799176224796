import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { ResponseStatus } from '@servicestack/client';
import React, { FunctionComponent } from 'react';
import RequestAlert from './RequestAlert';


interface IRequestErrorProps {
    responseStatus: ResponseStatus
}

const RequestError: FunctionComponent<IRequestErrorProps> = ({
    responseStatus
}) => {
    return (
        <RequestAlert
            icon={faExclamationCircle}
            responseStatus={responseStatus}
            severity="error"
        />
    )
};

export default RequestError;