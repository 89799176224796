////import FormsContextContainer from "../contexts/FormsContextContainer";
////import * as Dtos from "../api/dtos";

////const useSetFormProcessState = () => {

////    // return useCallback(() => { }, []);

////    const setFormProcessState = async (
////        forms: FormsContextContainer,
////        formId: number,
////        formDefinitionId: number,
////        newProcessState: Dtos.ProcessState
////    ) => {

////        return await forms.client.post(new Dtos.SetFormProcessState({
////            formId: formId,
////            formDefinitionId: formDefinitionId,
////            newProcessState: newProcessState
////        }));
////    }


////}

////export default useSetFormProcessState;

import FormsContext from "../contexts/FormsContext";
import FormsContextContainer from "../contexts/FormsContextContainer";
import * as Dtos from "../api/dtos";
import { useCallback, useContext, useRef } from "react"
import { useSnackbar } from "@ngt/forms";

const setFormProcessStatePromise = async (
    forms: FormsContextContainer,
    formId: number,
    formDefinitionId: number,
    newProcessState: Dtos.ProcessState
) => {

    return await forms.client.post(new Dtos.SetFormProcessState({
        formId: formId,
        formDefinitionId: formDefinitionId,
        newProcessState: newProcessState
    }));
}

const useSetFormProcessState = (
    successfn?: () => void,
    failFn?: () => void
) => {
    const formsContext = useContext(FormsContext);
    const { enqueueSnackbar } = useSnackbar();
    const successFnRef = useRef(successfn);
    const failFnRef = useRef(failFn);

    const setFormProcessState =
        useCallback((
            formId: number | undefined,
            formDefId: number | undefined,
            processState: Dtos.ProcessState
        ) => {

            if (!formId || !formDefId) {
                return;
            }

            setFormProcessStatePromise(
                formsContext,
                formId,
                formDefId,
                processState
            ).then((/* response: Dtos.SetFormProcessStateResponse*/) => {
                // refreshParentForms();
                enqueueSnackbar(
                    processState === Dtos.ProcessState.Processed ?
                        'Processed!' :
                        'Deleted!',
                    { variant: 'success' });

                successFnRef.current && successFnRef.current();

            }).catch(() => {
                enqueueSnackbar('Failed!', { variant: 'error' });

                failFnRef.current && failFnRef.current();
            });
        }, [formsContext, enqueueSnackbar, successFnRef, failFnRef]);

    return { setFormProcessState };
}

export default useSetFormProcessState;