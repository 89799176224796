import { CircularProgress, Container, makeStyles, Typography } from "@material-ui/core"
import { FunctionComponent, useCallback } from "react"
import FormSummaryCard from "../components/FormSummaryCard";
import useParentFormSummaries from "../hooks/useParentForms";
import { FixedSizeList } from "react-window";
import { ReactWindowScroller } from 'react-window-scroller';
import useSetFormProcessState from "../hooks/useSetFormProcessState";
import BasicInformation from "../components/layouts/BasicInformation";
import LoadState from "../constants/loadState";
import Error from '../components/layouts/Error';

const useStyles = makeStyles((theme) => ({
    virtualWindowCorrecter: {
        marginLeft: '-8px',
        marginRight: '-8px'
    },
    notification: {
        padding: theme.spacing(3, 0)
    }
}));

const AdminDashboard: FunctionComponent = () => {
    const classes = useStyles();
    const { parentFormSummaries, refreshParentForms, parentFormsLoading } = useParentFormSummaries();
    const refreshFormsCallback = useCallback(() => { refreshParentForms() }, [refreshParentForms]);
    const { setFormProcessState } = useSetFormProcessState(() => { refreshFormsCallback() });
    
    const genRow = useCallback(({ index, style }) => {
        const parentFormSummary = parentFormSummaries[index];

        return <FormSummaryCard
            parentFormSummary={parentFormSummary}
            key={`${parentFormSummary?.formDefinition?.id}-${parentFormSummary?.form?.id}`}
            setFormProcessState={setFormProcessState}
            style={style}
        />
    }, [parentFormSummaries, setFormProcessState]);

    return <Container>
        <Typography
            variant="h1"
        >
            Admin Dashboard
        </Typography>
        {
            parentFormsLoading === LoadState.Loading ?
                <CircularProgress color="secondary"/> :
                null
        }
        {
            parentFormsLoading === LoadState.LoadFail ?
                <div className={classes.notification}>
                    <Error
                        title="Error"
                        description="An error occurred and the list of forms could not be loaded. Please refresh the page and try again."
                    />
                </div> :
                null
        }
        {
            parentFormsLoading === LoadState.LoadSuccess ?
                (parentFormSummaries?.length ?? 0) === 0 ?
                    <div className={classes.notification}>
                        <BasicInformation
                            title="No Completed Forms"
                            description={`There are currently no completed forms.`}
                        />
                    </div> :
                    <div className={classes.virtualWindowCorrecter}>
                        <ReactWindowScroller>
                            {({ ref, outerRef, style, onScroll }: any) => (
                                <FixedSizeList
                                    ref={ref}
                                    outerRef={outerRef}
                                    style={style}
                                    height={window.innerHeight}
                                    width={undefined as any}
                                    itemCount={parentFormSummaries?.length ?? 0}
                                    itemSize={72}
                                    onScroll={onScroll}
                                    overscanCount={2}
                                >
                                    {genRow}
                                </FixedSizeList>
                            )}
                        </ReactWindowScroller>
                    </div> :
                null
        }
    </Container>
}

export default AdminDashboard;