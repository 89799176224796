import * as Dtos from "../api/dtos";
import { Link, useLocation } from "react-router-dom";
import React, { FunctionComponent, useCallback, useContext, useState } from "react";
import { Button, ButtonGroup, Card, CardActionArea, ClickAwayListener, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isoDateToShortDisplayDate } from "../utilities/dateTime";
import FormsContext from "../contexts/FormsContext";
import AuthenticationContext from "../contexts/AuthenticationContext";
import { hasPermission } from "../utilities/auth";
import { ProcessState } from '../api/dtos';


const useStyles = makeStyles((theme) => ({
    virtualCardCorrecter: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    cardRoot: {
        // marginBottom: theme.spacing(2),
        display: 'flex'
    },
    cardActionArea: {
        display: 'flex',
        justifyContent: 'left'
    },
    todoContainer: {
        borderRight: 'solid #d2d2d2 1px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: '100%'
    },
    todoIcon: {
        fontSize: '1.5rem',
        color: theme.palette.grey[500],
    },
    processedIcon: {
        fontSize: '1.5rem',
        // color: green[500]
        color: theme.palette.primary.main
    },
    formTitleContainer: {
        marginLeft: theme.spacing(2)
    },
    formDetailsContainer: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '0.75rem',
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: '0.03333rem'
    },
    actionButtonContainer: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row'
    }
}));

interface IFormCardProps {
    parentFormSummary: Dtos.ParentFormSummary;
    setFormProcessState: (
        formId: number | undefined,
        formDefId: number | undefined,
        processState: Dtos.ProcessState) => void;
    style?: React.CSSProperties;
}

const FormSummaryCard: FunctionComponent<IFormCardProps> = ({ parentFormSummary, setFormProcessState, style }) => {
    const classes = useStyles();
    const form = parentFormSummary?.form;
    const formDef = parentFormSummary?.formDefinition;
    const mcgFormsContext = useContext(FormsContext);
    const viewFormUri = `${mcgFormsContext.uris.form}/${formDef.code}/${form.id}`;
    const isFormProcessed = form?.processState === Dtos.ProcessState.Processed;
    const [deleting, setDeleting] = useState<boolean>(false);
    const location = useLocation();

    const adminSection = location.pathname === "/admin-dashboard";
    const authUser = useContext(AuthenticationContext);
    const isAdmin = hasPermission(authUser?.permissions, Dtos.Permissions.ViewCompleted);

    const details = adminSection ? `${form?.enteredBy} - ${isoDateToShortDisplayDate(form?.enteredDate)}` : `${isoDateToShortDisplayDate(form?.enteredDate)}`

    const deleteButtonFunction = useCallback(() => {
        if (!deleting) {
            return () => { setDeleting(true) };
        }
        else {
            return () => {
                setFormProcessState(form?.id, formDef?.id, Dtos.ProcessState.Archived);
            }
        }
    }, [deleting, setFormProcessState, form, formDef]);

    return <div className={classes.virtualCardCorrecter} style={style}>
        <Card className={classes.cardRoot}>
            <CardActionArea className={classes.cardActionArea} component={Link} to={viewFormUri}>
                <Tooltip title={isFormProcessed ? "Processed" : "Not Processed"}>
                    <div className={classes.todoContainer}>
                        <FontAwesomeIcon
                            fixedWidth
                            icon={isFormProcessed ? faCheckCircle : faCircle}
                            className={isFormProcessed ? classes.processedIcon : classes.todoIcon}
                        />
                    </div>
                </Tooltip>
                <div className={classes.formTitleContainer}>
                    <Typography variant="h4">
                        {formDef?.name}
                    </Typography>
                </div>
                <div className={classes.formDetailsContainer}>
                    <div>
                        {details}
                    </div>
                </div>
            </CardActionArea>
            <ButtonGroup>
                {
                    !isFormProcessed && !deleting && isAdmin && adminSection ?
                        <Tooltip title="Mark Processed">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    setFormProcessState(form?.id, formDef?.id, Dtos.ProcessState.Processed);
                                }}
                            >
                                <FontAwesomeIcon fixedWidth icon={faCheck} size="lg" />
                            </Button>
                        </Tooltip> : 
                        null
                }
                {
                    isAdmin || form.processState == ProcessState.Draft ? // eslint-disable-line eqeqeq
                        <Tooltip title="Delete">
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={deleteButtonFunction()}
                            >
                                {
                                    !deleting ? 
                                        <FontAwesomeIcon fixedWidth icon={faTimes} size="lg" /> :
                                        <ClickAwayListener onClickAway={() => { setDeleting(false) }}>
                                            <span>Confirm?</span>
                                        </ClickAwayListener>
                                }
                            </Button>
                        </Tooltip>
                    : null
                }
                <Tooltip title="View">
                    <Button
                        color="default"
                        variant="contained"
                        component={Link}
                        to={viewFormUri}
                    >
                        <FontAwesomeIcon fixedWidth icon={faEye} size="lg" />
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </Card>
    </div>
}

export default FormSummaryCard;