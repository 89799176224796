import { NumberFormatValues } from 'react-number-format';

const dbNumberFieldLimit = (values: NumberFormatValues) => {

    if (!values.floatValue) {
        return true;
    }

    if (values.floatValue < 1000000000000000 && values.floatValue > -1000000000000000) {
        return true;
    }

    return false;
}

export const INTEGER_FORMAT = {
    decimalScale: 0,
    allowNegative: true
}

export const PERCENTAGE_FORMAT = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {

        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue > 100 || values.floatValue < 0) {
            return false;
        }

        return true;
    }
}

export const MONEY_FORMAT = {
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: true,
    allowNegative: true,
    isAllowed: (values: NumberFormatValues) => dbNumberFieldLimit(values)
}