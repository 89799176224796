import { useCallback, useContext, useEffect, useState } from "react";
import * as Dtos from "../api/dtos";
import LoadState from "../constants/loadState";
import FormsContext from "../contexts/FormsContext";
import FormsContextContainer from "../contexts/FormsContextContainer";

const getParentFormsByAuth = async (forms: FormsContextContainer) => {
    return await forms.client.get(new Dtos.GetParentFormsByAuth());
}

const useParentFormSummariesByAuth = () => {
    const formsContext = useContext(FormsContext);
    const [parentFormSummaries, setParentFormSummaries] = useState<Dtos.ParentFormSummary[]>([]);
    const [parentFormsLoading, setParentFormsLoading] = useState<LoadState>(LoadState.NoLoad);

    const refreshParentForms = useCallback(() => {
        setParentFormsLoading(LoadState.Loading);
        getParentFormsByAuth(formsContext)
            .then((response: Dtos.GetParentFormsResponse) => {

                setParentFormsLoading(LoadState.LoadSuccess);
                setParentFormSummaries(response.parentFormSummaries);

            })
            .catch(() => {
                setParentFormsLoading(LoadState.LoadFail);
            });
    }, [formsContext])

    useEffect(() => {
        refreshParentForms();
    }, [refreshParentForms]);

    return { parentFormSummaries, refreshParentForms, parentFormsLoading };
}

export default useParentFormSummariesByAuth;