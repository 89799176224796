import { Grid, makeStyles, Paper, TableContainer } from "@material-ui/core";
import { Field, FieldProvider, Text, TextArea, Numeric, DatePicker, FormGrid, IFormGridCell, ILookupFilterProps, LookupFilter, getParentPropertyPath, FormLookupsContext, GetFieldLookupItem } from "@ngt/forms";
import { FieldContext, Input, useScopedField } from "@ngt/forms-core";
import React, { FunctionComponent, useContext, useEffect, useMemo } from "react";
import * as Dtos from '../../api/dtos';
import FormContainer from "../../components/layouts/FormContainer";
import { fieldMargin, gridFieldMargin } from "../../constants/forms";
import { MONEY_FORMAT } from "../../constants/numberFormats";
import { DOLLAR_PREFIX } from "../../constants/inputAdornments";
import { gstCodes } from "../../constants/gstCodes";
import FuzzySelect from "../../components/inputs/FuzzySelect";

interface INewCommitmentProps { }

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 2, 2, 2),
        margin: theme.spacing(2, 0, 2, 0)
    },
    subHeading: {
        padding: theme.spacing(1, 0, 1, 0)
    }
}));

const valuesSubscription = { values: true };

const costCodeFilter: ILookupFilterProps<Dtos.NewCommitment> = {
    subscription: valuesSubscription,
    filterFunction: (formState, _, lookup) => {
        const jobCode = formState.values.jobCode;

        const filteredLookupItems = lookup?.items.filter(item =>
            jobCode !== undefined &&
            ((item as Dtos.LookupItem).parent === jobCode)) ?? [];

        return {
            propertyName: lookup?.propertyName ?? '',
            items: filteredLookupItems
        };
    }
}

const subscription = {
    value: true
};

const GstUpdater: FunctionComponent = () => {

    const { name, actions } = useContext(FieldContext) ?? {};
    const rowPath = getParentPropertyPath(name);
    const { state: { value: gstCode } } = useScopedField<Dtos.NewCommitmentItem['gstCode'], Dtos.IValidationError>(`${rowPath}.gstCode`, subscription);
    const { state: { value: amount } } = useScopedField<Dtos.NewCommitmentItem['amount'], Dtos.IValidationError>(`${rowPath}.amount`, subscription);

    const lookups = useContext(FormLookupsContext);

    const deindexedName = useMemo(() => {
        if (!rowPath) {
            return '';
        }

        return `${ rowPath }.gstCode`?.replace(/\[.*?\]/g, '');
    }, [rowPath]);

    const item = useMemo(() => {
        return GetFieldLookupItem(lookups, deindexedName, gstCode?.toString()!);
    }, [deindexedName, lookups, gstCode]);

    useEffect(() => {
        if (item) {
            actions?.setValue?.((amount ?? 0) * gstCodes[item.value], false, true, true);
        }
    }, [gstCode, amount, actions, item]);

    return null;
};

const TotalUpdater: FunctionComponent = () => {

    const { name, actions } = useContext(FieldContext) ?? {};
    const rowPath = getParentPropertyPath(name);
    const { state: { value: amount } } = useScopedField<Dtos.NewCommitmentItem['amount'], Dtos.IValidationError>(`${rowPath}.amount`, subscription);
    const { state: { value: gstAmount } } = useScopedField<Dtos.NewCommitmentItem['gstAmount'], Dtos.IValidationError>(`${rowPath}.gstAmount`, subscription);

    useEffect(() => {
        actions?.setValue?.((amount ?? 0) + (gstAmount ?? 0), false, true, true);
    }, [amount, gstAmount, actions]);

    return null;
};

const columns: Array<IFormGridCell<Dtos.NewCommitmentItem>> = [
    {
        name: 'costCode',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <LookupFilter {...costCodeFilter}>
                <Input
                    component={FuzzySelect}
                />
            </LookupFilter>
        )
    },
    {
        name: 'description',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'amount',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Numeric}
                numberFormat={MONEY_FORMAT}
                InputProps={DOLLAR_PREFIX}
            />
        )
    },
    {
        name: 'gstCode',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={FuzzySelect}
            />
        )
    },
    {
        name: 'gstAmount',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <>
                <GstUpdater />
                <Input
                    component={Numeric}
                    numberFormat={MONEY_FORMAT}
                    InputProps={DOLLAR_PREFIX}
                    disabled={true}
                />
            </>
        )
    },
    {
        name: 'total',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <>
                <TotalUpdater />
                <Input
                    component={Numeric}
                    numberFormat={MONEY_FORMAT}
                    InputProps={DOLLAR_PREFIX}
                    disabled={true}
                />
            </>
        )
    },
]

const NewCommitment: React.FunctionComponent<INewCommitmentProps> = () => {

    const classes = useStyles();

    return (
        <FormContainer
            maxWidth={false}
            formType={ Dtos.NewCommitment }
        >
            { /* FieldProvider is placed here so form level errors will appear on the form. */}
            <FieldProvider name="id" />
            <Paper className={classes.paper}>
                <Field
                    name="vendor"
                    component={FuzzySelect}
                    margin={fieldMargin}
                />

                <Field
                    name="jobCode"
                    component={FuzzySelect}
                    margin={fieldMargin}
                />

                <Field
                    name="description"
                    component={TextArea}
                    margin={fieldMargin}
                    rows={2}
                    rowsMax={5}
                />

                <Field
                    name="projectManager"
                    component={FuzzySelect}
                    margin={fieldMargin}
                />

                <Field
                    name="urgency"
                    component={FuzzySelect}
                    margin={fieldMargin}
                />
            </Paper>
            <TableContainer component={Paper}>
                <TotalUpdater />
                <FormGrid
                    type={Dtos.NewCommitmentItem}
                    name="items"
                    columns={columns}
                />
            </TableContainer>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="enteredBy"
                            component={Text}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="enteredDate"
                            component={DatePicker}
                            margin={gridFieldMargin}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>
        </FormContainer>
    );
}

export default NewCommitment;