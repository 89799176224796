import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, TextField, Popover } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { cloneDeep } from "lodash-es";
import { FC, useEffect, useRef, useState } from "react";
import { QuickLinkGroup } from '../../api/dtos';
import { SketchPicker } from 'react-color'
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IGroupEdit {
    group: QuickLinkGroup;
    open: boolean;
    onOk: (group: QuickLinkGroup) => void;
    onClose: () => void;
    adGroups: string[];
}

const GroupEdit: FC<IGroupEdit> = ({open, onClose, group, onOk, adGroups: groups }) => {
    const [editGroup, setEditGroup] = useState<QuickLinkGroup>({} as any);

    useEffect(() => {
        if (open) {
            setEditGroup(cloneDeep(group))
        }
    }, [open, group])

    const [showColor, setShowColor] = useState(false);

    const elementRef = useRef<HTMLDivElement>();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Edit Group Configuration</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TextField 
                        label="Title"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editGroup.name ?? ''}
                        onChange={(e) => {
                            setEditGroup(c => ({
                                ...c,
                                name: e.target.value && e.target.value !== '' ? e.target.value : undefined as any
                            }))
                        }}
                    />
                    <TextField 
                        label="Color" 
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        value={editGroup.color ?? ''}
                        inputProps={{ readOnly: true }}
                        onClick={() => setShowColor(true)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <div
                                        ref={elementRef as any}
                                        style={{
                                            height: 32,
                                            width: 32,
                                            background: editGroup.color,
                                            border: `1px solid lightgray`
                                        }}
                                        onClick={() => setShowColor(true)}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton 
                                        style={{
                                            padding: 0,
                                            visibility: editGroup.color ? 'visible' : 'hidden'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setEditGroup(c => ({
                                                ...c,
                                                color: undefined as any
                                            }))
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} fixedWidth />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Popover
                      id={'edit.color'}
                      open={!!elementRef.current && showColor}
                      anchorEl={elementRef.current}
                      onClose={() => setShowColor(false)}
                      style={{width: 300}}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                        {/* <ClickAwayListener onClickAway={() => setShowColor(false)}> */}
                        <SketchPicker 
                            color={ editGroup.color } 
                            onChange={(e) => {
                                setEditGroup(c => ({
                                    ...c,
                                    color: e.hex
                                }))
                            }}
                            presetColors={["#004842","#cd1342"]}
                        />
                        {/* </ClickAwayListener> */}
                    </Popover>
                    <TextField 
                        label="Enabled" 
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Checkbox 
                                        checked={editGroup.enabled}
                                        onChange={(e, checked) => {
                                            setEditGroup(c => ({
                                                ...c,
                                                enabled: checked
                                            }))
                                        }}
                                    />
                                </InputAdornment>
                            )
                        }}
                        value={editGroup.enabled ? "Enabled" : "Disabled"}
                    />
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={groups}
                        value={editGroup.adGroups}
                        onChange={(event, newValue) => {
                            setEditGroup(c => ({
                                ...c,
                                adGroups: newValue
                            }))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="standard"
                                margin="normal"
                                label="Restricted To"
                                helperText="AD Groups - Any of the above groups give access"
                            />
                        )}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button 
                    onClick={() => {
                        onOk(editGroup);
                        onClose();
                    }} 
                    color="primary" 
                    autoFocus
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default GroupEdit;